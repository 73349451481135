import React, { useState, useEffect, useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import loadingGif from "../../icons/loading.gif";
import { UserContext } from '../../context/UserContext';

const NPS = ({selectedTeams,  period}) => {
  const [token] = useContext(UserContext);
  const [enps, setEnps] = useState(null);
  const [year, month] = period.split('-').map(Number);


  const getEnps = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    const response = await fetch(
        `api/ms/time/enps/${selectedTeams}/${month}/${year}`,
      requestOptions
    );
    console.log(selectedTeams, period)
    const data = await response.json();
    console.log("🚀 ~ file: eNPS.jsx:25 ~ getEnps ~ data:", data)
    setEnps(data);
  };

  useEffect(() => {
    if (selectedTeams) {
        getEnps();
    }
  }, [selectedTeams, period]);

  const data = {
    labels: ['Detratores','Neutros', 'Promotores'],
    datasets: [
      {
        label: "# of Votes",
        data: [enps?.total_detratores, enps?.total_neutros, enps?.total_promotores],
        backgroundColor: [
          "rgba(239,105,134,255)",
          "rgba(247,186,68,255)",
          "rgba(92,203,139,255)",
          "rgba(206,206,206, 0.5)",
        ],
        borderColor: [
          "rgba(226,71,95, 2.2)",
          "rgba(250,226,138, 2.2)",
          "rgba(76,196,140, 0.6)",
          "rgba(206,206,206, 0.6)",
        ],
        borderWidth: 1,
        weight: 2,
        borderAlign: "inner",
      },
    ],
  };


  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        borderWidth: 0.5,
        labels: {
          boxWidth: 10,
          color: "blue",
          font: {
            weight: "bold",
          },
        },
        padding: {
          top: 10, // Espaço superior
          bottom: 10, // Espaço inferior
        },
      },
      datalabels: {
        color: "white",
        font: {
          size: 12,
        },
        anchor: "right",
        align: "inner",
      },
    },
  };



  return (
    <>
  <div className="card">
    <div className="card-header">
      <p className="card-header-title">eNPS</p>
    </div>
    <div
      className="card-content"
      style={{
        height: "300px",
        width: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {enps ? (
          <div style={{ position: "relative", marginLeft: "25em", }}>
          <Doughnut
            width={350} // Aumente o valor da largura
            height={350} // Aumente o valor da altura
            data={data}
            options={options}
            plugins={[ChartDataLabels]}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "30%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <span style={{ fontSize: "36px", fontWeight: "bold" }}>
              {enps?.nps !== undefined ? enps?.nps.toFixed(1) : "N/A"}
            </span>
            <p>
              <span style={{ fontSize: "12px" }}>Nota</span>
            </p>
          </div>
        </div>
      ) : (
        <img src={loadingGif} width="80" alt="loading..." />
      )}
    </div>
  </div>
</>

  );
};

export default NPS;
