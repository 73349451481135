import React, {useState} from 'react';
import TabsTableForm from "../../comp_forms/TabsTableForm";

export const InfoAcoes = ({token, colaborador = {}, me}) => {
    const [reload, setReload] = useState(1);
    const [idEditAction, setIdEditAction] = useState(null);
    const [notificationParameters, setParameter] = useState({
        display: false,
        text: "",
        color: "notification is-success",
    });
    return <TabsTableForm
        token={token}
        colaborador = {colaborador}
        selectedTeam={colaborador.team.id}
        selectedMember={colaborador.pipefy_id}
        setIdEditAction={setIdEditAction}
        reload={reload}
        me={me}
        idEditAction={idEditAction}
        setParameter={setParameter}
        buttonActon={false}
    />
}
