
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from "../../context/UserContext";
import { Link } from "react-router-dom";
import loadingGif from "../../icons/loading.gif";
import placeholderImage from "../../images/user.png";

const MemberImage = ({ memberId, colaboradorName, width = 120, height = 110 }) => {
  const [token] = useContext(UserContext);
  const [showImage, setShowImage] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setShowImage(false);
  };

  const handleShow = () => {
    setShowImage(true);
  };

  useEffect(() => {
    if (memberId) {
      // Fetch the image data when memberId and tokenGuide are available
      const fetchImage = async () => {
        try {
          setLoading(true); // Set loading to true while fetching image
          const response = await fetch(
            `/api/ms/image/${memberId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if(response) {
          // Assuming the response contains the image data as a Blob
          const imageBlob = await response.blob();

          // Create a Blob URL from the fetched image data
          const imageUrl = URL.createObjectURL(imageBlob);
          setImageSrc(imageUrl);
          setLoading(false); // Set loading to false after fetching image
          }

        } catch (error) {
          const placeHoldImg = await fetch(
            `https://ui-avatars.com/api/?name=${colaboradorName}`
          )
          setImageSrc(placeHoldImg.url);
          console.log(imageSrc)
          console.error("Error fetching image:", error);
          setLoading(false); // Set loading to false if an error occurs
        }
      };

      fetchImage();
    }
  }, [memberId, token]);

  return (
    <>
      <div className={`modal ${showImage && "is-active"}`}>
        <div className="modal-background" onClick={handleClose}></div>
        <div className="modal-content">
          <p className="image is-4by3">
            {imageSrc && (
              <img
                style={{
                  objectFit: "cover",
                }}
                src={imageSrc}
                alt="activity-user"
              />
            )}
          </p>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={handleClose}
        ></button>
      </div>
      <Link to="#" onClick={handleShow}>
        {loading ? (
          <img
            src={loadingGif}
            width={width}
            height={height}
            alt="loading..."
          />
        ) : (
          <img
            style={{
              objectFit: "cover",
              width: `${width}px`,
              height: `${height}px`,
              borderRadius: "50%",
            }}
            src={imageSrc || placeholderImage}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="https://ui-avatars.com/api/?name=Actio";
            }}
            alt="activity-user"
          />
        )}
      </Link>
    </>
  );
};

export default MemberImage;
