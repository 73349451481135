import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import CreateUser from "./CreateUser";

const Users = ({ me }) => {
  const [token] = useContext(UserContext);
  const [users, setUsers] = useState(null);
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentFullName, setCurrentFullName] = useState("");
  const [currentType, setCurrentType] = useState(null);
  const [currentIdPipefy, setCurrentIdPipefy] = useState(null);
  const [role, setRole] = useState(null);

  const [currentUserId, setCurrentUserId] = useState(null);
  // const [currentActive, setCurrentActive] = useState(null);

  const getUserMeFromToken = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const user = JSON.parse(window.atob(base64));
    console.log(user.type)
    setRole(user.type)
  }

  const getUsers = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/users", requestOptions);

    const data = await response.json();
    setUsers(data);
  };

  const handleDeleteUser = async (e, id) => {
    e.preventDefault();
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    await fetch(`/api/users/${id}`, requestOptions);
    getUsers();
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        email: currentEmail,
        full_name: currentFullName,
        type: currentType,
        id_pipefy: currentIdPipefy,
      }),
    };
    await fetch(`/api/users/${currentUserId}`, requestOptions);
    getUsers();
    setCurrentUserId(null);
  };

  useEffect(() => {
    getUsers();
    getUserMeFromToken(token);
  }, []);
  return (
    <>
      {users &&
        (role == "adm" ? (
          <>
            <h1 className="subtitle">Usuários</h1>

            <CreateUser token={token} getUsers={getUsers} />

            <div className="box">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th></th>
                    <th>E-mail</th>
                    <th>Nome completo</th>
                    <th>Tipo</th>
                    <th>ID do pipefy</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) =>
                    user.id === currentUserId ? (
                      <tr>
                        <td></td>
                        <td>
                          <input
                            className="input"
                            type="text"
                            placeholder="E-mail"
                            value={currentEmail}
                            onChange={(e) => setCurrentEmail(e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            className="input"
                            type="text"
                            placeholder="Nome completo"
                            value={currentFullName}
                            onChange={(e) => setCurrentFullName(e.target.value)}
                          />
                        </td>
                        <td>
                          <div className="select is-fullwidth">
                            <select
                              value={currentType}
                              onChange={(e) => setCurrentType(e.target.value)}
                            >
                              <option value="adm">Administrador</option>
                              <option value="lider">Lider</option>
                              <option value="cliente">Cliente</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <input
                            className="input"
                            type="number"
                            placeholder="Id Pipefy"
                            value={currentIdPipefy}
                            onChange={(e) =>
                              setCurrentIdPipefy(e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <a
                            href="#"
                            onClick={handleUpdateUser}
                            className="button is-success"
                            style={{ marginRight: "10px" }}
                          >
                            Salvar
                          </a>
                          <a
                            href="#"
                            className="button is-info"
                            onClick={() => setCurrentUserId(null)}
                          >
                            Cancelar
                          </a>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <a
                            style={{ color: "red" }}
                            onClick={(e) => {
                              if (window.confirm("Apagar usuário?")) {
                                handleDeleteUser(e, user.id);
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                              <path
                                fillRule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                              />
                            </svg>
                          </a>
                        </td>
                        <td>{user.email}</td>
                        <td>{user.full_name}</td>
                        <td>{user.type}</td>
                        <td>{user.id_pipefy}</td>

                        <td>
                          <a
                            onClick={() => {
                              setCurrentUserId(user.id);
                              setCurrentEmail(user.email);
                              setCurrentFullName(user.full_name);
                              setCurrentType(user.type);
                              setCurrentIdPipefy(user.id_pipefy);
                            }}
                            style={{ padding: "20px" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="notification is-warning">
            <h1 className="title">Você não tem acesso a esta tela!</h1>
            Entre em contato com o administrador da ferramenta para pedir
            acesso.
          </div>
        ))}
    </>
  );
};

export default Users;
