import React from 'react'

const CardIcon = ({title, content, icon, percent, color}) => {
    return (
      
    <div className="card ">
        <header className="card-header">
            <p className={"card-header-title "+color+" is-size-7"}>
                {title}
            </p>       
        </header>
        <div className="card-content">
            <div className="content">
                <p> <i className={icon}></i>&nbsp;&nbsp;
                <b >&nbsp;{content}</b></p>

                <p className='is-size-7'>{percent}%</p>
                <progress className="progress is-small is-primary" value={percent} max="100"></progress>
            </div>
        </div>
      </div>
      
      
    )
  }
  export default CardIcon