import React from 'react'

const Message = ({message, setMessage, type}) => {

    
  return (
    message && (
        <div className={`notification ${type}`}>
        <button className="delete" onClick={() => {setMessage(null)}}></button>
        {message}
        </div>
      )
    
  )
}

export default Message