import React from 'react'

const SubTeamTable = ({token, subTeams, setSelectedSubTeam, setShow}) => {

    const getSubTeam = async (id) => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch(`/api/subteams/${id}`, requestOptions);
        const data = await response.json();
        setSelectedSubTeam(data);
      };

    const handleSelectSubTeam = (id) => {
        getSubTeam(id)
        setShow(true)
    }
  return (
    <div className="card">
        <div className="card-header">
            <p className="card-header-title">Listagem Sub-times</p>
        </div>
        <div className="card-content" style={{ height: "470px", overflowY: "scroll"}}>
            <table className="table is-fullwidth">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Descrição</th>
                        <th>Cliente</th>
                        <th>TAG</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        subTeams &&
                        subTeams.map(subTeam => (
                            <tr key={`sb_${subTeam.id}`}>
                                <td>{subTeam.name_sub_team}</td>
                                <td>{subTeam.description}</td>
                                <td>{subTeam.user.full_name}</td>
                                <td>{subTeam.tag === null || subTeam.tag === undefined ? '' : subTeam.tag.name}</td>
                                <td>
                                    <a onClick={() => handleSelectSubTeam(subTeam.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                    </a>

                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>

    </div>
  )
}

export default SubTeamTable