import React, { useState, useEffect } from "react";
import MemberImage from "../comp_teamguide/MemberImage";
import ReactTooltip from "react-tooltip";
import ReasonModal from "./ReasonModal";
import { Link } from "react-router-dom";
import loadingGif from "../../icons/loading.gif";
import {ArrowDownShortWide, ArrowUpWideShort} from "../../icons/icons";

const TableCriticidadeFull = ({ token, selectedTeams }) => {

  const [reason, setReason] = useState("");
  const [show, setShow] = useState(false);
  const [indicadores, setIndicadores] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [orderF, setOrderF] = useState("name_collaborator");
  const [isLoading, setIsLoading] = useState(false);
  const nxt = ["<<","<",">",">>"];

  const itemsPerPage = 5;

  const top5 = true;

  const criticidade = {
    1: "Crítico",
    3: "Intermediário",
    5: "Não crítico",
    10: "Sem informação",
  };
  /*const cor = {
    Crítico: "is-danger",
    Intermediário: "is-warning",
    "Não crítico": "is-success",
    "Sem informação": "",
  };*/

  const corCri = {
    "Crítico": "#ef6986",
    "Intermediário": "#f7ba44",
    "Não crítico": "#5ccb8b",
    "Sem informação": "#e6e6e6",
  };

  const handleShowReason = async (idCollaborator) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };


    const response = await fetch(
      `/api/get_criticalityleaders/${idCollaborator}`,
      requestOptions
    );
    const data = await response.json();
    if (data.length > 0) {
      setReason(data[0].reason);
      setShow(true);
    } else {
      setReason("");
    }
  };

  const getIndicadores = async () => {
    setIsLoading(true);

    const arrDados = JSON.parse(localStorage.getItem("allIndicadores"));
    const tempArr = [];
    if (arrDados) {
      for (let i = 0; i < selectedTeams.length; i++) {
         tempArr.push(... arrDados.filter(items => {
          return items.id_team == selectedTeams[i];
          }));
      }
    }
    console.log(tempArr);
    setIndicadores(tempArr);
   const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      `/api/full/indicators/${selectedTeams.join(",")}`,
      requestOptions
    );
    const data = await response.json();
    //console.log("🚀 ~ file: TableCriticidadeFull.jsx:75 ~ getIndicadores ~ data:", data)

    setIndicadores(data);
    setIsLoading(false); // Definir isLoading como false
  };

  //const parsIndicadores = async() => {
  //};

  useEffect(() => {
    setCurrentPage(1);
    if(selectedTeams && selectedTeams.length){
      selectedTeams&&getIndicadores();
    }

  }, [selectedTeams]);

  /*useEffect(() => {
    setCurrentPage(1);
    selectedTeams && getIndicadores()
}, [sortDirection, orderF]);*/

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (columnName) => {
    console.log("Passou hS:",columnName);
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let filteredIndicadores = [];
  if (indicadores) {
    filteredIndicadores = indicadores.filter((indicador) =>
      indicador.name_collaborator
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    //console.log("*****",sortColumn);
    if (orderF) {
      filteredIndicadores.sort((a, b) => {
        //console.log(a.name_collaborator);
        const columnA = a[orderF];
        const columnB = b[orderF];
        //console.log("*****columnA:",columnA);
        let comparison = 0;
        if (columnA > columnB) {
          comparison = 1;
        } else if (columnA < columnB) {
          comparison = -1;
        }
        return sortDirection ? comparison : -comparison;
      });
    }
  }

  const currentItems = filteredIndicadores.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredIndicadores.length / itemsPerPage);

  const renderTableRows = () => {
    if (currentItems.length > 0) {
      return currentItems.map((indicador) => (
        <tr key={indicador.id_collaborator}>
          <td>
            <div className="columns">
              <div className="column is-3">
                <MemberImage
                  memberId={indicador.id_collaborator}
                  colaboradorName={indicador.name_collaborator}
                  width={40}
                  height={40}
                />
              </div>
              <div className="column" style={{ textAlign: "left" }}>
                {indicador.name_collaborator}
              </div>
            </div>
          </td>
          {selectedTeams === -1 && <td>{indicador.name_team}</td>}
          {!top5 && (
            <>
              <td>
                <span className={`tag`}
                      style={{backgroundColor: corCri[indicador.prediction]}}>
                  {indicador.prediction}
                </span>
              </td>
              <td>
                <span
                  className={`tag `}
                  style={{backgroundColor: corCri[indicador.prediction]}}
                >
                  {criticidade[indicador.criticality_leader]}
                </span>
                {(indicador.criticality_leader === 1 ||
                  indicador.criticality_leader == 3) && (
                  <Link
                    to="#"
                    onClick={() =>
                      handleShowReason(indicador.id_collaborator)
                    }
                    style={{ paddingLeft: "5px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </Link>
                )}
              </td>
            </>
          )}

          <td
            style={
              !top5 ? { backgroundColor: "rgb(188, 196, 240, 0.4)" } : {}
            }
          >
            <span className={`tag`} style={{backgroundColor: corCri[indicador.final]}}>
              {indicador.final}
            </span>
          </td>
          <td>{indicador.criticality}%</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={6}>Sem dados</td>
        </tr>
      );
    }
  };

  const renderPaginationNumbers = () => {
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + 2, totalPages);

    const paginationNumbers = [];

    if (startPage > 1) {
      paginationNumbers.push(
        <li key={1}>
          <a
            style={{ fontSize: "12px" }}
            className="pagination-link"
            onClick={() => setCurrentPage(1)}
          >
            1
          </a>
        </li>
      );
      if (startPage > 2) {
        paginationNumbers.push(<li key="ellipsis-start">&hellip;</li>);
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      paginationNumbers.push(
        <li key={page}>
          <a
            style={{ fontSize: "12px" }}
            className={`pagination-link ${
              page === currentPage ? "is-current" : ""
            }`}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </a>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        paginationNumbers.push(<li key="ellipsis-end">&hellip;</li>);
      }
      paginationNumbers.push(
        <li key={totalPages}>
          <a
            style={{ fontSize: "12px" }}
            className="pagination-link"
            onClick={() => setCurrentPage(totalPages)}
          >
            {totalPages}
          </a>
        </li>
      );
    }

    return paginationNumbers;
  };

  return (
    <>
      <ReasonModal
        show={show}
        setShow={setShow}
        reason={reason}
        setReason={setReason}
      />
      <ReactTooltip id="crit1" effect="solid">
        <span>
          Colaboradores mais críticos para monitorar em relação à demissão
        </span>
      </ReactTooltip>
      <ReactTooltip id="info" effect="solid">
        <span>
          Uma IA calcula o grau de criticidade do colaborador para a demissão
          voluntária a partir de inúmeros dados correlacionados.
        </span>
      </ReactTooltip>

      <ReactTooltip id="info2" effect="solid">
        <span>
          O líder Profectum indica o nível de criticidade do colaborador para a
          demissão voluntária.
        </span>
      </ReactTooltip>

      <ReactTooltip id="info3" effect="solid">
        <span>
          Resultado final de criticidade para a demissão voluntária a partir das
          criticidades de IA e do líder, apresentando sempre o pior cenário.
        </span>
      </ReactTooltip>

      <ReactTooltip id="info4" effect="solid">
        <span>
          Este valor representa o resultado percentual do cálculo do modelo de
          correlação. Quanto maior, mais crítica é a situação do colaborador.
        </span>
      </ReactTooltip>

      <div className="column">
      {!top5 && (
      <div className="field is-grouped">
          <p className="control has-icons-left">
            <input
              className="input is-small"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
            <span className="icon is-small is-left">
              <i className="fa fa-search"></i>
            </span>
          </p>
        </div>
      )}
        <div className="col-md-1 lnSepareteTable"></div>
        <div className="table-container tbCrit">
          <table className="table is-fullwidth is-hoverable has-text-centered " >
            <thead>
              <tr>
                <th className="has-text-centered">Nome
                  {(orderF === 'name_collaborator') ?
                    <a className={'ml-1'} onClick={() => setSortDirection(!sortDirection)}>
                        {sortDirection ? <ArrowDownShortWide/> : <ArrowUpWideShort/>}
                    </a> :
                    <a className={'ml-1'} onClick={() => setOrderF('name_collaborator')}>
                        <i className="fa-solid fa-sort"></i>
                    </a>
                  }
                </th>
                {selectedTeams === -1 && (
                  <th className="has-text-centered">Time
                  </th>
                )}
                {!top5 && (
                  <>
                    <th className="has-text-centered">
                      <span style={{ paddingRight: "5px" }}>
                        Criticidade IA
                      </span>
                      <a data-tip data-for="info" data-event="click focus" onClick={handleSort}>
                        <i className="fa fa-circle-info"></i>
                      </a>
                    </th>
                    <th className="has-text-centered">
                      <span style={{ paddingRight: "5px" }}>
                        Criticidade Lider
                      </span>
                      <a data-tip data-for="info2" data-event="click focus">
                        <i className="fa fa-circle-info"></i>
                      </a>
                    </th>
                  </>
                )}

                <th className="has-text-centered">
                  <span style={{ paddingRight: "5px" }}>
                    {top5 ? "Criticidade" : "Criticidade Resultante"}
                  </span>
                  {(orderF === 'final') ?
                      <a className={'ml-1'} onClick={() => setSortDirection(!sortDirection)}>
                          {sortDirection ? <ArrowDownShortWide/> : <ArrowUpWideShort/>}
                      </a> :
                      <a className={'ml-1'} onClick={() => setOrderF('final')}>
                          <i className="fa-solid fa-sort"></i>
                      </a>
                    }
                </th>
                <th className="has-text-centered">
                  <span style={{ paddingRight: "5px" }}>%</span>
                  {(orderF === 'criticality') ?
                      <a className={'ml-1'} onClick={() => setSortDirection(!sortDirection)}>
                          {sortDirection ? <ArrowDownShortWide/> : <ArrowUpWideShort/>}
                      </a> :
                      <a className={'ml-1'} onClick={() => setOrderF('criticality')}>
                          <i className="fa-solid fa-sort"></i>
                      </a>
                    }
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6}>
                    <img src={loadingGif} alt="loading..." />
                  </td>
                </tr>
              ) : (
                renderTableRows()
              )}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <nav
            className="pagination is-centered is-small is-rounded"
            role="navigation"
            aria-label="pagination"
          >
            <a
                  className="pagination-previous"
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1}
                >
                  {nxt[0]}
                </a>
                <a
                  className="pagination-previous"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {nxt[1]}
                </a>
                <a
                  className="pagination-next"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {nxt[2]}
                </a>

                <a
                  className="pagination-next"
                  onClick={() => setCurrentPage(totalPages)}
                  disabled={currentPage === totalPages}
                >
                  {nxt[3]}
                </a>
            <ul className="pagination-list">
              {renderPaginationNumbers()}
            </ul>
          </nav>
        )}
      </div>
    </>
  );
};

export default TableCriticidadeFull;
