import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import TimesSelect from "./TimesSelect";
import NPS from "./eNPS";
import AbaladoEmocional from "./EmocionalAbalado";
import MotivoDemissao from './MotivoDemissao'
import RequestEngajamento from "../comp_teamguide/EngajamentPesquisa";
import EngajamentPesquisaGeral from "../comp_teamguide/EngajamentoGeralPesquisa";
//import RequestTurnover from "./comp_teamguide/RequestTurnover";
//import NPS from "./comp_teamguide/NPS2";
import Adesao from "./TaxAdesao"
//import NPSMedia from "./comp_teamguide/NPSMedia";


const PulsePage = ({me, faker}) => {
  const [token] = useContext(UserContext);
  //console.log("***PULSE:",team);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [period, setPeriod] = useState('');
  const dt = localStorage.getItem("teamsLocal");


  useEffect(() => {
    // Obter a data atual no formato YYYY-MM (ano-mês)
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0, então adicionamos 1
    const formattedDate = `${year}-${month}`;

    // Definir a data atual no estado
    setPeriod(formattedDate);
  }, []); // Executar isso apenas uma vez na montagem inicial


  return (
    me && (
      <div>
        <h1 className="title has-text">Pesquisa Pulse - Analytics</h1>
        
        <div className="columns" >
            <div className="column" style={{ marginLeft: '12px' }}> 
                <TimesSelect
                selectedTeams={selectedTeams}
                setSelectedTeams={setSelectedTeams}
                />
            </div>
            <div className="column is-one-fifth" style={{ textAlign: "left" }} >
              <input
                className="input"
                type="month"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
              />
            </div>
        </div>
        <br/>
        <div className="columns" >
            <div className="column">
                <Adesao 
                    token={token}
                />
            </div>
            <div className="column">
               <NPS 
                selectedTeams={selectedTeams}
                period={period}
               />
            </div>
        </div>

        <div className="columns">
          <div className="column">
            <RequestEngajamento
              selectedTeams={selectedTeams}
              period={period}
            />
          </div>

          <div className="column" >
                <div className="card" style={{
                    width: "82vh",
                    height:"50vh",
                }}>
                    <div className="card-header">
                    <p className="card-header-title">Engajamento Geral</p>
                    <a
                        className="card-header-icon"
                        data-tip
                        data-for="engajamento"
                        data-event="click focus"
                    >
                        <span className="icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-question-circle"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                        </svg>
                        </span>
                    </a>
                    </div>
                    <div className="card-content">
                        <EngajamentPesquisaGeral 
                            selectedTeams={selectedTeams} period={period}
                        />
                    </div>
                </div>
            </div>
          
        </div>

        <div className="columns">
            <div className="column"> 
                <AbaladoEmocional
                    selectedTeams={selectedTeams}
                    period={period}
                />
            </div>
            <div className="column"> 
                <MotivoDemissao
                    selectedTeams={selectedTeams}
                    period={period}
                />
            </div>
        </div>
        

            
      </div>
    )
  );
};
export default PulsePage;
