import React, { useState, useContext, useEffect } from 'react';
import {Link} from "react-router-dom";
import MemberImage from "../../../comp_teamguide/MemberImage";
import DataDrivenModal from "./../../../comp_forms/DataDrivenModal"

export const InfoHeader = ({colaborador={}, token, criticidade, dataDriven, npsClass, avaliacao}) => {
    const [edit, setEdit] = useState(false);
    const [dataDrivenData, setDataDrivenData] = useState(null);
    const [loading, setLoading] = useState(false);


    let criticidadeDesc = "";
    switch (criticidade) {
        case "1":
            criticidadeDesc = "Crítico";
            break;
        case "3":
            criticidadeDesc = "Intermediário";
            break;
        case "5":
            criticidadeDesc = "Não Crítico";
            break;
        default:
            criticidadeDesc = ""
    }

    const handleDataDriven = async (idAction) => {
        setEdit(true);
    };

    const getDataDrivenData = async () => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
        setDataDrivenData(null);
        setLoading(true);
        const response = await fetch(
          `/api/datadrivens/team/${colaborador.pipefy_id}`,
          requestOptions
        );
        const data = await response.json();

        setDataDrivenData(data);
    };

    useEffect(() => {
            colaborador && getDataDrivenData();
    }, [colaborador]);

    return (
        <>
        <DataDrivenModal
            show={edit}
            setShow={setEdit}
            id={colaborador.pipefy_id}
            name={colaborador.collaborator_name}
            dataDrivenData={dataDrivenData}
            setDataDrivenData={setDataDrivenData}
            selectedMember={colaborador.pipefy_id}
      />
        <div className="columns m-3">
            <div className="column ml-5 is-6">
                <MemberImage
                    memberId={colaborador.pipefy_id}
                    colaboradorName={colaborador.collaborator_name}
                    width={140}
                    height={140}
                    token={token}
                />
            </div>
            <div className="column">
                <div className="columns mb-5">

                    <div className="field mr-5">
                        <label>Equipe</label>
                        <div className="control">
                            <div className="field is-grouped">
                                <div className="control">
                                    <span className="tag is-info">{colaborador.team.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="field mr-5">
                        <label>Criticidade</label>
                        <div className="control">
                            <div className="field is-grouped">
                                <div className="control">
                                    <span className={`tag is-rounded ${criticidadeDesc === 'Crítico' ? 'is-danger' : criticidadeDesc === 'Não Crítico' ? 'is-success' : 'is-warning'}`}>
                                        {criticidadeDesc}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="field mr-5">
                        <label>Histórico&nbsp;NPS</label>
                        <div className="control">
                            <div className="field is-grouped">
                                <div className="control">
                                    <span className={`tag is-rounded ${npsClass === 'Promotor' ? 'is-success' : npsClass === 'Detrator' ? 'is-danger' : 'is-warning'}`}>
                                        {npsClass}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label>Avaliação</label>
                        <div className="control">
                            <div className="field is-grouped">
                                <div className="control">
                                    <span className="tag is-default is-rounded">{avaliacao}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to="#" onClick={() => handleDataDriven(colaborador.pipefy_id)}>
                        <i
                            className={"fa-solid fa-edit"}
                            style={{fontSize: "1.3em", color: "blue", position: "absolute", right: "20px", top: "20px"}}
                        ></i>
                    </Link>
                </div>
                <div className="columns" style={{"gap": "20px"}}>

                    <div className="field mr-5">
                        <label className={'is-small'}>Empresa&nbsp;de&nbsp;atuação</label>
                        <div className="control">
                            <div className="field is-grouped">
                                <div className="control">
                                    <div className="label text-darkblue">{colaborador.client.split("-")[0]}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="field mr-5">
                        <label className={'is-small'}>Cargo</label>
                        <div className="control">
                            <div className="field is-grouped">
                                <div className="control">
                                    <div className="label  text-darkblue" style={{width: '50%'}}>{colaborador.position}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="field mr-5">
                        <label className={'is-small'}>Senioridade</label>
                        <div className="control">
                            <div className="field is-grouped">
                                <div className="control">
                                    <div className="label  text-darkblue">{dataDriven.senioridade?dataDriven.senioridade: "Não Informado"}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        </>
    )
}
