import React from "react";

const ErrorMessage = ({ message, setMessage }) => (
  <>
  {message && (
    <div className="notification is-danger is-light">
    <button className="delete" onClick={() => setMessage(null)}></button>
    {message}
  </div>
  )}
  
</>
);

export default ErrorMessage;