import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import loadingGif from "../../icons/loading.gif";
import MemberImage from "../comp_teamguide/MemberImage";
import ReactTooltip from "react-tooltip";
import {ArrowDownShortWide, ArrowUpWideShort} from "../../icons/icons";


const TableLowPerformance = ({ selectedTeams }) => {
  const [token] = useContext(UserContext);
  const [nps, setNps] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("true");
  const [currentPage, setCurrentPage] = useState(1);
  const [orderF, setOrderF] = useState("Colaborador");
  const itemsPerPage = 3;
  const nxt = ["<<","<",">",">>"];


  const classificacao = (nota) => {
    if (nota >= 1 && nota <= 6) {
      return "Detrator";
    } else if (nota >= 7 && nota <= 8) {
      return "Neutro";
    } else if (nota >= 9 && nota <= 10) {
      return "Promotor";
    }
  };
  const cor = {
    Detrator: "is-danger",
    Neutro: "is-warning",
    Promotor: "is-success",
  };
  const corCri = {
    Detrator: "#ef6986",
    Neutro: "#f7ba44",
    Promotor: "#5ccb8b",
  };

  const getNPS = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      `/api/top/nps/${selectedTeams.join(",")}`,
      requestOptions
    );
    const data = await response.json();
    setNps(data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (selectedTeams.length) {
      getNPS();
    }
  }, [selectedTeams]);

  useEffect(() => {
    if (selectedTeams.length) {
      getNPS();
    }
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  const filteredIndicadores = nps
    ? nps.filter((indicador) =>
        indicador.Colaborador.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

    if (orderF) {
      filteredIndicadores.sort((a, b) => {
        //console.log(a.name_collaborator);
        const columnA = a[orderF];
        const columnB = b[orderF];

        //console.log("*****columnA:",columnA);
        let comparison = 0;
        if (columnA > columnB) {
          comparison = 1;
        } else if (columnA < columnB) {
          comparison = -1;
        }

        return sortDirection ? comparison : -comparison;
      });
    }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredIndicadores.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredIndicadores.length / itemsPerPage);

  const renderPaginationNumbers = () => {
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + 2, totalPages);

    const paginationNumbers = [];

    if (startPage > 1) {
      paginationNumbers.push(
        <li key={1}>
          <a
            style={{ fontSize: "12px" }}
            className="pagination-link"
            onClick={() => setCurrentPage(1)}
          >
            1
          </a>
        </li>
      );
      if (startPage > 2) {
        paginationNumbers.push(<li key="ellipsis-start">&hellip;</li>);
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      paginationNumbers.push(
        <li key={page}>
          <a
            style={{ fontSize: "12px" }}
            className={`pagination-link ${
              page === currentPage ? "is-current" : ""
            }`}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </a>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        paginationNumbers.push(<li key="ellipsis-end">&hellip;</li>);
      }
      paginationNumbers.push(
        <li key={totalPages}>
          <a
            style={{ fontSize: "12px" }}
            className="pagination-link"
            onClick={() => setCurrentPage(totalPages)}
          >
            {totalPages}
          </a>
        </li>
      );
    }

    return paginationNumbers;
  };

  return (
    <>
      <ReactTooltip id="perf1" effect="solid">
        <span>
          Colaboradores mais críticos para monitorar em relação à performance
        </span>
      </ReactTooltip>
      <div className="col-md-1 lnSepareteTable"></div>
      <div className="column">
        {!loading ? (
          <>
            {/* <div className="field is-grouped">
              <p className="control has-icons-left">
                <input
                  className="input is-small"
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-search"></i>
                </span>
              </p>
            </div> */}

            <table className="table is-fullwidth is-hoverable has-text-centered table-striped" id="sortTable">
              <thead>
                <tr >
                  <th className="has-text-centered">
                    <span
                      className="clickable"
                      onClick={() => handleSort("Colaborador")}
                    >
                      Nome

                    </span>
                    {(orderF === 'Colaborador') ?
                      <a className={'ml-1'} onClick={() => setSortDirection(!sortDirection)}>
                          {sortDirection ? <ArrowDownShortWide/> : <ArrowUpWideShort/>}
                      </a> :
                      <a className={'ml-1'} onClick={() => setOrderF('Colaborador')}>
                          <i className="fa-solid fa-sort"></i>
                      </a>
                    }
                  </th>
                  <th className="has-text-centered">
                    <span
                      className="clickable"
                      style={{ paddingRight: "5px" }}
                      onClick={() => handleSort("Nota")}>
                      Classificação
                    </span>
                    {(orderF === 'Nota') ?
                      <a className={'ml-1'} onClick={() => setSortDirection(!sortDirection)}>
                          {sortDirection ? <ArrowDownShortWide/> : <ArrowUpWideShort/>}
                      </a> :
                      <a className={'ml-1'} onClick={() => setOrderF('Nota')}>
                          <i className="fa-solid fa-sort"></i>
                      </a>
                    }
                  </th>
                  <th className="has-text-centered">
                    <span
                      className="clickable"
                      onClick={() => handleSort("Nota")}
                    >
                      NPS
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((indicador) => (
                  <tr key={indicador.id_collaborator}>
                    <td>
                      <div className="columns">
                        <div className="column is-3">
                          <MemberImage
                            memberId={indicador.id_collaborator}
                            colaboradorName={indicador.Colaborador}
                            width={40}
                            height={40}
                          />
                        </div>
                        <div className="column" style={{ textAlign: "left" }}>
                          {indicador.Colaborador}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        className={`tag`}
                        style={{backgroundColor: corCri[classificacao(indicador.Nota)]}}
                      >
                        {classificacao(indicador.Nota)}
                      </span>
                    </td>
                    <td>{indicador.Nota}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {totalPages > 1 && (
              <nav
                className="pagination is-centered is-small is-rounded"
                role="navigation"
                aria-label="pagination"
              >
                <a
                  className="pagination-previous"
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1}
                >
                  {nxt[0]}
                </a>
                <a
                  className="pagination-previous"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {nxt[1]}
                </a>

                <a
                  className="pagination-next"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {nxt[2]}
                </a>

                <a
                  className="pagination-next"
                  onClick={() => setCurrentPage(totalPages)}
                  disabled={currentPage === totalPages}
                >
                  {nxt[3]}
                </a>
                <ul className="pagination-list">{renderPaginationNumbers()}</ul>
              </nav>
            )}
          </>
        ) : (
          <img src={loadingGif} alt="loading..." />
        )}
      </div>

    </>
  );
};

export default TableLowPerformance;
