import React from 'react'

const Pagination = ({page, setPage, lastPage, setLoading = false}) => {

  const nxt = ["<<","<",">",">>"];

    const handlePage = (pageNumber) => {
        setPage(pageNumber);
        setLoading(true);

    }

    const handleNext = () => {
        if (page <= lastPage) {
            setPage(page + 1)
        }
    }

    const handlePrior = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const pages = (page, firstPage, lastPage) => {
        let content = []
        for (let i = firstPage; i <= lastPage + 1; i++) {

            content.push(
                <li key={`page${i}`}>
                    <a className={`pagination-link${i == page ? " is-current" : ""} is-small`}
                       onClick={() => handlePage(i)}>{i}</a>
                </li>
            )

        }

        return content;
    };

    const pageConstructor = () => {
        if (lastPage <= 6) {
            return (
                <ul className="pagination-list is-small">
                    {pages(page, 1, lastPage)}
                </ul>
            )
        } else if (page <= 4) {
            return (
                <>
                    <ul className="pagination-list is-small">
                        {pages(page, 1, 4)}

                        <li><span className="pagination-ellipsis is-small">&hellip;</span></li>


                        {pages(page, lastPage - 2, lastPage - 1)}
                    </ul>
                </>
            )
        } else if (page > 4 && page <= lastPage - 3) {
            return (
                <>
                    <ul className="pagination-list is-small">
                        {pages(page, 1, 1)}

                        <li><span className="pagination-ellipsis is-small">&hellip;</span></li>

                        <li key={`page${page - 1}`}>
                            <a className="pagination-link is-small" onClick={() => handlePage(page - 1)}>{page - 1}</a>
                        </li>

                        <li key={`page${page}`}>
                            <a className="pagination-link is-current is-small"
                               onClick={() => handlePage(page)}>{page}</a>
                        </li>

                        <li key={`page${page + 1}`}>
                            <a className="pagination-link is-small" onClick={() => handlePage(page + 1)}>{page + 1}</a>
                        </li>

                        <li><span className="pagination-ellipsis is-small">&hellip;</span></li>

                        {pages(page, lastPage - 2, lastPage - 1)}
                    </ul>
                </>
            )
        } else {
            return (
                <>
                    <ul className="pagination-list is-small">
                        {pages(page, 1, 2)}

                        <li><span className="pagination-ellipsis is-small">&hellip;</span></li>


                        {pages(page, lastPage - 3, lastPage - 1)}
                    </ul>
                </>
            )
        }


    }
    return (

        <>

            
            <nav
                className="pagination is-centered is-small is-rounded"
                role="navigation"
                aria-label="pagination"
              >
                <a
                  className="pagination-previous"
                  onClick={() => setPage(1)}
                  disabled={page === 1}
                >
                  {nxt[0]}
                </a>
                <a
                  className="pagination-previous"
                  onClick={handlePrior}
                  disabled={page === 1}
                >
                  {nxt[1]}
                </a>
                
                <a
                  className="pagination-next"
                  onClick={handleNext}
                  disabled={page === lastPage}
                >
                  {nxt[2]}
                </a>

                <a
                  className="pagination-next"
                  onClick={() => setPage(lastPage+1)}
                  disabled={page === lastPage}
                >
                  {nxt[3]}
                </a>
                <ul className="pagination-list">{pageConstructor()}</ul>
              </nav>

            {lastPage && <div className="has-text-right m-0 is-small">Última Página: {lastPage}</div>}
        </>


    )
}

export default Pagination
