import React, {useState, useEffect} from 'react';

const ModalAddTimeline = ({me, setAddTimeline, idAddTimeline, token, setParameter, showModalAdd, setShowModalAdd} )=>{
    
    const [data, setData] = useState(['']);
    const [dataAux, setAuxData] = useState(['']);    
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [showFormEdit, setShowFormEdit] = useState(false);
    
    const [edit, setEdit] = useState(false);
    const [idEditAction, setIdEditActio] = useState('');
    const [titleEdit, setTitleEdit] = useState('');
    const [subTitleEdit, setSubTitleEdit] = useState('');
    
    
    const getTimeLine = async (idAddTimeline) => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch("/api/get_timeline/"+idAddTimeline, requestOptions);
        const data = await response.json();
  
        setShowModalAdd(true);
        setData(data);
    };

    const handleTimeline = async () => {
        let data =  new Date();
        data = data.toISOString();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(
                {
                    title: title,
                    sub_title: subTitle,
                    lock_updated: data,
                    status: true,
                    id_user: me.id,
                    id_individual_action: idAddTimeline
                }),
        };
        const response = await fetch("/api/timeline", requestOptions);
        setParameter({
            display: true,
            text: "Salvo com sucesso.",
            color: "notification is-success"
        })
        setTitle('');
        setSubTitle('');
        setAddTimeline(null);
        setShowModalAdd(false);
        getTimeLine(idAddTimeline);
    }

    const putTimeline = async () => {
        let data =  new Date();
        data = data.toISOString()
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(
                {
                    title: titleEdit,
                    sub_title: subTitleEdit,
                    lock_updated: data,
                    status: true,
                    id_user: me.id,
                    id_individual_action: idAddTimeline
                }),
        };
        const response = await fetch(`/api/timeline/${edit}`, requestOptions);
        setAddTimeline(null);
        setEdit(false);
        getTimeLine(idAddTimeline);
    }

    const deleteTimeline = async (id_timeline) => {
        if (window.confirm("Você realmente quer apagar esse comentário?")){
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        await fetch(`/api/timeline_delete/${id_timeline}`, requestOptions);
        getTimeLine(idAddTimeline);
    }
    }

    useEffect(() =>{
        if(!(showModalAdd)){
            setAddTimeline(null);
        }
    }, [showModalAdd])

    useEffect(() => {
        if (idAddTimeline){
            setEdit(false);
            getTimeLine(idAddTimeline);
            setShowModalAdd(true);
        }
    }, [idAddTimeline])

    useEffect(() => {
        if (data.length){

            let formatDate = {}
            let options = {year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric'};
            data.forEach(function(item){
                let data = item.lock_updated;
                data  = new Date(data);
                data = data.toLocaleDateString("pt-BR", options);
                formatDate[item.id] = data;
            })
            setAuxData(formatDate)
        }
    }, [data])

    return(
        <div>
        <div className={`modal ${showModalAdd && "is-active"}`}>
            <div className="modal-background" onClick={() => {setShowModalAdd(false)}}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Comentarios</p>
                    <button onClick={() => {setShowModalAdd(false)}} className="delete" aria-label="close"></button>
                </header>
                <section className="modal-card-body">
                    {edit && (
                    <div className="box has-background-grey-lighter" >
                        <h5 className="subtitle is-5">Edição</h5>
                        <div className="columns">
                            <div className="column is-2">
                                <label className="label">Id</label>
                                <h6 className="title is-6">{edit}</h6>
                            </div>
                            <div className="column">
                                    <label className="label">Titulo</label>
                                    <input
                                        id = "member_form"
                                        className="input" 
                                        type="text"
                                        value={titleEdit}
                                        onChange={(e) => setTitleEdit(e.target.value)}
                                    />
                            </div>
                            <div className="column">
                                <label className="label">Descrição</label>
                                <textarea 
                                    className="textarea" 
                                    rows="2"
                                    value={subTitleEdit}
                                    onChange={(e)=> setSubTitleEdit(e.target.value)}
                                >
                                </textarea>
                            </div>
                            
                        </div>
                        <div className="columns">
                            <div className="column is-2">
                                
                                <div className="buttons">
                                    <button onClick={() => {putTimeline()}} className="button is-primary is-small">Salvar</button>
                                </div>
                            </div>
                            <div className="column is-2">
                                <div className="buttons">
                                    <button onClick={() => {setEdit(false)}} className="button is-danger is-small">Fechar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                    <div className="box">
                        <div className="columns">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Titulo</th>
                                        <th>Comentário</th>
                                        <th>Data</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(value => (
                                        <tr key={value.id}>
                                            <td>{value.id}</td>
                                            <td>{value.title}</td>
                                            <td>{value.sub_title}</td>
                                            <td>{dataAux[value.id]}</td>
                                            <td>
                                                <a to="#" onClick={() => { 
                                                    setEdit(value.id);
                                                    setTitleEdit(value.title);
                                                    setSubTitleEdit(value.sub_title);
                                                }}>
                                                    <i className="fa fa-edit" style={{color: 'grey'}} />
                                                </a>
                                                &nbsp;
                                                <a to="#" onClick={() => { 
                                                    deleteTimeline(value.id);
                                                }}>                                                    
                                                    <i className="fas fa-trash-alt" style={{color: 'red'}} />
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <form>
                        <div className="columns">
                            <div className="column">
                                <label className="label">Titulo</label>
                                <input
                                    id = "member_form"
                                    className="input" 
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <input
                                    className="input" 
                                    type="hidden"
                                    value={idAddTimeline}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <label className="label">Descrição</label>
                                <textarea 
                                    className="textarea" 
                                    rows="2"
                                    value={subTitle}
                                    onChange={(e)=> setSubTitle(e.target.value)}
                                >
                                </textarea>
                            </div>
                        </div>

                    </form>
                </section>
                <footer className="modal-card-foot">
                    <button className="button is-success" onClick={() => {handleTimeline()}} >Save</button>
                    <button onClick={() => {
                        setShowModalAdd(false);
                        setEdit(false)
                        }} className="button">Fechar</button>
                </footer>
            </div>
        </div>
        </div>
    )
}
export default ModalAddTimeline
