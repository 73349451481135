import React, { useContext } from "react";
import { UserContext } from "../context/UserContext";
import PesquisaFatores from "./subcomponents/PesquisaFatores";
function Pesquisa() {
  const [token] = useContext(UserContext);
  return (
    <div>
      <h1 className="subtitle">Pesquisa</h1>
      <PesquisaFatores token={token}/>
    </div>
  );
}

export default Pesquisa;
