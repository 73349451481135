import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const TableAcoes = ({
  selectedTeam,
  selectedMember,
  period,
  seletedSituation,
  token,
  setIdRequest,
  setIdEditAction,
  setAddTimeline,
  reload,
  showModalAdd,
  setShowModalAdd,
  dadosTableFinaly, 
  setDadosTableFinaly
}) => {
  const [dadosTable, setDadosTable] = useState([]);
  const [criticality, setCriticality] = useState([]);
  const [order, setorder] = useState("ASC");
  const [column, setColumn] = useState(null);
  const [NPS, setNPS] = useState([]);
  const [eraseTooltip, setEraseTooltip] = useState(false);

  const SITUACAO = {
    NAO_INICIADO: "Não iniciado",
    ANDAMENTO: "Em andamento",
    CONCLUIDO: "Concluído",
  };

  const cor_indicador = {
    Crítico: "is-danger",
    Intermediário: "is-warning",
    "Não crítico": "is-success",
    "Não informado": "",
  };

  const cor2 = {
    Detrator: "is-danger",
    Neutro: "is-warning",
    Promotor: "is-success",
  };

  const getAcoesTable = async (rota) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "/api/individual_actions_team/" + rota,
      requestOptions
    );
    const data = await response.json();
    
    if(!data.length){
      alert('Sem dados para esta consulta')
    }
    setDadosTable(data);
  };

  const get_nps = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/nps/" + selectedTeam, requestOptions);
    const data = await response.json();
    setNPS(data);
  };

  const returnCategory = (nota) => {
    //console.log("🚀 ~ file: TableAcoes.jsx:77 ~ returnCategory ~ nota", nota)
    
    if (nota<7){
      return ['Detrator', nota]
    }else if(nota>6 && nota<9){
      return ['Neutro', nota]
    }else if(nota>8){
      return ['Promotor', nota]
    }else{
      //console.log(nota)
      return ['Promotor', 'Não foi']
    }
  }


  const findCategoriaNPS = (nps_colab) => {
    if (nps_colab.length){
      if (nps_colab.length === 1) {
        return returnCategory(nps_colab[0].Nota)
      }else{
        const ultimoTRI = Math.max(...nps_colab.map(o => o.Trimestre));
        const arrayComplete = nps_colab.filter(i => i.Trimestre === ultimoTRI);
        const {Nota} = arrayComplete[0];
        
        return returnCategory(Nota)
      }
    }else{
      return ['Não Informado',""]
    }
  };
  
  const get_criticality = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "/api/full/nopage/indicators/" + selectedTeam,
      requestOptions
    );
    const data = await response.json();
    setCriticality(data);
  };

  useEffect(() => {
    get_nps();
    setEraseTooltip(false);
    get_criticality();
  }, [selectedTeam]);

  useEffect(() => {
    setEraseTooltip(false);
    if (selectedTeam && !selectedMember && period) {
      let data = period.split("-");
      data[1] = Math.trunc(data[1]);
      getAcoesTable(
        "period/" +
          selectedTeam +
          "/" +
          data[1] +
          "/" +
          data[0] +
          "/" +
          seletedSituation
      );
    } else if (selectedTeam && selectedMember && period) {
      let data = period.split("-");
      data[1] = Math.trunc(data[1]);
      getAcoesTable(
        "period/collaborator/" +
          selectedTeam +
          "/" +
          data[1] +
          "/" +
          data[0] +
          "/" +
          selectedMember +
          "/" +
          seletedSituation
      );
    } else if ((!period || period == "") && selectedMember) {
      getAcoesTable(
        "collaborator/" +
          selectedTeam +
          "/" +
          selectedMember +
          "/" +
          seletedSituation
      );
    } else if ((!period || period == "") && !selectedMember) {
      getAcoesTable("collaborator/" + selectedTeam + "/0/" + seletedSituation);
    }
  }, [selectedTeam, selectedMember, period, reload, seletedSituation]);

  useEffect(() => {
    if (dadosTable.length && criticality && dadosTable[0].status) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const dados = [];
    
      dadosTable.forEach((item, i) => {
        const { deadline, id_collaborator, name_collaborator, gap, burden, action, situation } = item;
        let nome = name_collaborator.split(" ");
        let criticality_collab = "Não Informado";
        let percentage = "0";
    
        if (nome[1].length > 3) {
          nome = `${nome[0]} ${nome[1]}`;
        } else {
          nome = `${nome[0]} ${nome[1]} ${nome[2]}`;
        }
    
        const nps_colab = NPS.filter(itemNPS => itemNPS.id_collaborator === id_collaborator);
        const [nps, nota] = findCategoriaNPS(nps_colab);
    
        const criticalityItem = criticality.find(item_2 => item_2.id_collaborator === id_collaborator);
        if (criticalityItem) {
          criticality_collab = criticalityItem.final;
          percentage = criticalityItem.criticality;
        }
    
        dados[i] = {
          id: item.id,
          nome,
          GAP: gap.name,
          burden: String(burden),
          deadline: new Date(deadline).toLocaleDateString("pt-BR", options),
          action,
          situation,
          criticality: criticality_collab,
          percentage: String(percentage),
          nps,
          Nota: nota
        };
    
        item.GAP = gap.name;
        item.status = false;
        delete item.gap;
        delete item.user;
      });
    
      setDadosTableFinaly(dados);
      setEraseTooltip(true);
    }
    if (!dadosTable.length) {
      setDadosTableFinaly([]);
    }
  }, [dadosTable]);

  const RequestTimeLine = (id) => {
    setIdRequest(id);
  };
  const RequestActionEdit = (id) => {
    setIdEditAction(id);
  };
  const RequestAddTimeLine = (id) => {
    setShowModalAdd(true);
    setAddTimeline(id);
  };

  const sorting = async (col) => {
    setColumn(col);
    if (order === "ASC") {
      const sorted = [...dadosTableFinaly].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setDadosTableFinaly(sorted);
      setorder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...dadosTableFinaly].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setDadosTableFinaly(sorted);
      setorder("ASC");
    }
  };

  return (
    <div>
      {eraseTooltip ? 
        dadosTableFinaly.map((item) => (
          <ReactTooltip id={`nps_${item.id}`} effect="solid">
            {item.Nota ? <span>Nota: {item.Nota}</span> : <span>Sem informação</span>}
          </ReactTooltip>
            )) : null
      }
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>
              <Link to="#" onClick={() => sorting("nome")}>
                Nome
                <i
                  className={`fa fa-sort${
                    column === "nome" ? (order === "ASC" ? "-up" : "-down") : ""
                  }`}
                  style={{ paddingLeft: "5px" }}
                ></i>
              </Link>
            </th>
            <th>
              <Link to="#" onClick={() => sorting("criticality")}>
                Criticidade
                <i
                  className={`fa fa-sort${
                    column === "criticality" ? (order === "ASC" ? "-up" : "-down") : ""
                  }`}
                  style={{ paddingLeft: "5px" }}
                ></i>
              </Link>
            </th>
            <th>
              <Link to="#" onClick={() => sorting("nps")}>
                NPS
                <i
                  className={`fa fa-sort${
                    column === "nps" ? (order === "ASC" ? "-up" : "-down") : ""
                  }`}
                  style={{ paddingLeft: "5px" }}
                ></i>
              </Link>
            </th>
            <th>
              <Link to="#" onClick={() => sorting("GAP")}>
                GAP
                <i
                  className={`fa fa-sort${
                    column === "GAP" ? (order === "ASC" ? "-up" : "-down") : ""
                  }`}
                  style={{ paddingLeft: "5px" }}
                ></i>
              </Link>
            </th>
            <th>
              <Link to="#" onClick={() => sorting("burden")}>
                Peso
                <i
                  className={`fa fa-sort${
                    column === "burden" ? (order === "ASC" ? "-up" : "-down") : ""
                  }`}
                  style={{ paddingLeft: "5px" }}
                ></i>
              </Link>
            </th>
            <th>
              <Link to="#" onClick={() => sorting("action")}>
                Ação
                <i
                  className={`fa fa-sort${
                    column === "action" ? (order === "ASC" ? "-up" : "-down") : ""
                  }`}
                  style={{ paddingLeft: "5px" }}
                ></i>
              </Link>
            </th>
            <th>
              <Link to="#" onClick={() => sorting("deadline")}>
                Prazo
                <i
                  className={`fa fa-sort${
                    column === "deadline" ? (order === "ASC" ? "-up" : "-down") : ""
                  }`}
                  style={{ paddingLeft: "5px" }}
                ></i>
              </Link>
            </th>
            <th>
              <Link to="#" onClick={() => sorting("situation")}>
                Status da Ação
                <i
                  className={`fa fa-sort${
                    column === "situation" ? (order === "ASC" ? "-up" : "-down") : ""
                  }`}
                  style={{ paddingLeft: "5px" }}
                ></i>
              </Link>
            </th>
            <th>Edit</th>
            <th>Comentário</th>
          </tr>
        </thead>
        <tbody>
          {dadosTable &&
            dadosTableFinaly.map((value) => (
              <tr key={value.id}>
                <td>{value.nome}</td>
                <td>
                  <span className={"tag " + cor_indicador[value.criticality]}>
                    {value.criticality}
                  </span>
                </td>
                <td>
                  <a data-tip data-for={`nps_${value.id}`}>
                    <span className={`tag ${cor2[value.nps]}`}>
                      {value.nps}
                    </span>
                  </a>
                </td>
                <td>{value.GAP}</td>
                <td>{value.burden}</td>
                <td>{value.action}</td>
                <td>{value.deadline}</td>
                <td>{SITUACAO[value.situation]}</td>
                <td>
                  <a
                    onClick={() => RequestActionEdit(value.id)}
                    className="is-info is-light"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fillRule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                  </a>
                </td>
                <td>
                  <button
                    onClick={() => RequestTimeLine(value.id)}
                    className="button is-info is-small is-light"
                  >
                    Ver
                  </button>
                  <button
                    onClick={() => RequestAddTimeLine(value.id)}
                    className="button is-info is-small is-light"
                  >
                    add
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default TableAcoes;
