import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import Times from "../comp_teamguide/Times";
import ChartAcoes from "./ChartAcoes"
import TableAcoes from "./TableAcoes"
import Separator from "./Separator"
import ModalNewAction from "./ModalNewAction"


function PlanoAcao_v2({me}) {
  const [token] = useContext(UserContext);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [nameTeam, setNameTeam] = useState([]);
  const [seletedSituation, setSeletedSituation] = useState(0);
  const today = new Date();
  const [dados, setDados] = useState([]);
  const [period, setPeriod] = useState('');
  const [showModalAcao, setModalhowAcao] = useState(false);

  // const [period, setPeriod] = useState(
  //   today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2)
  // );



  const getAcoesTable = async (rota) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "/api/individual_actions_team/" + rota,
      requestOptions
    );
    const data = await response.json();
    
    // if(!data.length){
    //   alert('Sem dados para esta consulta')
    // }
    setDados(data);
  };


  useEffect(() => {
    if (selectedTeams.length > 0) {
      let endpoint = `period/${selectedTeams.join(",")}`;
  
      if (period) {
        let data = period.split("-");
        const [year, month] = data;
        endpoint += `/${month}/${year}`;
      } else {
        endpoint += `/0/0`;
      }
  
      if (seletedSituation) {
        endpoint += `/${seletedSituation}`;
      } else {
        endpoint += `/0`;
      }
  
      getAcoesTable(endpoint);
    }
  }, [selectedTeams, period, seletedSituation]);
  
  
  const RequestAddAcao = () => {
    setModalhowAcao(true);
  };


return (
  <>
    <ModalNewAction
      me={me}
      id_collaborator="false"
      token={token}
      setModalhowAcao={setModalhowAcao}
      showModalAcao={showModalAcao}
      selectedTeams={selectedTeams}
    />
    <h1 className="title has-text-link">Plano de Ação</h1>
    <div className="columns">
      <div style={{ marginLeft: '12px' }}>
        Equipes
      </div>
    </div>
    <br/>
    <div className="columns">
      <div className="column">
        <Times
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
          setNameTeam={setNameTeam}
        />
      </div>
      <div className="column" style={{ textAlign: "right" }}>
        <button 
          onClick={() => {RequestAddAcao()}}
          className="button is-info"> 
          + Nova Ação
        </button>
      </div>
    </div>
    
      <div className="card">
        <div className="card-header" style={{ justifyContent: "flex-end" }}>
          <a
            className="card-header-icon"
            data-tip
            data-for="crit"
            data-event="click focus"
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-question-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
              </svg>
            </span>
          </a>
        </div>

        <div className="card-content">
          <div className="columns">
            <div className="column is-one-fifth">
              <label htmlFor="">Período</label> 
              <input
                className="input"
                type="month"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
              />
            </div>
            <div className="column is-one-fifth">
              <label htmlFor="">Status das Ações</label> 
              <div className="select">
                <select
                  name="situation"
                  id="situation"
                  value={seletedSituation}
                  onChange={(e) => setSeletedSituation(e.target.value)}
                >
                  <option value="0">Status da Ação</option>
                  <option value="NAO_INICIADO">Não iniciado</option>
                  <option value="ANDAMENTO">Em andamento</option>
                  <option value="CONCLUIDO">Concluído</option>
                </select>
              </div>
            </div>
            {/* <div className="column" style={{ textAlign: "right" }}>
              pesquisa
            </div> */}
          </div>
          <div className="columns">
            <ChartAcoes 
              dados={dados}
            />
          </div>
          <Separator horizontal={false} />
          <br/>
          <div className="columns">
          <TableAcoes 
            dados={dados}
            selectedTeams={selectedTeams}
            token={token}
            me={me}
          />
          </div>
        </div>
      </div>

    

  </>
);
}

export default PlanoAcao_v2;