import React, {useEffect, useState} from 'react';

export const InfoCriticidade = ({me, colaborador, token}) => {

    const [dadosCriticidade, setDadosCriticidade] = useState([]);
    const [newCriticidade, setNewCriticidade] = useState(true);
    const [selectedOption, setSelectedOption] = useState('1');
    const [reason, setReason] = useState("");

    const getCriticidade = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const response = await fetch("/api/get_criticalityleaders/" + colaborador.pipefy_id, requestOptions);
        const data = await response.json();
        setNewCriticidade(data.length === 0)
        setDadosCriticidade(data);
        setReason(data.length > 0 ? data[0].reason : "");
        setSelectedOption(data.length > 0 ? data[0].criticality.toString() : '1')
    };

    useEffect(() => {
        getCriticidade()
    }, []);


    const teams = [
        {id: 1, name: "Crítico"},
        {id: 3, name: "Intermediário"},
        {id: 5, name: "Não Crítico"},
    ]

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleTextAreaChange = (event) => {
        setReason(event.target.value);
    };

    const hadleCriticidadeSubmit = async () => {
        let payload = {
            id_user: me.id,
            criticality: !newCriticidade && dadosCriticidade[0].low_performance === true ? '1' : selectedOption,
            low_performance: newCriticidade ? false : dadosCriticidade[0].low_performance,
            id_collaborator: colaborador.pipefy_id.toString(),
            name_collaborator: colaborador.collaborator_name,
            reason: reason,
            status: true,
            msg:'',
            type: null
        }
        if(!newCriticidade)
            payload = {...payload, id: dadosCriticidade[0].id}


        const requestOptions = {
            method: newCriticidade ? "POST" : "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(payload),
        };
        const endpoint = newCriticidade ? "/api/criticalityleaders" : "/api/criticalityleaders/" + colaborador.pipefy_id;
        await fetch(endpoint, requestOptions);
    }

    return (
        <>
            <div className="field">
                <label className="label text-darkblue">Criticidade da liderança</label>

                <div className="control is-flex">
                    <>
                        {teams.map((team) => (
                            <div
                                className="button is-info is-light"
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    marginRight: '12px'
                                }}
                                key={team.id}
                            >
                                <input
                                    type="radio"
                                    name="criticidade"
                                    value={team.id}
                                    checked={selectedOption === team.id.toString()}
                                    onChange={handleOptionChange}
                                    style={{marginRight: "5px"}}
                                />
                                <label htmlFor={`team_${team.id}`}>{team.name}</label>
                            </div>
                        ))
                        }

                        <div
                            className="button is-info is-light"
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                marginRight: '12px'
                            }}
                            id={'button'}
                            onClick={hadleCriticidadeSubmit}
                        >
                            <label htmlFor={`button`}>Atualizar</label>
                        </div>
                    </>
                </div>
            </div>

            <div className="field">
                <label className="label is-size-7 has-text-default">Observações</label>
                <div className="control is-flex is-flex-direction-column is-justify-content-flex-start">
                    <textarea value={reason}
                              onChange={handleTextAreaChange}
                              className="textarea is-static is-size-7"
                              rows="3"
                    />
                </div>
            </div>
        </>
    );
}
