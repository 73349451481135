import React, {useContext, useState} from 'react'
import { UserContext } from "../context/UserContext";
import { useNavigate, useSearchParams } from "react-router-dom";


const ChangePassword = ({me}) => {
    const [token, setToken] = useContext(UserContext);
    const [newPassword, setNewPassword] = useState("");
    const [repetedNewPassword, setRepetedNewPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const handleUpdatePassword = async (e) => {
        if (newPassword === repetedNewPassword && newPassword !== "") {
            e.preventDefault();
            const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
                },
                body: JSON.stringify({
                    reset_password_token: searchParams.get("reset_password_token"),
                    new_password: newPassword
                })
            };
            const response = await fetch(`/api/users/reset_password`, requestOptions);
            if (response.ok){
                setErrorMessage(null);
                
                setToken(null)
                navigate('/login');
            } else {
                setErrorMessage("Algo de errado acontenceu!")
            }
            
        } else {
            setErrorMessage("Senhas não batem!")
        }
        
        
    };


 
    return (
        <div className="columns">
            <div className="column">

            </div>
            <div className="column">
                <div className="box">
                    <div className="field">
                        <label className="label">Nova senha</label>
                        <div className="control">
                            <input 
                            className="input" 
                            type="password" 
                            placeholder="Nova senha"
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                                
                            }}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Repetir nova senha</label>
                        <div className="control">
                            <input 
                            className="input" 
                            type="password" 
                            placeholder="Repetir nova senha"
                            value={repetedNewPassword}
                            onChange={(e) => {
                                setRepetedNewPassword(e.target.value);
                               
                            }}
                            />
                        </div>
                    </div>
                    <span style={{color: 'red'}}>{errorMessage}</span>
                    <button 
                    className="button is-fullwidth is-info" 
                    onClick={handleUpdatePassword}
                    >
                        Salvar
                    </button>

                </div>
            </div>
            <div className="column">
        
            </div>
        
        </div>
    
  )
}

export default ChangePassword