import React, {useEffect, useState, useRef} from "react";
import ReactTooltip from "react-tooltip";
import RequestMember from "../comp_teamguide/RequestMember";
import Notification from "./../Notification";
import $ from 'jquery'


const ModalNewAction = ({
                            me,
                            id_collaborator,
                            token,
                            colaborador=false,
                            setModalhowAcao,
                            showModalAcao,
                            selectedTeams,
                            disabled = false
                        }) => {

    //console.log("id_collaborator", selectedTeams)

    const [criticalityBD, setCriticityBD] = useState(null);
    const [notificationParameters, setParameter] = useState({
        display: false,
        text: "",
        color: "notification is-success",
    });
    const [classPeso, setClassPeso] = useState('label');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [lowPerformance, setLowPerformance] = useState(false);
    const [nameMember, SetNameMember] = useState(colaborador['collaborator_name'] || null);
    const [criticidade, setCriticidade] = useState("");
    const [reason, setReason] = useState(0);
    const [maxBurden, setMaxBurden] = useState(0);
    const [membersList, setMembersList] = useState([]);
    const [selectedMember, setSelectedMember] = useState(id_collaborator);
    const [selectedGAP, setSelectedGAP] = useState(null);
    const [data_one, setData_one] = useState(new Date().toISOString().slice(0, 10));
    const [description, setDescription] = useState('');
    const [action, setAction] = useState('');
    const selectRef = useRef(null);

    const [team, setTeam] = useState(null);
    const [nameSubTeam, setNameSubTeam] = useState(colaborador['sub_team'] ? colaborador['sub_team']['name'] : null);
    const [subteam, setSubTeam] = useState(colaborador['subteam_pipefy_id'] || null);
    const [id_gap, setGAP] = useState(null);
    const [burden, setBurden] = useState('');
    const [deadline, setDeadline] = useState(null);

    useEffect(() => {
        getGAP();
        if (selectedMember) {
            get_criticidade_id();
            get_peso();
            setButtonDisabled(false);
        }
    }, [selectedMember])

    useEffect(() => {
        if (burden) {
            get_peso();
            validateBurden();
        }
    }, [data_one, selectedMember])

    useEffect(() => {
        if (burden) {
            validateBurden();
        }
    }, [burden])

    const getGAP = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch("/api/gaps", requestOptions);
        const data = await response.json();
        setSelectedGAP(data)
    };

    const getMember = async (id) => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch("/api/ms/collabrator/"+id, requestOptions);
        const data = await response.json();
        console.log("🚀 ~ file: ModalNewAction.jsx:96 ~ getMember ~ data:", data)
        setTeam(data['team_pipefy_id'])
        setNameSubTeam(data['sub_team']['name'])
        setSubTeam(data['subteam_pipefy_id'])
        SetNameMember(data['collaborator_name'])

    };

    const get_criticidade_id = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch("api/get_criticalityleaders/" + selectedMember, requestOptions);
        const data = await response.json();
        if (data.length) {
            let val = data[0].criticality.toString();
            let lp = data[0].low_performance
            setLowPerformance(lp)
            setCriticidade(val);
            setCriticityBD(val)
            setReason(data[0].reason)
        } else {
            setCriticityBD(null);
            setCriticidade('');
            setLowPerformance(false);
            setReason('');
        }
    };

    const changeCriticidade = async (e) => {
        if (criticalityBD) {
            await handleUpdateCriticidade(e);
        } else {
            await handleCreateCriticidade(e);
        }
    };
    //console.log(selectedTeams)
    const handleCriticidade = async (e, method) => {
        e.preventDefault();
        setParameter({
            display: true,
            text: "Criticidade Salva",
            color: "notification is-primary",
        });
        let val = $("#form_action input[type='radio']:checked").val();

        const requestOptions = {
            method,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                id_user: me.id,
                criticality: lowPerformance ? 1 : val,
                low_performance: lowPerformance,
                id_collaborator: selectedMember,
                name_collaborator: nameMember,
                reason: reason,
                status: true,
            }),
        };

        const endpoint = method === "POST" ? "/api/criticalityleaders" : "/api/criticalityleaders/" + selectedMember;

        const response = await fetch(endpoint, requestOptions);

        if (method === "POST") {
            setCriticityBD(val);
        }
    };

    const handleUpdateCriticidade = async (e) => {
        await handleCriticidade(e, "PUT");
    };

    const handleCreateCriticidade = async (e) => {
        await handleCriticidade(e, "POST");
    };

    const get_peso = async () => {
        setParameter({
            display: false,
        })
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        let d = new Date(data_one);
        if (!!d.valueOf()) {
            var year = d.getFullYear();
            var month = d.getMonth() + 1;
            // TODO.
        }
        const response = await fetch("api/individual_actions/" + selectedMember + "/" + month + "/" + year, requestOptions);
        const data = await response.json();
        if (data.length) {
            let sum = 0;
            data.forEach(value => {
                sum += value.burden;
            });
            setMaxBurden(sum);
            if (sum >= 100) {

                setParameter({
                    display: true,
                    text: "Peso para o mês e colaborador solicitado ja esta no máximo: " + sum,
                    color: "notification is-warning"
                })
                setButtonDisabled(true);
            } else {
                setParameter({
                    display: false
                })
            }
        }
    };

    const validateBurden = async (e) => {

        let total = 0;
        total = parseInt(burden) + parseInt(maxBurden);

        if (total > 100) {

            setParameter({
                display: true,
                text: "Peso utrapassa 100%, valor máximo: " + (100 - maxBurden),
                color: "notification is-warning"
            })
            setClassPeso('label has-text-danger');
            setButtonDisabled(true);
        } else {
            setParameter({
                display: false,
            })
            setClassPeso('label');
            setButtonDisabled(false);
        }
    }

    const handleMemberChange = (e) => {
        const selectedText = e.target.options[e.target.selectedIndex].text;
        setSelectedMember(e.target.value);
        SetNameMember(selectedText); // Definir o texto do colaborador selecionado no estado
        getMember(e.target.value)
    };



    const handleIndividualActions = async (e) => {
        e.preventDefault();
        let data_one_iso = new Date(data_one);
        data_one_iso = data_one_iso.toISOString();

        let deadline_iso = new Date(deadline);
        deadline_iso = deadline_iso.toISOString();

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(
                {
                    description: description,
                    burden: burden,
                    data_one: data_one_iso,
                    action: action,
                    deadline: deadline_iso,
                    id_collaborator: Number(selectedMember),
                    name_collaborator: nameMember,
                    team: Number(team),
                    leader: 7,
                    situation: "NAO_INICIADO",
                    type: 0,
                    date_completion: "2022-07-18T18:13:04.248Z",
                    status: true,
                    id_user: me.id,
                    id_gap: Number(id_gap),
                    id_subtime: subteam,
                    name_subtime: nameSubTeam
                }
            ),
        };
        const reponse = await fetch("/api/individual_actions", requestOptions);

        // if(reload == 1){
        //     setReload(2);
        // }else{
        //     setReload(1);
        // }
        setParameter({
            display: true,
            text: "Salvo com sucesso.",
            color: "notification is-success"
        })
        get_peso();
        setAction('');
        setDescription('');
        setDeadline(null);
        window.location.reload();
    };
    return (
        <>
            <ReactTooltip id='info_1' effect='solid'>
                <span>O líder deve avaliar a criticidade do liderado em relação à demissão voluntária</span>
            </ReactTooltip>
            <ReactTooltip id='info_2' effect='solid'>
                <span>O líder deve registrar as causas dos problemas e desafios do liderado</span>
            </ReactTooltip>
            <ReactTooltip id='info_3' effect='solid'>
                <span>Escolha o tipo de GAP referente ao problema/desafio do seu liderado</span>
            </ReactTooltip>
            <ReactTooltip id='info_4' effect='solid'>
                <span>Peso para as ações de um determinado mês, distribua o peso de cada ação de tal forma que a soma dos pesos resulte em 100%</span>
            </ReactTooltip>
            <ReactTooltip id='info_5' effect='solid'>
                <span>Defina a ação necessária para tratar o problema/desafio do seu liderado</span>
            </ReactTooltip>
            <ReactTooltip id='info_6' effect='solid'>
                <span>Informações sobre o componente</span>
            </ReactTooltip>
            <div className={`modal ${showModalAcao && "is-active"}`}>
                <div className="modal-background" onClick={() => {
                    setModalhowAcao(false)
                }}></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Nova Ação</p>
                        <button onClick={() => {
                            setModalhowAcao(false)
                        }} className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        <Notification
                            notification_parameters={notificationParameters}
                            setParameter={setParameter}
                        />

                        <div className="column">
                            <div className="select is-fullwidth is-primary">
                                <select
                                    id="selectMember"
                                    value={selectedMember}
                                    onChange={(e) => handleMemberChange(e)}
                                    disabled={disabled}
                                    ref={selectRef}
                                >
                                    {!disabled ? (<option key={-1} value="">
                                        Membros
                                    </option>):(<option>{nameMember}</option>)}
                                    <RequestMember
                                        selectedTeam={selectedTeams}
                                        members={membersList}
                                        setMembers={setMembersList}
                                    />
                                </select>
                                </div>
                        </div>
                        <div className="box">
                            <div className="columns">
                                <div className="column">
                                    <label className="label">Data 1:1</label>
                                    <div className="field">
                                        <input
                                            className="input"
                                            type="date"
                                            value={data_one}
                                            onChange={(e) => setData_one(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="column">
                                    <label className="label">GAP</label>
                                    <div className="select is-fullwidth">
                                        {selectedGAP && (
                                            <select value={id_gap} onChange={(e) => setGAP(e.target.value)}>
                                                <option key={-1} value="">Selecione</option> {/* Valor padrão vazio */}
                                                {selectedGAP.map((team) => (
                                                    <option key={`team_${team.id}`} value={team.id}>{team.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <label className="label">Registros das causas</label>
                                </div>
                                <div className="columns">
                                    <a className="card-header-icon" data-tip data-for="info_2" data-event="click focus">
                              <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-question-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path
                                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                </svg>
                              </span>
                                    </a>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <input
                                        className="input"
                                        type="hidden"
                                        value={maxBurden}
                                        onChange={(e) => setMaxBurden(e.target.value)}
                                    />
                                    <textarea className="textarea"
                                              rows="2"
                                              value={description}
                                              onChange={(e) => setDescription(e.target.value)}
                                    >
                          </textarea>
                                </div>
                            </div>
                            <div className="columns">

                            </div>

                            <div className="columns">
                                <div className="column">
                                    <label className="label">Ação</label>
                                </div>
                                <a className="card-header-icon" data-tip data-for="info_5" data-event="click focus">
                          <span className="icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                   className="bi bi-question-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path
                                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                              </svg>
                          </span>
                                </a>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <input
                                        className="input"
                                        type="text"
                                        value={action}
                                        onChange={(e) => setAction(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-one-third">
                                    <label className="label">Peso</label>
                                    <div className="field">
                                        <p className="control has-icons-left has-icons-right">
                                            <input
                                                className="input"
                                                type="number"
                                                value={burden}
                                                min="1"
                                                max="100"
                                                onChange={(e) => setBurden(e.target.value)}
                                            />
                                            <span className="icon is-small is-right">
                                                <i className="fas fa-percent"></i>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="column">
                                    <label className="label">Prazo</label>
                                    <input
                                        className="input"
                                        type="date"
                                        value={deadline}
                                        onChange={(e) => setDeadline(e.target.value)}
                                    />
                                </div>
                            </div>
                            <button
                                disabled={buttonDisabled}
                                className="button is-info is-fullwidth"
                                onClick={handleIndividualActions}>
                                Incluir
                            </button>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button onClick={() => {
                            setModalhowAcao(false)
                        }} className="button">Fechar
                        </button>
                    </footer>
                </div>
            </div>
        </>
    );

};
export default ModalNewAction;
