import React, { useRef } from 'react';

const ModalEditSkills = ({showModalEdit, setShowModalEdit, onResponse, table, setTable, setObj, setNameNew, nameNew}) => {
  const labels = {
    'tag': 'TAG',
    'hs': 'HardSkill',
    'ss': 'SoftSkill'
  };

  const confirmButtonRef = useRef(null); // Create a ref for the Confirmar button

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) { // Listen for the Enter key
      e.preventDefault(); // Prevent the default behavior of the Enter key
      confirmButtonRef.current.click(); // Programmatically click the Confirmar button
    }
  };

  return (
    <div>
      <div className={`modal ${showModalEdit && "is-active"}`}>
        <div className="modal-background" onClick={() => {setShowModalEdit(false)}}></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div className="columns">
              <div className="column">
                <div className="box">
                  <div className="columns">
                    <div className="column is-one-fifth">
                      <label className="label">Editar {labels[table]}</label>
                      <input 
                        className="input" 
                        type="text" 
                        placeholder="Nome"
                        value={nameNew}
                        onChange={(e) => setNameNew(e.target.value)}
                        onKeyDown={handleKeyDown} // Add the keydown event listener
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-link" onClick={() => {onResponse()}} ref={confirmButtonRef}>Confirmar</button>
            <button 
              onClick={() => {
                setShowModalEdit(false);
                setObj(null);
                setTable(null);
              }} 
              className="button"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ModalEditSkills;
