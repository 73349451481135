import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import MemberImage from '../comp_teamguide/MemberImage';

const TableColab = ({ data, unique, setSearchTable, searchTable }) => {
  const recordsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingOrder, setSortingOrder] = useState('ASC');

  // Calculate the total number of pages if the unique array is not null
  const totalPages = unique ? Math.ceil(unique.length / recordsPerPage) : 0;

  // Handler to change the current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handler for "Previous" button click
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Handler for "Next" button click
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Helper function to generate an array of page numbers for pagination
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5; // Number of page numbers to display in the pagination

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  // Function to handle column sorting
  const handleSorting = (column) => {
    if (sortingColumn === column) {
      // If the same column is clicked, reverse the sorting order
      setSortingOrder((prevOrder) => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
    } else {
      // If a different column is clicked, set it as the sorting column with ASC order
      setSortingColumn(column);
      setSortingOrder('ASC');
    }
  };

  // Function to sort the unique array based on the current sorting column and order
  const sortedUnique = () => {
    if (!sortingColumn) {
      return unique;
    }
    return [...unique].sort((a, b) => {
      const aValue = a[sortingColumn].toLowerCase();
      const bValue = b[sortingColumn].toLowerCase();
      return sortingOrder === 'ASC'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
  };

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTable(e.target.value.toLowerCase());
    setCurrentPage(1); // Reset the current page when searching
  };

  // Helper function to get the current records for the current page
  const getCurrentRecords = () => {
    const startIdx = (currentPage - 1) * recordsPerPage;
    const endIdx = startIdx + recordsPerPage;
    return unique ? sortedUnique().slice(startIdx, endIdx) : [];
  };

  // Render the data for the current page
  const renderedData = getCurrentRecords();

  return (
    <>
      <ReactTooltip id='info' effect='solid'>
        <span>Informações sobre o componente</span>
      </ReactTooltip>

      <div className='card-content' style={{ height: '532px', overflowY: 'scroll' }}>
        {data && (
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th></th>
                <th onClick={() => handleSorting('name_collaborator')} style={{ cursor: 'pointer' }}>
                  Colaborador
                  {sortingColumn === 'name_collaborator' && (
                    <i
                      className={`fa-solid fa-sort-${sortingOrder === 'ASC' ? 'down' : 'up'}`}
                      style={{ marginLeft: '4px', fontSize: '0.8em', color: 'blue' }}
                    />
                  )}
                </th>
                <th onClick={() => handleSorting('name_team')} style={{ cursor: 'pointer' }}>
                  Equipe
                  {sortingColumn === 'name_team' && (
                    <i
                      className={`fa-solid fa-sort-${sortingOrder === 'ASC' ? 'down' : 'up'}`}
                      style={{ marginLeft: '4px', fontSize: '0.8em', color: 'blue' }}
                    />
                  )}
                </th>
                <th>GAP</th>
              </tr>
            </thead>
            <tbody>
              {renderedData.map((obj) => (
                <tr key={obj.id}>
                  <td>
                    <MemberImage memberId={obj.id_collaborator} colaboradorName={obj.name_collaborator} width={50} height={40} />
                  </td>
                  <td>{obj.name_collaborator}</td>
                  <td>{obj.name_team}</td>
                  <td>
                    <ul>
                      {data
                        .filter((element) => element.id_collaborator === obj.id_collaborator)
                        .map((element) => (
                          <li key={element.id}>{element.gap}</li>
                        ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Pagination */}
        {totalPages > 1 && (
          <nav className='pagination is-centered is-small is-rounded' role='navigation' aria-label='pagination'>
            <ul className='pagination-list'>
              {/* "Previous" button */}
              <li>
                <a
                  className='pagination-previous'
                  aria-label='Previous'
                  onClick={handlePreviousClick}
                >
                  Anterior
                </a>
              </li>

              {/* Page numbers */}
              {getPageNumbers().map((pageNumber) => (
                <li key={pageNumber}>
                  <a
                    style={{ fontSize: "12px" }}
                    className={`pagination-link${pageNumber === currentPage ? ' is-current' : ''}`}
                    aria-label={`Goto page ${pageNumber}`}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </a>
                </li>
              ))}

              {/* "Next" button */}
              <li>
                <a
                  className='pagination-next'
                  aria-label='Next'
                  onClick={handleNextClick}
                >
                  Próximo
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </>
  );
};

export default TableColab;
