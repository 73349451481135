import React, {useState, useEffect,  useContext} from 'react'
import { UserContext } from "../../context/UserContext";

const LiderSelect = ({selectedLider, setSelectedLider}) => {
    const [lideres, setLideres] = useState(null);
    const [token] = useContext(UserContext);

    const getLideres = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch("/api/users", requestOptions);

        const data = await response.json();
        setLideres(data.filter(user => user.type === 'cliente'));
    };
    useEffect(() => {
        getLideres();
    }, [])

    return (

            <div className="select is-fullwidth">
            <select
                value={selectedLider}
                onChange={(e) => setSelectedLider(e.target.value)}
                >
                <option>Clientes</option>
                {
                    lideres &&
                    lideres.map(lider => (
                        <option key={lider.id} value={lider.id}>{lider.full_name}</option>
                    ))
                }
                </select>
            </div>

  )
}

export default LiderSelect