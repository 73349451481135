import React from 'react';
import { createRoot } from 'react-dom/client';
import "bulma/css/bulma.css";
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';
import './index.css';

import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { UserProvider } from './context/UserContext';


const container = document.getElementById('root');

const root = createRoot(container);

root.render(

  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </UserProvider>
);



