import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import TableColaboradores from "./TableColaboradores";
import TimesNew from "../comp_teamguide/TimesNew";

const ColaboradorLista = ({ me, faker }) => {
  const [token] = useContext(UserContext);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [colaboradores, setColaboradores] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState(0);

  const [size] = useState(5);
  const [lastPage, setLastPage] = useState(null);
  const [page, setPage] = useState(1);

  const [searchValue, setSearchValue] = useState("");
  const [orderField, setOrderField] = useState("name");
  const [orderFieldAsc, setOrderFieldAsc] = useState(true);

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const fetchData = async (url, options) => {
    setLoadingRequests((prev) => prev + 1);
    try {
      const response = await fetch(url, options);
      return await response.json();
    } finally {
      setLoadingRequests((prev) => prev - 1);
    }
  };

  const [seniorities, setSeniorities] = useState([]);

  useEffect(() => {
    fetch("/api/datadrivens", options)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((jsonData) => {
        setSeniorities(jsonData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

const fetchColaboradores = async () => {
  setLoading(true);
  let requestsPending = selectedTeams.length;

  try {
    const urls = selectedTeams.map((team) => `/api/ms/time/collaborators/${team}`);
    const fetchPromises = urls.map((url) => fetchData(url, options));
    const results = await Promise.all(fetchPromises);

    let concatenatedResult = results.reduce((acc, data) => acc.concat(data), []);

    concatenatedResult = concatenatedResult.map((obj) => {
      const seniorityObj = seniorities.find((seniority) => seniority.id_collaborator === obj.id);
      const seniority = seniorityObj ? seniorityObj.senioridade : "Não Informado";
      return { ...obj, seniority };
    });

    const returnedData = filterAndSortData(concatenatedResult);

    const total = returnedData.length;
    const ratio = Math.floor(total / size);
    setLastPage(total <= size ? 1 : total % size === 0 ? ratio : ratio + 1);
    const startIndex = (page - 1) * size;
    const endIndex = Math.min(returnedData.length, startIndex + size);
    const pageData = returnedData.slice(startIndex, endIndex);

    setColaboradores(pageData);
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    requestsPending--;
    if (requestsPending === 0) {
      setLoading(false);
    }
  }
};

  const filterAndSortData = (data) => {
    let filteredData = data;
    if (searchValue) {
      filteredData = data.filter(
        (employee) =>
          (employee.name && employee.name.toLowerCase().includes(searchValue.toLowerCase())) ||
          (employee.seniority && employee.seniority.toLowerCase().includes(searchValue.toLowerCase())) ||
          (employee.position && employee.position.toLowerCase().includes(searchValue.toLowerCase()))
      );
    }
    return sortEmployeesData(filteredData);
  };

  const sortEmployeesData = (data) => {
    return data.sort((a, b) => {
      if (a[orderField] < b[orderField]) return orderFieldAsc ? -1 : 1;
      else if (a[orderField] > b[orderField]) return orderFieldAsc ? 1 : -1;
      return 0;
    });
  };

  const handleSearchChange = async (event) => {
    setSearchValue(event.target.value);
    setPage(1);
    selectedTeams && (await fetchColaboradores());
  };

  useEffect(() => {
    if (loadingRequests === 0 && loading) {
      setLoading(false);
    }
  }, [loadingRequests, loading]);

  useEffect(() => {
    setPage(1);
    selectedTeams && fetchColaboradores();
  }, [selectedTeams, searchValue, orderField, orderFieldAsc]);

  return (
    <div>
      <main>
        <h1 className="title mb-5">Colaboradores</h1>
      </main>

      <div className="columns">
        <div className="column is-10">
          <TimesNew selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} faker={false} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <TableColaboradores
            colaboradores={colaboradores}
            fetchColaboradores={fetchColaboradores}
            lastPage={lastPage}
            page={page}
            setPage={setPage}
            selectedTeams={selectedTeams}
            searchValue={searchValue}
            handleSearchChange={handleSearchChange}
            orderField={orderField}
            orderFieldAsc={orderFieldAsc}
            setOrderField={setOrderField}
            setOrderFieldAsc={setOrderFieldAsc}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ColaboradorLista;
