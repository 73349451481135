import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieGraphPesquisa = ({ pesquisa }) => {
  const [data, setData] = useState(null);
  const [labels, setLabels] = useState(null);
  const [customLabels, setCustomLabels] = useState(null);

  useEffect(() => {
    if (pesquisa && pesquisa.desenvolvimento !== undefined) {
      setData([pesquisa.desenvolvimento, pesquisa.dinheiro, pesquisa.clima]);
      setLabels(["Desenvolvimento", "Dinheiro", "Clima"]);
    }
  }, [pesquisa]);

  useEffect(() => {
    data &&
      setCustomLabels(
        labels.map(
          (label, index) => `${label}: ${Math.round(data[index] * 10)}%`
        )
      );
  }, [data]);
  return (
    pesquisa &&
    pesquisa.desenvolvimento !== undefined && (
      <div>
        <Pie
          data={{
            labels: customLabels,
            datasets: [
              {
                label: "# of Votes",
                data: [
                  Math.round(pesquisa.desenvolvimento),
                  Math.round(pesquisa.dinheiro),
                  Math.round(pesquisa.clima),
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                ],
                borderWidth: 1,
              },
            ],
          }}
          width={"240px"}
          height={"240px"}
          options={{ maintainAspectRatio: false }}
        />
      </div>
    )
  );
};

export default PieGraphPesquisa;
