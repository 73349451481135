import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import * as XLSX from "xlsx";

const UploadPesquisa = () => {
  const [token] = useContext(UserContext);
  const [pesquisas, setPesquisas] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [name, setName] = useState(null);
  const [tokenTeamGuide, setTokenTeamGuide] = useState(null);
  const [tokenTeamGuideDB, setTokenTeamGuideDB] = useState(null);

  const getAllEmployees = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    // get id of the employee from token
    const id = await requestOptions.headers.Authorization.split(" ")[1]
    const response = await fetch(
      "api/get_collaborator/" + id,
      requestOptions
    );
    const data = await response.json();
    setEmployees(data);
  };

  const getToken = async (e) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(`/api/token/teamguide`, requestOptions);
    const data = await response.json();
    setTokenTeamGuideDB(data);
    if (data.detail === "Token does not exist!") {
      //console.log("updart");
      createToken();
    } else {
      handleToken();
    }
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;

        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        const handleId = (email) => {
          try {
            return employees.filter(
              (item) => item.contactEmail === email
            )[0].id
          } catch (error) {
            return 0
          }
        }

        setPesquisas(
          json
            .map((v) => ({
              ...v,
              id_collaborator: handleId(v["e-mail"]),
            }))
            .map(function (item) {
              delete item["e-mail"];
              return item;
            })
        );
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      setName(e.target.files[0].name);
    }
  };

  const handleToken = async (e) => {
    if (tokenTeamGuide) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          token: tokenTeamGuide,
        }),
      };
      let r = await fetch("/api/token/teamguide/1", requestOptions);
      setTokenTeamGuide("");
    }
  };

  const createToken = async (e) => {
    if (tokenTeamGuide) {
      //console.log(tokenTeamGuide);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          token: tokenTeamGuide,
        }),
      };
      let r = await fetch("/api/tokencreate/teamguide", requestOptions);
      setTokenTeamGuide("");
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    await pesquisas.forEach((pesquisa) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          id_collaborator: pesquisa.id_collaborator,
          clima: parseInt(pesquisa.clima),
          dinheiro: parseInt(pesquisa.dinheiro),
          desenvolvimento: parseInt(pesquisa.desenvolvimento),
        }),
      };
      fetch(`/api/pesquisas`, requestOptions);
    });
    setName(null);
    setPesquisas(null);
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  return (
    <div>
      <h1 className="subtitle">Upload</h1>
      <div className="columns">
        <div className="column">
          <div className="file is-boxed is-fullwidth">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="upload"
                id="upload"
                onChange={readUploadFile}
              />
              <span className="file-cta">
                <span className="file-label">Choose a file…</span>
              </span>
              {name && <span className="file-name">{name}</span>}
            </label>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-two-thirds">
          <label className="label">Token</label>
          <input
            className="input"
            type="text"
            value={tokenTeamGuide}
            onChange={(e) => setTokenTeamGuide(e.target.value)}
          />
        </div>
        <div className="column">
          <label className="label">&nbsp;</label>
          <button className="button is-info" onClick={getToken}>
            Save
          </button>
        </div>
      </div>
      {pesquisas && (
        <button className="button is-fullwidth" onClick={handleUpload}>
          Upload
        </button>
      )}
    </div>
  );
};

export default UploadPesquisa;
