import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import TimesNew from "./comp_teamguide/TimesNew";
import TablePerformance from "./comp_forms/TablePerformance";
import ModalPerformance from "./comp_forms/modals/ModalPerformance";
import ModalNPS from "./comp_forms/modals/ModalNPS";

const Performance = ({me, faker}) => {
  const [token] = useContext(UserContext);
  const [selectedTeam, setSeletedTeam] = useState(null);
  const [performances, setPerformances] = useState(null);
  const [chosenPerformance, setChosenPerformance] = useState(null);
  const [chosenHard, setChosenHard] = useState(null);
  const [chosenSoft, setChosenSoft] = useState(null);
  const [loading, setLoading] = useState(false);
  const [collaborator, setCollaborator] = useState(null);
  const [show, setShow] = useState(false);
  const [chosenClassNPS, setChosenClassNPS] = useState(null);
  const [chosenSubTime, setChosenSubTime] = useState(null);


  const [showNPS, setShowNPS] = useState(false);
  const [NPS, setNPS] = useState('');
  const [nameCollaborator, setNameCollaborator] = useState('');

  const [size] = useState(5);
  const [lastPage, setLastPage] = useState(null);
  const [page, setPage] = useState(1);

  const [hardSkill, setHardSkill] = useState(null);
  const [softSkill, setSoftSkill] = useState(null);
  const [skillsCollaborator, setSkillsCollaborator] = useState(null);

  const getPerformances = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      `/api/performances/${selectedTeam}?page=${page}&size=${size}`,
      requestOptions
    );
    const data = await response.json();

    setPerformances(data.items);
    setLoading(false);
    setLastPage(Math.floor(data.total / data.size));
  };

  const getSkills = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const ss = await fetch("/api/all_softskill", requestOptions);
    const data = await ss.json();
    const softskill = data.map(function(item) {
      return {
          id: item.id,
          label: item.name
          };
      });
    setSoftSkill(softskill);

  };

  const getSkillsCollaborator = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`/api/skill/${collaborator}`, requestOptions);
    const data = await response.json();
    if(data){
      setSkillsCollaborator(data);
    }else{
      setSkillsCollaborator([]);
    }
  };

  const getHardSkillCollaborator = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const hs = await fetch(`/api/tag_subteam_user/${collaborator}`, requestOptions);
    const data_2 = await hs.json();
    if (data_2.length){
      const newObject = {
        items: data_2.map(function(item) {
            return {
                id: item.id,
                label: item.name
            };
        }),
        selectedItems: []
    };
      setHardSkill(newObject);
    }else{
      setHardSkill([]);
    }

  };

  const get_nps = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/ms/NPS/teams/mean/" + selectedTeam, requestOptions);
    const data = await response.json();
    setNPS(data);
  };

  const getUpdatePerformances = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    setLoading(true);
    await fetch(`/api/update/performances/${selectedTeam}`, requestOptions);
    getPerformances();
  };

  useEffect(() => {
    setPerformances(null)
    selectedTeam && getPerformances();
    setPage(1);
  }, [selectedTeam]);

  useEffect(() => {
    getSkills();
    selectedTeam && getPerformances();
  }, []);

  useEffect(() => {
    if(collaborator){
      getSkillsCollaborator();
      getHardSkillCollaborator();
    }
  }, [collaborator]);


  return (
    <div>
      <ModalPerformance
        show={show}
        setShow={setShow}
        collaborator={collaborator}
        setCollaborator={setCollaborator}
        performance={chosenPerformance}
        setPerformance={setChosenPerformance}
        getPerformances={getPerformances}
        token={token}
        hardSkill={hardSkill}
        softSkill={softSkill}
        skillsCollaborator={skillsCollaborator}
        selectedTeam={selectedTeam}
        chosenClassNPS={chosenClassNPS}
        chosenSubTime={chosenSubTime}
        nameCollaborator={nameCollaborator}
      />
      <ModalNPS
        showNPS={showNPS}
        setShowNPS={setShowNPS}
        collaborator={collaborator}
        nameCollaborator ={nameCollaborator}
        setCollaborator={setCollaborator}
        NPS={NPS}
        setNPS={setNPS}
      />
      <h1 className="subtitle">Performance</h1>
      <div className="columns">
        <div className="column is-10">
          <TimesNew selectedTeams={selectedTeam} setSelectedTeams={setSeletedTeam} faker={faker}/>
        </div>
        {me && me.type != "cliente" && (
          <div className="column">
            <button
              className={`button is-fullwidth ${loading && "is-loading"}`}
              onClick={getUpdatePerformances}
            >
              Atualizar
            </button>
          </div>
        )}
      </div>
      <div className="columns">
        <div className="column">
          <TablePerformance
            performances={performances}
            token={token}
            getPerformances={getPerformances}
            setCollaborator={setCollaborator}
            setShow={setShow}
            setShowNPS={setShowNPS}
            get_nps={get_nps}
            setNameCollaborator={setNameCollaborator}
            setChosenPerformance={setChosenPerformance}
            setChosenHard={setChosenHard}
            setChosenSoft={setChosenSoft}
            lastPage={lastPage}
            page={page}
            setPage={setPage}
            setChosenClassNPS={setChosenClassNPS}
            setChosenSubTime={setChosenSubTime}
          />
        </div>
      </div>
    </div>
  );
};

export default Performance;
