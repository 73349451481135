import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';

const TimesSelect = ({ selectedTeams, setSelectedTeams}) => {
  const [teams, setTeams] = useState(null);
  const [token] = useContext(UserContext);

  const getTeams = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    const response = await fetch(`api/ms/get_teams`, requestOptions);
    if (response.ok) {
      const data = await response.json();
      setTeams(data);
    }
  };

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    if (teams && teams.length > 0) {
      setSelectedTeams(teams[0].id); // Seleciona o primeiro time
    }
  }, [teams, setSelectedTeams]);

return (
    <>
        {teams ? (
          <div className="select ">
            <select
              value={selectedTeams}
              onChange={(e) => setSelectedTeams(e.target.value)}
            >
              {teams.map((team, index) => (
                <option key={index} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <p>Buscando times...</p>
        )}

    </>
  );
};


export default TimesSelect;
