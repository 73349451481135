import React, {useState, useEffect} from 'react'
import ModalNotification from './modals/ModalNotification'
import ModalEditSkills from './modals/ModalEditSkills'
import ReactPaginate from 'react-paginate';

import '../../../src/paginacao.css';

const GetTagSkills = ({token, tags, value, reload, getTags, setReload}) => {
    const [th, setTh] = useState([])
    const [td, setTd] = useState([])
    const [edit, setEdit] = useState(null);
    const [valuesGet, setValuesGet] = useState(null)
    
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);

    const [modalResponse, setModalResponse] = useState(null);
    const [modalMessage, setModalMessage] = useState(null);

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 6;

    const [obj, setObj] = useState(null);
    const [table, setTable] = useState(null);
    const [nameNew, setNameNew] = useState(null);

    const get_values = async () => {
        if (value !== 'tag'){
            let rota='';
            if (value ==='hs'){
                rota = '/api/all_hardskill';
            }else if(value ==='ss'){
                rota = '/api/all_softskill';
            }
            
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
            };
            
            const response = await fetch(rota, requestOptions);
            const data = await response.json();
            setValuesGet(data);
        }
    };
    useEffect(() => {
        get_values()
    }, [reload])

    const erase = async () => {
        let rota = '';
        if(edit ==='tag'){
            rota = `api/tag_subteam/${modalResponse.v1}`;
        }
        else if(edit ==='hs'){
            rota = `api/hardskill/${modalResponse.v1}`;
        }
        else if(edit ==='ss'){
            rota = `api/softskill/${modalResponse.v1}`;
        }
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        await fetch(rota, requestOptions);
        setShowModal(false);
        if(edit ==='tag'){
            window.location.reload();
        }else{
            get_values();
        }
    }

    useEffect(() => {
        if (tags){
            if (value === 'tag'){
                const row = [];
                setTh(['ID','TAG'])
                tags.forEach(function(i) {
                    row.push({'v1': i.id, 'v2': i.name})
                  });
               setTd(row);
            }       
        }
        if(valuesGet){
            const row = [];
            valuesGet.forEach(function(i) {
                if (value !=='tag'){
                    row.push({'v1': i.id, 'v2': i.name})
                }
            });
            setTd(row)
            if(value === 'ss'){
                setTh(['ID','SoftSkill'])
            }else if(value === 'hs'){
                setTh(['ID', 'hardSkill'])
            }
        }
    }, [tags, valuesGet])

    function renderID(obj) {
        return <td>{obj.v1}</td>;
      }
      
      function renderName(obj) {
        return <td>{obj.v2}</td>;
      }

    useEffect(() => {
        if(obj){
            if(table){
                setNameNew(obj.v2)
            }
        }
    }, [obj])

      const handlePUT = async () => {
        let body = {};
        let rota = 'api';
        //console.log(table);
        body = {name: nameNew, status: true};
        if(table ==='tag'){
            rota = `api/edit_tag_subteam/${obj.v1}`;
        }
        else if(table ==='hs'){
            rota = `api/edit_hardskill/${obj.v1}`;
        }
        else if(table === 'ss'){
              rota = `api/edit_softskill/${obj.v1}`;
        }
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(body),
        };
        await fetch(rota, requestOptions);
        setObj(null);
        setTable(null);
        setReload(reload => !reload);
        getTags();
        setShowModalEdit(false);
    }

      function handleEditClick(obj) {
        setShowModalEdit(true);
        setEdit(value);
        setObj(obj);
        setTable(value);
      }

      function handleDeleteClick(obj) {
        setEdit(value);
        setModalResponse(obj);
        setShowModal(true);
        setModalMessage(`Deseja realmaente remover: ${obj.v2}?`);
      }
    return(
        
        <>  
            <ModalNotification
                message={modalMessage}
                onResponse={erase}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <ModalEditSkills
                showModalEdit={showModalEdit}
                setShowModalEdit={setShowModalEdit}
                onResponse={handlePUT}
                table={table}
                setTable={setTable}
                setObj={setObj}
                setNameNew={setNameNew}
                nameNew={nameNew}
            />
            <div className="box">
            <table className="table is-fullwidth" style={{ width: '100%' }}> 
              <thead>
                  <tr>
                  {
                  th.map(value => (
                    <th>{value}</th>
                  ))
                  }
                  <th>edit</th>
                  </tr>
                </thead>
                <tbody>
                
                {td.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage).map((obj, index) => {
                    return (
                        <tr key={index}>
                        {renderID(obj)}
                        {renderName(obj)}
                        <td >
                            <a to="#" onClick={() => handleEditClick(obj)}>
                            <i className="fa fa-edit" style={{color: 'grey'}} />
                            </a>
                            &nbsp;
                            <a to="#" onClick={() => handleDeleteClick(obj)}>                                                    
                            <i className="fas fa-trash-alt" style={{color: 'red'}} />
                            </a>
                        </td>
                        </tr>
                    );
                    })}

                </tbody>
              </table>
              <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(td.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={({ selected }) => setPageNumber(selected)}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    pageClassName={"page-link"}
                    renderOnZeroPageCount={null}
                    />
            </div>     
        </>
    )
}
export default GetTagSkills