import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import ReactPaginate from 'react-paginate';
import '../../../src/paginacao.css';

const ReportTable = ({ actions, setActionsSelected, msg }) => {
  const [selectedActions, setSelectedActions] = useState([]);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;

const handlePageClick = ({ selected }) => {
  setPageNumber(selected);
};

  const handleSelectAction = (event, id) => {
    const isChecked = event.target.checked;

    // Update the selectedActions state variable
    if (isChecked) {
      setSelectedActions([...selectedActions, id]);
    } else {
      setSelectedActions(selectedActions.filter((actionId) => actionId !== id));
    }

    // Update the setActionsSelected function
    setActionsSelected((prevSelected) => {
      if (isChecked) {
        return [...prevSelected, id];
      } else {
        return prevSelected.filter((actionId) => actionId !== id);
      }
    });
  };

  useEffect(() => {
    const rows = document.querySelectorAll(".table tbody tr");
    rows.forEach((row) => {
      const checkbox = row.querySelector("input[type='checkbox']");
      const id = parseInt(checkbox.value);
      if (selectedActions.includes(id)) {
        row.classList.add("selected");
      } else {
        row.classList.remove("selected");
      }
    });
  }, [selectedActions, pageNumber]);  
  
  return (
    <>
      <ReactTooltip id="engajamento" effect="solid">
        <span>
        Selecione as ações que deseja incluir no relatório.
        </span>
      </ReactTooltip>
      <div className="card">
        <div className="card-header">
          <p className="card-header-title">Ações</p>
          <a
            className="card-header-icon"
            data-tip
            data-for="engajamento"
            data-event="click focus"
          >
            <span className="icon">
            <a data-tip data-for="info2" data-event="click focus">
              <i className="fa fa-circle-info"></i>
            </a>
            </span>
          </a>
        </div>
        <div className="card-content">
        {actions.length ? (
  <div>
    <table className="table">
      <thead>
        <tr>
          <th></th>
          <th>Nome</th>
          <th>Causa da Ação</th>
          <th>Ação</th>
          <th>Prazo</th>
        </tr>
      </thead>
      <tbody>
        {actions
          .slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)
          .map((action) => (
            <tr key={action.id}>
              <td>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    value={action.id}
                    checked={selectedActions.includes(action.id)}
                    onChange={(event) => handleSelectAction(event, action.id)}
                  />
                </label>
              </td>
              <td>{action.name_collaborator}</td>
              <td>{action.description}</td>
              <td>{action.action}</td>
              <td>
                {new Date(action.deadline).toLocaleDateString("pt-BR", options)}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
    <ReactPaginate
      breakLabel={"..."}
      previousLabel={"< previous"}
      onPageChange={handlePageClick}
      nextLabel={"next >"}
      pageCount={Math.ceil(actions.length / itemsPerPage)}
      pageRangeDisplayed={3}
      renderOnZeroPageCount={null}
      containerClassName={"pagination"}
      pageLinkClassName="page-num"
      previousClassName="page-num"
      nextClassName="page-num"
      activeClassName={"active"}
    />
  </div>
) : (
  <p className="has-text-danger">{msg}</p>
)}

        </div>
      </div>
    </>
  );
};

export default ReportTable;
