import React from 'react';

export const InfoHeaderNumbers = ({colaborador, dataDriven, me, salario}) => {
    function numberToReal(numberString) {
        const number = parseFloat(numberString);
        return number.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const localDay = date.getUTCDate().toString().padStart(2, "0");
        const localMonth = (date.getUTCMonth() + 1).toString().padStart(2, "0");
        const localYear = date.getUTCFullYear();
        return `${localDay}/${localMonth}/${localYear}`;
    }

    function months(referenceDate) {
        const referenceDateObj = new Date(referenceDate);
        const currentDateObj = new Date();
        const numberMonths = (currentDateObj.getFullYear() - referenceDateObj.getFullYear()) * 12 +
            (currentDateObj.getMonth() - referenceDateObj.getMonth());
        return numberMonths > 0 ? `${numberMonths} meses` : 'Não Informado'
    }

    let numeroPromocoes = 0;
    let ultimaPromocao = null;
    let percentagem = 0;



    return (
        <>
            <div className="column right-border">
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Tempo de Profectum</label>
                    <div className="control">
                        <div className="has-text-weight-bold  text-darkblue">{months(colaborador.date_admission)}</div>
                    </div>
                </div>
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Tempo de Empresa</label>
                    <div className="control">
                        <div className="has-text-weight-bold text-darkblue">{months(dataDriven.data_empresa)}</div>
                    </div>
                </div>
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Tempo médio em outras empresas</label>
                    <div className="control">
                        <div className="has-text-weight-bold  text-darkblue">
                            {dataDriven.tempo_medio_empresas_meses > 0 ? `${dataDriven.tempo_medio_empresas_meses} meses` : 'Não Informado'}
                        </div>
                    </div>
                </div>
            </div>
            {me && me.type != 'cliente' && (
                <>
            <div className="column right-border">
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Salário atual</label>
                    <div className="control">
                        <div className="has-text-weight-bold text-darkblue">
                            {salario[0]?.salary_amount ? numberToReal(salario[0].salary_amount) : "Sem informação"}
                        </div>
                    </div>
                </div>
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Salário médio no mercado</label>
                    <div className="control">
                        <div
                            className="has-text-weight-bold text-darkblue">{numberToReal(dataDriven.salario_medio_cargo_senioridade ?? 0)}</div>
                    </div>
                </div>
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Salário médio no time</label>
                    <div className="control">
                        <div
                            className="has-text-weight-bold  text-darkblue">{numberToReal(dataDriven.salario_medio_cargo_time
                         ?? 0)}</div>
                    </div>
                </div>
            </div>
            <div className="column right-border">
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Número de promoções</label>
                    <div className="control">
                        <div className="has-text-weight-bold  text-darkblue">{numeroPromocoes}</div>
                    </div>
                </div>
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">% no aumento da promoção</label>
                    <div className="control">
                        <div className="has-text-weight-bold text-darkblue">{percentagem}%</div>
                    </div>
                </div>
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Data da última promoção</label>
                    <div className="control">
                        <div className="has-text-weight-bold  text-darkblue">{ultimaPromocao ? formatDate(ultimaPromocao): ''}</div>
                    </div>
                </div>
            </div>
            </>
            )}
            <div className="column">
            {me && me.type != 'cliente' && (
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Faturamento</label>
                    <div className="control">
                        <div
                            className="has-text-weight-bold  text-darkblue">{numberToReal(dataDriven.faturamento ?? 0)}</div>
                    </div>
                </div>
                )}
                <div className="field is-flex is-flex-direction-column">
                    <label className="is-size-7">Modalidade de trabalho</label>
                    <div className="control">
                        <div className="has-text-weight-bold text-darkblue">{dataDriven.modalidade_trabalho}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
