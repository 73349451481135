import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";

const DataDrivenModal = ({
  show,
  setShow,
  id,
  name,
  dataDrivenData,
  setDataDrivenData,
  selectedMember,
}) => {
  const [token] = useContext(UserContext);

  const [senioridade, setSenioridade] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [tempoMedio, setTempoMedio] = useState("");
  const [salarioSenioridade, setSalarioSenioridade] = useState("");
  const [salarioTime, setSalarioTime] = useState("");
  const [modalidade, setModalidade] = useState("");
  const [social, setSocial] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [faturamento, setFaturamento] = useState("");

  const handleCreateData = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        id_collaborator: id,
        senioridade: senioridade,
        data_empresa: dataInicio + "T00:00:00.000",
        tempo_medio_empresas_meses: tempoMedio,
        salario_medio_cargo_senioridade: salarioSenioridade,
        salario_medio_cargo_time: salarioTime,
        modalidade_trabalho: modalidade,
        engenharia_social: social,
        linkedin_link: linkedin,
        faturamento: faturamento
      }),
    };
    await fetch(`/api/datadrivens`, requestOptions);
  };

  const getDataDrivenData = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      `/api/datadrivens/team/${selectedMember}`,
      requestOptions
    );
    const data = await response.json();
    setDataDrivenData(data);
  };

  const handleUpdateData = async (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        id_collaborator: dataDrivenData.id_collaborator,
        senioridade: senioridade,
        data_empresa: dataInicio + "T00:00:00.000",
        tempo_medio_empresas_meses: tempoMedio,
        salario_medio_cargo_senioridade: salarioSenioridade,
        salario_medio_cargo_time: salarioTime,
        modalidade_trabalho: modalidade,
        engenharia_social: social,
        linkedin_link: linkedin,
        faturamento: faturamento
      }),
    };
    await fetch(
      `/api/datadrivens/team/${dataDrivenData.id_collaborator}`,
      requestOptions
    );
  };

  const verifyInput = () => {
    if (senioridade === "") {
      return false;
    }
    if (linkedin === "") {
        return false;
      }
    if (dataInicio === "") {
      return false;
    }
    if (tempoMedio === "") {
      return false;
    }
    if (salarioSenioridade === "") {
      return false;
    }
    if (salarioTime === "") {
      return false;
    }
    if (modalidade === "") {
      return false;
    }
    if (social === "") {
      return false;
    }
    return true;
  };

  const handleSave = (e) => {
    const flag = verifyInput();
    if (flag) {
      if (dataDrivenData.senioridade !== undefined) {
        handleUpdateData(e);
      } else {
        handleCreateData(e);
      }
      setShow(false);
    } else {
      alert("Por favor, preencha os campos obrigatórios");
    }
  };

  const emptyValues = () => {
    setSenioridade("");
    setDataInicio("");
    setTempoMedio("");
    setSalarioSenioridade("");
    setSalarioTime("");
    setModalidade("");
    setSocial("");
    setLinkedin("");
    setFaturamento("");
  };

  const handleClose = () => {
    setShow(false);
    emptyValues();
  };

  useEffect(() => {
    if (show && dataDrivenData.id_collaborator !== undefined) {
      setSenioridade(dataDrivenData.senioridade);
      setDataInicio(dataDrivenData.data_empresa.split("T")[0]);
      setTempoMedio(dataDrivenData.tempo_medio_empresas_meses);
      setSalarioSenioridade(dataDrivenData.salario_medio_cargo_senioridade);
      setSalarioTime(dataDrivenData.salario_medio_cargo_time);
      setModalidade(dataDrivenData.modalidade_trabalho);
      setSocial(dataDrivenData.engenharia_social);
      setLinkedin(dataDrivenData.linkedin_link);
      setFaturamento(dataDrivenData.faturamento);
    } else {
      emptyValues();
    }
  }, [show]);

  return (
    <div className={`modal ${show && "is-active"}`}>
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{name}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={handleClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Página do linkedin*</label>
            <div className="control">
              <input
                required
                className="input"
                type="text"
                placeholder="https://www.linkedin.com/in/"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Senioridade*</label>

            <div className="select is-fullwidth">
              <select
                value={senioridade}
                onChange={(e) => setSenioridade(e.target.value)}
                required
              >
                <option value={""}>Escolha uma opção</option>
                <option value={"Junior"}>Junior</option>
                <option value={"Pleno"}>Pleno</option>
                <option value={"Senior"}>Senior</option>
              </select>
            </div>
          </div>
          <div className="field">
            <label className="label">Data de início na empresa*</label>
            <div className="control">
              <input
                required
                className="input"
                type="date"
                placeholder="Data"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">
              Tempo médio em outras empresas (meses)*
            </label>
            <div className="control">
              <input
                required
                className="input"
                type="text"
                placeholder="Número de meses"
                value={tempoMedio}
                onChange={(e) => setTempoMedio(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">
              Salário médio para o cargo e senioridade*
            </label>
            <div className="control">
              <input
                required
                className="input"
                type="text"
                placeholder="Número de meses"
                value={salarioSenioridade}
                onChange={(e) => setSalarioSenioridade(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">
              Salário médio do time no mesmo cargo e senioridade*
            </label>
            <div className="control">
              <input
                required
                className="input"
                type="text"
                placeholder="Valor"
                value={salarioTime}
                onChange={(e) => setSalarioTime(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">
              Faturamento*
            </label>
            <div className="control">
              <input
                required
                className="input"
                type="text"
                placeholder="Faturamento"
                value={faturamento}
                onChange={(e) => setFaturamento(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Modalidade de trabalho*</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  required
                  value={modalidade}
                  onChange={(e) => setModalidade(e.target.value)}
                >
                  <option value={""}>Escolha uma opção</option>
                  <option value={"Presencial"}>Presencial</option>
                  <option value={"Remoto"}>Remoto</option>
                  <option value={"Híbrido"}>Híbrido</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">Informações complementares*</label>
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Comentários..."
                rows="2"
                value={social}
                onChange={(e) => setSocial(e.target.value)}
              ></textarea>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={handleSave}>
            Save changes
          </button>
          <button className="button" onClick={handleClose}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default DataDrivenModal;
