import React from 'react'

const ReasonModal = ({show, setShow, reason, setReason}) => {

    const handleClose = () => {
        setReason("")
        setShow(false)
    }
  return (
    // <div className={`modal ${show && "is-active"}`}>
    // <div className="modal-background" onClick={handleClose}></div>

    // <div className="modal-content">
    // <p>{reason}</p> 
    // </div>
    // <button className="modal-close is-large" aria-label="close" onClick={handleClose}></button>
    // </div>

<div className={`modal ${show && "is-active"}`}>
<div className="modal-background" onClick={handleClose}></div>
<div className="modal-card">
  <header className="modal-card-head">
    <p className="modal-card-title">Motivo</p>
    <button className="delete" aria-label="close"  onClick={handleClose}></button>
  </header>
  <section className="modal-card-body">
    {reason}
  </section>
  <footer className="modal-card-foot">

  </footer>
</div>
</div>
  )
}

export default ReasonModal