import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../context/UserContext";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as IconMenu} from "../icons/profectum.svg";
import ImageLogo from "../images/logo.png";
//import { ReactComponent as PeopleIcon } from '../icons/people.svg';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  GearIcon,
  HouseIcon,
  ListStarsIcon,
  LoginFooterIcon,
  LogoutFooterIcon,
  PeopleIcon,
  TicketDetailed
} from "../icons/icons";


const Aside = ({ expandedSideBar, setExpandedSideBar }) => {
  const [token, setToken] = useContext(UserContext);
  const [showDetail, setShowDetail] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [cliente, setCliente] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const getMe = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch("/api/users/me", requestOptions);
    if (response.ok) {
      const data = await response.json();
      if (data.type == "cliente") {
        setCliente(true);
      }
    }
  };

  useEffect(() => {
    token && getMe();
  }, []);

  const handlelLogout = () => {
    setToken(null);
    navigate("/login");
  };

  const handleSideBar = () => {
    setExpandedSideBar(!expandedSideBar);
  };

  return (
    <>
      <header className={`header ${expandedSideBar && "body-pd"}`} id="header">
        <div
          className={`header_toggle ${expandedSideBar && "bx-x"}`}
          onClick={handleSideBar}
          id="header-toggle"
        >
          {expandedSideBar ? (
            <ArrowRightIcon/>
          ) : (
            <ArrowLeftIcon/>
          )}
        </div>
        <div className="header_img"></div>
      </header>
      <div className={`l-navbar ${expandedSideBar && "shows"}`} id="nav-bar">
        <nav className="nav">
          <div>
            <Link to="/">
              <div className="nav_logo">
                <IconMenu width="3rem" fill="white" stroke="green" />
                <span className="nav_logo-name-primary">

                  <img height={"80px"} width={"120px"} src={ImageLogo}/>
                </span>

              </div>
            </Link>

            {token && (
              <>
                <div className="nav_list">
                  <Link to="/">
                    <div
                        className={`nav_link ${!expandedSideBar && "small"} ${
                            location.pathname === "/" && "selected"
                        }`}
                    >
                      <HouseIcon/>
                      <span className="nav_logo-name">Analytics</span>
                    </div>
                  </Link>

                  {/*<Link to="/pulsepg">
                    <div
                        className={`nav_link ${!expandedSideBar && "small"} ${
                            location.pathname === "/pulsepg" && "selected"
                        }`}
                    >
                      <PeopleIcon width="22" height="22"/>
                      <span className="nav_logo-name">Pulse</span>
                    </div>
                      </Link>*/}
                  <Link to="/colaboradores">
                    <div
                        className={`nav_link ${!expandedSideBar && "small"} ${
                            location.pathname === "/colaboradores" && "selected"
                        }`}
                    >
                      <PeopleIcon width="22" height="22"/>
                      <span className="nav_logo-name">Colaboradores</span>
                    </div>
                  </Link>
                  <Link to="#" onClick={() => setShowDetail(!showDetail)}>
                    <div className={`nav_link ${!expandedSideBar && "small"}`}>
                      <TicketDetailed/>
                      <span className="nav_logo-name">
                        Diagnóstico
                        <i
                            className={`fa fa-caret-${
                                showDetail ? "up" : "down"
                            }`}
                            style={{paddingLeft: "5px"}}
                        ></i>
                      </span>
                    </div>
                  </Link>
                  {showDetail && (
                      <>
                        <Link to="/indicadores">
                          <div
                              className={`nav_link ${!expandedSideBar && "small"} ${
                                  location.pathname === "/indicadores" && "selected"
                              } dropdown-container`}
                          >
                            <span className="nav_logo-name">Risco Demissão</span>
                          </div>
                        </Link>
                        <Link to="/performance">
                          <div
                              className={`nav_link ${!expandedSideBar && "small"} ${
                                  location.pathname === "/performance" && "selected"
                              } dropdown-container`}
                          >
                            <span className="nav_logo-name">Performance</span>
                          </div>
                        </Link>
                      </>
                  )}
                  <Link to="#" onClick={() => setShowAction(!showAction)}>
                    <div className={`nav_link ${!expandedSideBar && "small"}`}>
                      <ListStarsIcon/>
                      <span className="nav_logo-name">
                        Ações
                        <i
                            className={`fa fa-caret-${
                                showAction ? "up" : "down"
                            }`}
                            style={{paddingLeft: "5px"}}
                        ></i>
                      </span>
                    </div>
                  </Link>
                  {showAction && (
                      <>
                        {!cliente && (
                            <Link to="/plano-acao">
                              <div
                                  className={`nav_link ${
                                      !expandedSideBar && "small"
                                  } ${
                                      location.pathname === "/plano-acao" && "selected"
                                  } dropdown-container`}
                              >
                                <span className="nav_logo-name">
                              Cadastrar Ação
                            </span>
                              </div>
                            </Link>
                        )}
                        <Link to="/visao-consolidada">
                          <div
                              className={`nav_link ${!expandedSideBar && "small"} ${
                                  location.pathname === "/visao-consolidada" &&
                                  "selected"
                              } dropdown-container`}
                          >
                            <span className="nav_logo-name">GAP Consolidado</span>
                          </div>
                        </Link>
                      </>
                  )}

                  {!cliente && (
                      <>
                        <Link to="#" onClick={() => setShowConfig(!showConfig)}>
                          <div
                              className={`nav_link ${!expandedSideBar && "small"}`}
                          >
                            <GearIcon/>
                            <span className="nav_logo-name">
                            Configurações
                            <i
                                className={`fa fa-caret-${
                                    showConfig ? "up" : "down"
                                }`}
                                style={{paddingLeft: "5px"}}
                            ></i>
                          </span>
                          </div>
                        </Link>
                        {showConfig && (
                            <>
                              <Link to="/relatorio">
                                <div
                                    className={`nav_link ${
                                        !expandedSideBar && "small"
                                    } ${
                                        location.pathname === "/relatorio" && "selected"
                                    } dropdown-container`}
                                >
                                  <span className="nav_logo-name">Relatório</span>
                                </div>
                              </Link>
                              {/*<Link to="/pesquisa">
                                <div
                                    className={`nav_link ${
                                        !expandedSideBar && "small"
                                    } ${
                                        location.pathname === "/pesquisa" && "selected"
                                    } dropdown-container`}
                                >
                                  <span className="nav_logo-name">Pesquisa</span>
                                </div>
                              </Link>*/}
                              <Link to="/subtimes">
                                <div
                                    className={`nav_link ${
                                        !expandedSideBar && "small"
                                    } ${
                                        location.pathname === "/subtimes" && "selected"
                                    } dropdown-container`}
                                >
                                  <span className="nav_logo-name">Subtimes | Skills</span>
                                </div>
                              </Link>
                              {/*<Link to="/upload">
                                <div
                                    className={`nav_link ${
                                        !expandedSideBar && "small"
                                    } ${
                                        location.pathname === "/upload" && "selected"
                                    } dropdown-container`}
                                >
                                  <span className="nav_logo-name">Upload</span>
                                </div>
                              </Link>*/}
                              <Link to="/users">
                                <div
                                    className={`nav_link ${
                                        !expandedSideBar && "small"
                                    } ${
                                        location.pathname === "/users" && "selected"
                                    } dropdown-container`}
                                >
                                  <span className="nav_logo-name">Usuários</span>
                                </div>
                              </Link>
                            </>
                        )}
                      </>
                  )}
                </div>
              </>
            )}
          </div>

          <a className="nav_link" onClick={handlelLogout}>
            {token ? (
              <>
                <LogoutFooterIcon/>
                <span className="nav_name">Logout</span>
              </>
            ) : (
              <>
                <LoginFooterIcon/>
                <span className="nav_name">Login</span>
              </>
            )}
          </a>
        </nav>
      </div>
    </>
  );
};

export default Aside;
