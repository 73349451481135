export const getGroupedIndicadores = async (token,selectedTeams) => {
        const selectedTeamsArray = Array.isArray(selectedTeams)
        ? selectedTeams
        : [selectedTeams];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      //console.log(selectedTeamsArray);
      const response = await fetch(
        `/api/grouped/indicators/${selectedTeamsArray.join(",")}`,
        requestOptions
      );
      const data = await response.json();
      //console.log(data);
      localStorage.setItem("chartCriticality",JSON.stringify(data));
      
};

export const getIndicadores = async (token,selectedTeams) => {
    const selectedTeamsArray = Array.isArray(selectedTeams)
    ? selectedTeams
    : [selectedTeams];

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      `/api/full/indicators/${selectedTeamsArray.join(",")}`,
      requestOptions
    );
    const data = await response.json();
    //console.log("🚀 ~ file: TableCriticidadeFull.jsx:75 ~ getIndicadores ~ data:", data)

    localStorage.setItem("allIndicadores",JSON.stringify(data));
};

export const getEngagement = async (token, selectedTeams) => {
  const selectedTeamsArray = Array.isArray(selectedTeams)
    ? selectedTeams
    : [selectedTeams];
  const requestOptions = {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
      },
  };

  const response = await fetch(
      `/teamguide/get_engagement_team/${selectedTeamsArray.join(",")}`, 
      requestOptions
  );
  const data = await response.json();
  console.log(data);
  localStorage.setItem("engajamentoChart",JSON.stringify(data));
  
};