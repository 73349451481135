import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Line } from "react-chartjs-2";

const LineGraphNotas = ({ NPS, selectedMemberName, collaborator }) => {
  const [token] = useContext(UserContext);
  const [notaMember, setNotaMember] = useState([]);
  const [notaTeam, setNotaTeam] = useState([]);
  const [trimestres, setTrimestres] = useState([]);

  const fetchMember = async () => {
    console.log("🚀 ~ file: LineGraphNotas.jsx:12 ~ fetchMember ~ colaborador:", collaborator)
    
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const response = await fetch(
        `/api/ms/NPS/collaborators/${collaborator}`,
        requestOptions
      );
      const data = await response.json();
      console.log("🚀 ~ file: LineGraphNotas.jsx:28 ~ fetchMember ~ data:", data)

      // Obtendo os últimos 4 elementos do array
      const ultimosQuatroTrimestres = data.slice(-4);

      // Criando arrays separados para armazenar médias e trimestres
      const mediasNotas = ultimosQuatroTrimestres.map((item) => item.score);
      setNotaMember(mediasNotas);

      // Atualizando trimestres se o colaborador tiver menos de 4 trimestres
      setTrimestres(
        ultimosQuatroTrimestres.map(
          (item) => `${item.ano}${item.trimestre}`
        )
      );
    } catch (error) {
      console.error("Erro ao buscar dados do colaborador:", error);
    }
  };

  useEffect(() => {
    if (NPS.length && collaborator) {
      console.log("🚀 ~ file: LineGraphNotas.jsx:11 ~ useEffect ~ NPS:", NPS);

      const nome = selectedMemberName;

      // Obtendo os últimos 4 elementos do array
      const ultimosQuatroTrimestres = NPS.slice(-4);

      // Criando arrays separados para armazenar médias e trimestres
      const mediasNotas = ultimosQuatroTrimestres.map((item) => item.media_nota);
      setNotaTeam(mediasNotas);

      // Atualizando trimestres para a equipe
      setTrimestres(
        ultimosQuatroTrimestres.map(
          (item) => `${item.ano}${item.trimestre}`
        )
      );
      fetchMember();
      // Chame a função para buscar os dados do colaborador
      
    }
  }, [NPS, selectedMemberName, collaborator]);

  return (
    NPS && (
      <Line
        data={{
          labels: trimestres,
          datasets: [
            {
              label: selectedMemberName,
              data: notaMember,
              fill: true,
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
            },
            {
              label: "Time",
              data: notaTeam,
              fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)",
            },
          ],
        }}
        options={{
          scales: {
            y: {
              min: 0,
              max: 10,
              stepSize: 1,
            },
            x: {},
          },
        }}
      />
    )
  );
};

export default LineGraphNotas;