import React, {useEffect, useState} from 'react'
import {Route, Routes, useNavigate} from "react-router-dom";


import Home from "./Home";
//import VisaoConsolidada from './VisaoConsolidada';
import VisaoConsolidada from './consolidado/VisaoConsolidada_v2';

import PlanoAcao from "./PlanoAcao";
import Acoes from "./Acoes";
import PlanoAcao_v2 from './acoes/PlanoAcao_v2';
import Indicadores from "./Indicadores";
import Subtimes from "./Subtimes";
import Users from "./Users";
import DataDriven from "./DataDriven";
import UploadPesquisa from "./UploadPesquisa";

import Login from "./Login";
import ChangePassword from "./ChangePassword";
import SendEmail from "./SendEmail";
import Register from "./Register";
import Performance from "./Performance";
import Pesquisa from './Pesquisa';
import Relatorio from './Relatorios'
import ColaboradorLista from "./colaborador/ColaboradorLista";
import {ColaboradorInfo} from "./colaborador/ColaboradorInfo";
import PulsePage from './pulse/PulsePage';

import './Main.css';

const Main = ({token, expandedSideBar, setExpandedSideBar}) => {
  const [me, setMe] = useState(null);
  const [faker] = useState(false);

    const navigate = useNavigate();
    const getMe = async () => {
        try {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
            };

            const response = await fetch("/api/users/me", requestOptions);

            if (response.ok) {
                const data = await response.json();
                setMe(data);
            } else {
                navigate('/login');
            }
        } catch (error) {
            console.error("Erro ao buscar dados do usuário:", error);
        }
    };
  useEffect(() => {
    token && getMe();
    }, []);

  useEffect(() => {
    getMe();
    }, [token]);

  return (
      <>
            {token ?
                (
                    <div className="columns logged-container">
                        <div className={`column is-${expandedSideBar ? "2" : "1"}`}></div>
                        <div className={`column m-5 is-${expandedSideBar ? "is-10" : "is-8"}`}
                             style={{transitionDuration: "5s"}}>

                            <Routes>
                                <Route path="/" element={<Home me={me} faker={faker}/>}/>
                                <Route path="/login" element={<Login getMe={getMe}/>}/>
                                <Route path='/pulsepg' element={<PulsePage me={me} />}/>
                                <Route path="/visao-consolidada" element={<VisaoConsolidada faker={faker}/>}/>
                                <Route path="/plano-acao" element={<PlanoAcao_v2 me={me} faker={faker}/>}/>
                                <Route path="/indicadores" element={<Indicadores faker={faker}/>}/>
                                <Route path="/acoes" element={<Acoes me={me} faker={faker}/>}/>
                                <Route path="/subtimes" element={<Subtimes faker={faker}/>}/>
                                <Route path="/datadriven" element={<DataDriven me={me} faker={faker}/>}/>
                                <Route path="/upload" element={<UploadPesquisa/>}/>
                                <Route path="/users" element={<Users me={me}/>}/>
                                <Route path="/performance" element={<Performance me={me} faker={faker}/>}/>
                                <Route path="/pesquisa" element={<Pesquisa/>}/>
                                <Route path="/relatorio" element={<Relatorio me={me} faker={faker}/>}/>
                                <Route path="/colaboradores" element={<ColaboradorLista me={me} faker={faker} />} />
                                <Route path="/colaborador/:id" element={<ColaboradorInfo me={me} faker={faker} />}/>
                            </Routes>
                        </div>
                    </div>

                ) : (
                    <Routes>
                        <Route path="/login" element={<Login getMe={getMe}/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/send_email" element={<SendEmail/>}/>
                        <Route path="/change_password" element={<ChangePassword me={me}/>}/>
                    </Routes>
                )
            }
        </>


  )
}

export default Main
