import React, {useState, useEffect} from 'react'

const ModalEditActions = ({
    setIdEditAction, 
    idEditAction, 
    token, 
    setParameter, 
    setReload, 
    reload,
    handleDeleteAction} 
    )=>{
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState('');
    const [description, setDescription] = useState('');
    const [action, setAction] = useState(null);
    const [selectedGAP, setSelectedGAP] = useState([]);
    const [id_gap, setGAP] = useState(0);
    const [deadline, setDeadline] = useState(null);
    const [situation, setSituation] = useState(null);
    const [burden, setBurden] = useState(null);
    const [data_one, setData_one] = useState(null);
    const [maxBurden ,setMaxBurden] = useState(0);
    const [id_collaborator, SetId_collaborator] = useState(null);
    const [idSubtime, setIdSubtime] = useState(0);
    const [nameSubtime, setNameSubtime] = useState(null);
    

    const getAction = async (idEditAction) => {

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const response = await fetch("/api/individual_actions/"+idEditAction, requestOptions);
        const data = await response.json();
        //console.log(data)
        setData(data);
        setShowModal(true)
    };

    const get_peso = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        let d = new Date( data_one );
        if ( !!d. valueOf() ) {
            var year = d. getFullYear();
            var month = d. getMonth()+1;
            // TODO.
            }        
        const response = await fetch("api/individual_actions/"+id_collaborator+"/"+month+"/"+year, requestOptions);
        const data = await response.json();
        if (data.length){
            //console.log(data)
            let sum = 0;
            data.forEach(value => {
                if (value.id !==idEditAction){
                    sum += value.burden;
                }
            });
            let max = sum;
            setMaxBurden(max);
        }
    };

    const getGAP = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const response = await fetch("/api/gaps", requestOptions);
        const data = await response.json();
        setSelectedGAP(data)
    };


    useEffect(() => {
        if(idEditAction){
            getGAP()
            getAction(idEditAction)
        }
    }, [idEditAction])

    useEffect(() => {
        if (data){
            document.getElementById('gap').value=data.gap;
            setDescription(data.description);
            setAction(data.action);
            setGAP(data.gap.id);
            setDeadline(new Date(data.deadline).toISOString().slice(0, 10));
            setBurden(data.burden);
            setSituation(data.situation);
            setData_one(new Date(data.data_one).toISOString().slice(0, 10));
            SetId_collaborator(data.id_collaborator)
            setIdSubtime(data.id_subtime)
            setNameSubtime(data.name_subtime)
        }
    }, [data])

    useEffect(() =>{
        if(!(showModal)){
            setIdEditAction(null);
        }
    }, [showModal])

    useEffect(() =>{
        if(id_collaborator){
            get_peso();
        }
    }, [id_collaborator])

    const handleUpdateAction = async (status) => {
    
        if((maxBurden + burden) <= 100){
        
            let concluido;
            let data_one_iso = new Date(data_one);
            data_one_iso = data_one_iso.toISOString()

            let deadline_iso = new Date(deadline);
            deadline_iso = deadline_iso.toISOString()

            if (data.situation == 'CONCLUIDO'){
                concluido =  new Date();
                concluido = concluido.toISOString()
            }else{
                concluido = new Date(data.date_completion);
                concluido = concluido.toISOString()
            }
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(
                    {
                        description: description,
                        burden: burden,
                        data_one: data_one_iso,
                        action: action,
                        deadline: deadline_iso,
                        situation: situation,
                        id_collaborator: data.id_collaborator,
                        name_collaborator: data.name_collaborator,
                        team: data.team,
                        leader: data.leader,
                        type: data.type,
                        date_completion: concluido,
                        status: status,
                        id_user: data.user.id,
                        id_gap: id_gap,
                        id_subtime: idSubtime,
                        name_subtime: nameSubtime
                    }
                    ),
            };
            let r = await fetch('/api/individual_actions/'+idEditAction, requestOptions);
            //console.log("🚀 ~ file: ModalEditActions.jsx ~ line 160 ~ handleUpdateAction ~ r", r)
            
            if (r.ok === true && r.status === 200) {
                setShowModal(false);
                setParameter({
                    display: true,
                    text: "Ação atualizada com sucesso",
                    color: "notification is-primary"
                })
                if(reload == 1){
                    setReload(2);
                }else{
                    setReload(1);
                }
                window.location.reload();
            }else{
                setShowModal(false);
                setParameter({
                    display: true,
                    text: "Erro: "+r.status+". "+r.statusText,
                    color: "notification is-danger"
                })
            }
        }else{
            setShowModal(false)
            setParameter({
                display: true,
                text: "Peso utrapassa 100%, valor máximo: "+(100-maxBurden),
                color: "notification is-warning"
            })
            alert("Peso utrapassa 100%, valor máximo: "+(100-maxBurden))
        }
    };
    

    return(
        <div>
        <div className={`modal ${showModal && "is-active"}`}>
            <div className="modal-background" onClick={() => {setShowModal(false)}}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Comentarios</p>
                    <button className="delete" aria-label="close" onClick={() => {setShowModal(false)}}></button>
                </header>
                <section className="modal-card-body">
                    <form id="form_action">
                    <div className="columns">
                        <div className="column">
                            <label className="label">Membro</label>
                            <input
                                id = "member_form"
                                className="input" 
                                type="text"
                                value={data.name_collaborator}
                                readOnly
                                />
                            <input
                                className="input" 
                                type="hidden"
                                value={id_collaborator}
                                readOnly
                                />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <label className="label">Data 1:1</label>
                            <div className="field">
                                <input 
                                    className="input" 
                                    type="date" 
                                    value={data_one}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column"> 
                            <label className="label">Registros das causas</label>
                            <textarea 
                                className="textarea" 
                                rows="2"
                                value={description}
                                onChange={(e)=> setDescription(e.target.value)}
                            >
                            </textarea>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-three-quarters">    
                            <label className="label">GAP</label>
                            <div className="select is-fullwidth">
                                <select id="gap" value={id_gap} onChange={(e) =>
                                    setGAP(e.target.value)}>
                                    {
                                    selectedGAP.map((team) => (
                                    <option key={`team_${team.id}`} value={team.id}>{team.name}</option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="column">
                                <label className="label">Peso %</label>
                                <input 
                                    className="input" 
                                    type="number" 
                                    value={burden}
                                    min="1"
                                    max="100"
                                    onChange={(e) => setBurden(e.target.value)}
                                />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <label className="label">Ação</label>
                            <input 
                                className="input" 
                                type="text" 
                                value={action}
                                onChange={(e) => setAction(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <label className="label">Prazo</label>
                            <input 
                                className="input" 
                                type="date" 
                                value={deadline}
                                onChange={(e) => setDeadline(e.target.value)}
                            />
                        </div>
                        <div className="column">
                        <label className="label">Status da ação</label>
                            <div className="select is-fullwidth">
                                <select id="situacao" value={situation} onChange={(e) =>
                                    setSituation(e.target.value)}>
                                    <option value='NAO_INICIADO'>Não iniciado</option>
                                    <option value='ANDAMENTO'>Em andamento</option>
                                    <option value='CONCLUIDO'>Concluído</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </form>
            </section>
            <footer className="modal-card-foot">
                <button className="button is-success" onClick={() => {handleUpdateAction(true)}} >Save</button>
                <button className="button is-danger" onClick={() => {handleDeleteAction(idEditAction)}} >Delete</button>
                <button onClick={() => {setShowModal(false)}} className="button">Cancel</button>
            </footer>
            </div>
        </div>
        </div>
    )
}
export default ModalEditActions
