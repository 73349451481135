import React from 'react'
import ToolTip from './ToolTip'

const Card = ({title, content, edit=false, type="input", useToolTipo=true, text="Descrição do card", id=null}) => {
  return (
    
        <div className="card">
        <header className="card-header">
        <p className="card-header-title">
        {title}
        
        </p>
        {useToolTipo && <ToolTip text={text} id={id ? id : title} />}
       
    </header>
    <div className="card-content colCriticidade">
    <div className="content">
        {
        !edit ? content
        : 
        (
          type === "input" && (
            <input className="input" value={content} type="text" placeholder="Text input" />
          )
        )
        
        }
      
    </div>
  </div>
    </div>
    
    
  )
}

export default Card