import React, {useState, useEffect} from 'react'
import GetTagSkills from './GetTagSkills'


const TableTagSkills = ({token, tags, getTags, setReload, reload}) => {

    return(
        <>
            <div className="card is-fullwidth">
                <header className="card-header">
                    <p className="card-header-title">TAG - Skills</p>
                </header>
                <div className={`card-content`} style={{ height: "470px", overflowY: "scroll"}}>
                    <div className="content">
                        <div className="columns">
                            <div className="column">
                                <GetTagSkills 
                                token={token}
                                tags={tags}
                                value={'tag'}
                                reload={reload}
                                getTags={getTags}
                                setReload={setReload}
                                />
                            </div>
                            <div className="column">
                                <GetTagSkills 
                                token={token}
                                tags={tags}
                                value={'hs'}
                                reload={reload}
                                getTags={getTags}
                                setReload={setReload}
                                />
                            </div>
                            <div className="column">
                                <GetTagSkills 
                                token={token}
                                tags={tags}
                                value={'ss'}
                                reload={reload}
                                getTags={getTags}
                                setReload={setReload}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>           
        </>
    )
}
export default TableTagSkills