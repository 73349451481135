import React, {useContext, useState} from "react";
import { UserContext } from "./context/UserContext";


import Main from "./components/Main";

import Aside from "./components/Aside";


const App = () => {
  
  const [token] = useContext(UserContext);
  const [expandedSideBar, setExpandedSideBar] = useState(true);

    return (
        <>
            {token && <Aside
                expandedSideBar={expandedSideBar}
                setExpandedSideBar={setExpandedSideBar}
            />}
            <Main
                token={token}
                expandedSideBar={expandedSideBar}
                setExpandedSideBar={setExpandedSideBar}
            />
        </>
    );
};

export default App;


