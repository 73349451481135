import React from 'react';

const StackedBarChart = ({ data }) => {
  const total = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', height: '30px' }}>
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: item.color,
              height: '100%',
              width: `${(item.value / total) * 100}%`,
              borderRadius: getBorderRadius(index, data.length),
            }}
          />
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        {data.map((item, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                backgroundColor: item.color,
                width: '12px',
                height: '12px',
                marginRight: '5px',
                borderRadius: '3px',
              }}
            />
            <div>
              <span >{item.legend}</span>
              <div style={{ fontSize: '12px', color: 'darkblue'}}>
                <span><b>{item.value.toFixed(2)}</b></span>
                <span style={{ marginLeft: '5px',  color: 'darkblue'}}><b>({((item.value / total) * 100).toFixed(2)}%)</b></span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const getBorderRadius = (index, length) => {
  if (index === 0) {
    return '5px 0 0 5px';
  } else if (index === length - 1) {
    return '0 5px 5px 0';
  }
  return 0;
};

export default StackedBarChart;
