import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"
import { warning } from "@remix-run/router";

const ModalPerformance = ({
  show, setShow, setShowNPS, collaborator, setCollaborator, performance, setPerformance, getPerformances,
  token, hardSkill, softSkill, skillsCollaborator, selectedTeam, chosenClassNPS, chosenSubTime, nameCollaborator
}) => {

  const [hardSkillColab, setHardSkillColab] = useState([]);
  const [softSkillColab, setSoftSkillColab] = useState([]);
  const [note, setNote] = useState(null);

  const handleUpdatePerformance = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        id_collaborator: collaborator,
        name_collaborator: nameCollaborator,
        strategic: false,
        performance: performance,
        nps_class: chosenClassNPS,
        id_team: selectedTeam[0],
        id_sub_team: chosenSubTime,
        hard: 'hard',
        soft: 'soft',
        status: true,
      }),
    };
    await fetch(`/api/performances/${collaborator}`, requestOptions);
    getPerformances();
    handleCreateSkills();
    handleClose();

  };

  const handleClose = () => {
    setShow(false);
    setCollaborator(null);
  };

  console.log(performance)

  const handleCreateSkills = async () =>{
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        id_collaborator: collaborator,
        id_performance: performance,
        status: true,
        hardskill: hardSkillColab,
        softskill: softSkillColab,
        note: note,
      }),
    };
    await fetch(`/api/skill`, requestOptions);

  };

  useEffect(() => {
    if(skillsCollaborator){
      setHardSkillColab(skillsCollaborator.hardskill)
      setSoftSkillColab(skillsCollaborator.softskill ? skillsCollaborator.softskill : [])
      setNote(skillsCollaborator.note)
    }

  }, [skillsCollaborator]);

  return (
    <div className={`modal ${show && "is-active"}`}>
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Avaliação do colaborador</p>
          <button
            className="delete"
            aria-label="close"
            onClick={handleClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="select is-fullwidth">
              <select
                value={performance}
                onChange={(e) => setPerformance(e.target.value)}
              >
                <option value={0}>Categoria de performance atual</option>
                <option value={1}>Baixa</option>
                <option value={2}>Média</option>
                <option value={3}>Alta</option>
              </select>
            </div>
          </div>
          <div className="field">
          <label className="label">HardSkills</label>
            {hardSkill &&
            <MultiSelect
              responsiveHeight={"250px"}
              messages={{noItemsMessage : "No HardSkill..."}}
              showSelectAll={false}
              items={hardSkill.items}
              selectedItems={hardSkillColab}
              onChange={(e) => setHardSkillColab(e)}
            /> }
          </div>
          <div className="field">
            <label className="label">SoftSkill</label>
            {softSkill &&
            <MultiSelect
              responsiveHeight={"250px"}
              messages={{noItemsMessage : "No SoftSkill..."}}
              showSelectAll={false}
              items={softSkill}
              selectedItems={softSkillColab}
              onChange={(e) => setSoftSkillColab(e)}
            /> }
          </div>
          <div className="field">
            <label className="label">Sugestão de melhorias</label>
            <textarea
              className="textarea"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Descreva aqui"
            ></textarea>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-success"
            onClick={handleUpdatePerformance}
          >
            Salvar
          </button>
          <button className="button" onClick={handleClose}>
            Cancelar
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ModalPerformance;
