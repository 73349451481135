import React, { useContext, useState, useEffect } from "react";
import Times from "./comp_teamguide/Times";
import RequestMember from "./comp_teamguide/RequestMember";
import TableAcoes from "./comp_forms/TableAcoes";
import { UserContext } from "../context/UserContext";
import ModalTimeline from "./comp_forms/modals/ModalTimeline";
import ModalEditActions from "./comp_forms/modals/ModalEditActions";
import ModalAddTimeline from "./comp_forms/modals/ModalAddTimeline";
import Notification from "./Notification";
import InfoAcoes from "./subcomponents/InfoAcoes";

const Acoes = ({me, faker}) => {
  const [token] = useContext(UserContext);
  const [notificationParameters, setParameter] = useState({
    display: false,
    text: "",
    color: "notification is-success",
  });
  const today = new Date();
  const [period, setPeriod] = useState(
    today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2)
  );
  const [selectedTeam, setSeletedTeam] = useState("");
  const [selectedMember, setSeletedMember] = useState("");
  const [seletedSituation, setSeletedSituation] = useState("*");
  const [idRequest, setIdRequest] = useState(null);
  const [idEditAction, setIdEditAction] = useState(null);
  const [idAddTimeline, setAddTimeline] = useState(null);
  const [reload, setReload] = useState(0);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [dadosTableFinaly, setDadosTableFinaly] = useState([]);
  const [membersList, setMembersList] = useState(null);

  return (
    <div>
      <Notification
        notification_parameters={notificationParameters}
        setParameter={setParameter}
      />

      <ModalEditActions
        setIdEditAction={setIdEditAction}
        idEditAction={idEditAction}
        token={token}
        setParameter={setParameter}
        setReload={setReload}
        reload={reload}
      />
      <ModalTimeline
        setIdRequest={setIdRequest}
        idRequest={idRequest}
        token={token}
        setParameter={setParameter}
      />
      <ModalAddTimeline
        me={me}
        setAddTimeline={setIdRequest}
        idAddTimeline={idAddTimeline}
        token={token}
        setParameter={setParameter}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}

      />
      <h1 className="subtitle">Plano de Ação</h1>
      <div className="columns">
        <div className="column is-one-quarter">
          <Times
            selectedTeam={selectedTeam}
            setSeletedTeam={setSeletedTeam}
            faker={faker}
          />
        </div>
        <div className="column is-one-quarter">
          <div className="select is-fullwidth">
            <select
              value={selectedMember}
              onChange={(e) => setSeletedMember(e.target.value)}
            >
              <option key={-1} value="">
                Membros
              </option>
              <RequestMember
                selectedTeam={selectedTeam}
                members={membersList}
                setMembers={setMembersList}
              />
            </select>
          </div>
        </div>
        <div className="column is-one-fifth">
          <input
            className="input"
            type="month"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
          />
        </div>
        <div className="column is-one-fifth">
          <div className="select is-fullwidth">
            <select
              name="situation"
              id="situation"
              value={seletedSituation}
              onChange={(e) => setSeletedSituation(e.target.value)}
            >
              <option value="*">Status da Ação</option>
              <option value="NAO_INICIADO">Não iniciado</option>
              <option value="ANDAMENTO">Em andamento</option>
              <option value="CONCLUIDO">Concluído</option>
            </select>
          </div>
        </div>
      </div>
      <InfoAcoes dadosTableFinaly={dadosTableFinaly} />
      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-header">
              <p className="card-header-title">Indicadores</p>
            </div>
            <div className="card-content">
              <TableAcoes
                selectedTeam={selectedTeam}
                selectedMember={selectedMember}
                period={period}
                seletedSituation={seletedSituation}
                token={token}
                setIdRequest={setIdRequest}
                setIdEditAction={setIdEditAction}
                setAddTimeline={setAddTimeline}
                reload={reload}
                showModalAdd={showModalAdd}
                setShowModalAdd={setShowModalAdd}
                dadosTableFinaly={dadosTableFinaly}
                setDadosTableFinaly={setDadosTableFinaly}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acoes;
