import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import loadingGif from "../../icons/loading.gif";
import { Line } from "react-chartjs-2";

const GraficoTurnover = ({
  data,
  customTitle = null,
  setSeletedData,
  selectedData,
  nameTeam,
  selectedTeams
}) => {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const color = ["rgba(106,159,197, 0.5)", "rgba(247,186,68, 0.5)", "rgba(239,105,134, 0.5)", "rgba(238,105,134,0.5)"];
  const colorBorder = ["rgba(106,159,197, 0.3)", "rgba(247,186,68, 0.3)", "rgba(239,105,134, 0.3)", "rgba(238,105,134,0.3)"];
  const options_date = {
    year: "numeric",
    month: "2-digit",
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const tempLabels = Object.keys(data[Object.keys(data)[0]]);
      const tempDatasets = Object.keys(data).map((id, index) => ({
        label: `${id == '792335360' ? 'BU Saúde' : 'BU Indústria'}`,
        data: Object.values(data[id]).map(value => Number(value.toFixed(2))),
        backgroundColor: color[index % color.length],
        borderColor: colorBorder[index % colorBorder.length],
        fill: {
          target: 'start',
          above: colorBorder[index % colorBorder.length],
          below: color[index % color.length],
        },
      }));

      setLabels(tempLabels);
      setDatasets(tempDatasets);
    }
  }, [data]);

  return (
    <>
      <ReactTooltip id="turnover" effect="solid">
        <span>
          Essa proporção é determinada com base na comparação entre o número de pessoas que deixaram a empresa e a quantidade de novos funcionários contratados durante um período específico.
        </span>
      </ReactTooltip>
      <div className="card">
        <div className="card-header">
          <p className="card-header-title">
            Turnover
            <div className="ml-auto">
              <div className="select is-small">
                <select
                  value={selectedData}
                  onChange={(e) => setSeletedData(Number(e.target.value))}
                >
                  <option value="3">Ultimos 3 meses</option>
                  <option value="6">Ultimos 6 meses </option>
                </select>
              </div>
            </div>
          </p>
          <a
            className="card-header-icon"
            data-tip
            data-for="turnover"
            data-event="click focus"
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-question-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
              </svg>
            </span>
          </a>
        </div>
        <div className="card-content" style={{ height: "280px" }}>
          {data && nameTeam.length ? (
            <Line
              data={{
                labels: labels.map((label) =>
                  new Date(label).toLocaleDateString(
                    undefined,
                    options_date
                  )
                ),
                datasets: datasets,
              }}
              width={"240px"}
              height={"240px"}
              options={{ maintainAspectRatio: false }}
            />
          ) : (
            <img src={loadingGif} alt="loading..." />
          )}
        </div>
      </div>
    </>
  );
};

export default GraficoTurnover;