import React, {useEffect} from "react";
import MemberImage from "../comp_teamguide/MemberImage";
import {Link, useNavigate} from "react-router-dom";
import Pagination from "../subcomponents/Pagination";
import loadingGif from "../../icons/loading.gif";
import {ArrowDownShortWide, ArrowUpWideShort} from "../../icons/icons";

const TableColaboradores = ({
                                colaboradores,
                                fetchColaboradores,
                                lastPage,
                                page,
                                setPage,
                                selectedTeams,
                                searchValue,
                                handleSearchChange,
                                orderField,
                                orderFieldAsc,
                                setOrderField,
                                setOrderFieldAsc,
                                loading = false,
                                setLoading
                            }) => {

    useEffect(() => {
        selectedTeams && fetchColaboradores()
    }, [page]);

    useEffect(() => {
        setPage(1);
        selectedTeams && fetchColaboradores()
    }, [orderFieldAsc, orderField]);
    const navigate = useNavigate();

    const cor = {
        "BU Indústria": "#ddf3fd",
        "BU Saúde": "#fcecf0",
        "Default": "#dddddd"
      };

    const handleClick = (colaborador) => {
        navigate(`/colaborador/${colaborador.id}`, {state: {colaborador}});
    };

    console.log(colaboradores)

    return (
        <div>

            <div className="card">
                {/* <div className="card-header">
                    <p className="card-header-title"/>
                    <div className="control m-3">
                        <input
                            className="input"
                            type="text"
                            placeholder="Pesquisa..."
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div> */}

                <div className="card-content">

                    <table className="table is-fullwidth is-hoverable has-text-centered">
                        <thead>
                        <tr>
                            <th className="has-text-centered">Colaborador
                                {(orderField === 'name') ?
                                    <a className={'ml-1'} onClick={() => setOrderFieldAsc(!orderFieldAsc)}>
                                        {orderFieldAsc ? <ArrowDownShortWide/> : <ArrowUpWideShort/>}
                                    </a> :
                                    <a className={'ml-1'} onClick={() => setOrderField('name')}>
                                        <i className="fa-solid fa-sort"></i>
                                    </a>
                                }


                            </th>

                            <th className="has-text-centered">Cargo
                                {(orderField === 'position') ?
                                    <a className={'ml-1'} onClick={() => setOrderFieldAsc(!orderFieldAsc)}>
                                        {orderFieldAsc ? <ArrowDownShortWide/> : <ArrowUpWideShort/>}
                                    </a> :
                                    <a className={'ml-1'} onClick={() => setOrderField('position')}>
                                        <i className="fa-solid fa-sort"></i>
                                    </a>
                                }
                            </th>
                            <th className="has-text-centered">Senioridade
                                {(orderField === 'seniority') ?
                                    <a className={'ml-1'} onClick={() => setOrderFieldAsc(!orderFieldAsc)}>
                                        {orderFieldAsc ? <ArrowDownShortWide/> : <ArrowUpWideShort/>}
                                    </a> :
                                    <a className={'ml-1'} onClick={() => setOrderField('seniority')}>
                                        <i className="fa-solid fa-sort"></i>
                                    </a>
                                }
                            </th>

                            <th className="has-text-centered">Equipe
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        {colaboradores && !loading ?
                            colaboradores.map((colaborador, i) => (
                                <tr key={colaborador.id}>
                                    <td>
                                        <div className="columns">
                                            <div className="column is-3">
                                                <MemberImage
                                                    memberId={colaborador.id}
                                                    colaboradorName={colaborador.name}
                                                    width={40}
                                                    height={40}
                                                />
                                            </div>
                                            <div className="column" style={{textAlign: "left"}}>
                                                <Link to={`/colaborador/${colaborador.id}`}>
                                                    {colaborador.name}
                                                </Link>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{colaborador.position}</td>
                                    <td>{colaborador.seniority ? colaborador.seniority : 'Não Informado na API'}</td>
                                    <td>
                                        <span
                                        style={{ backgroundColor: colaborador.team?.name ? cor[colaborador.team?.name] : cor["Default"] }}
                                        className="tag"
                                        >
                                        {colaborador.team?.name || "Sem Informações"}
                                        </span>
                                    </td>
                                </tr>
                            )) : <img src={loadingGif} width="80" alt="loading..."/>}
                        </tbody>
                    </table>
                    <Pagination page={page}  setPage={setPage} lastPage={lastPage} setLoading={setLoading}/>

                </div>


            </div>
        </div>
    );
};

export default TableColaboradores;
