import React, { useState, useEffect ,useContext} from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import loadingGif from "../../icons/loading.gif";
import { UserContext } from '../../context/UserContext';



const EngajamentPesquisaGeral = ({ selectedTeams, period }) => {
  const [token] = useContext(UserContext);
  const [labels, setLabels] = useState(null);
  const [values, setValues] = useState(null);
  const [year, month] = period.split('-').map(Number);
  
    const getGroupedIndicadores = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const date = new Date();
        const response = await fetch(
            `api/ms/time/engagement/${selectedTeams}/${month}/${year}`, 
            requestOptions
        );
        const data = await response.json();
        //console.log('******Engagement:',data);
        //const data = JSON.parse(localStorage.getItem("engajamentoChart"));
        
        const tempArr = [];
        
        //console.log(data.engajamento_geral);
        setValues(data.engajamento_geral.replace('%', ''));
    };
    

  useEffect(() => {
    setValues(null);
    if (selectedTeams.length){
      getGroupedIndicadores();
    }
  }, [selectedTeams]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: [values][0],
        backgroundColor: [
          "rgba(92,203,139,255)",
        ],
        borderColor: [
          "rgba(206,206,206, 0.6)",
        ],
        borderWidth: 0,
        weight: 80,
        borderAlign: "inner",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        borderWidth: 10.5,
        labels: {
          boxWidth: 10,
          color: "blue",
          font: {
            weight: "bold",
          },
        },
        padding: {
          top: 50, // Espaço superior
          bottom: 50, // Espaço inferior
        },
      },
      datalabels: {
        color: "white",
        font: {
          size: 12,
        },
        display: false,
        anchor: "right",
        align: "inner",
      },
    },
  };
  


  return (
    <div className="column is-two-fifths">
      <br/>
      {values ? (
        <div
          style={{
            position: "relative",
            height:"33vh",
            width:"30vw"
          }}
        >
          <Doughnut
            width={700} // Aumente o valor da largura
            height={800} // Aumente o valor da altura
            data={data}
            options={options}
            plugins={[ChartDataLabels]}
          />
          <div
            style={{
              position: "absolute",
              top: "65%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              height: "10em",
            }}
          >
            <span style={{ fontSize: "35px", fontWeight: "bold" }}>
              {data.datasets[0].data}
            </span>
            <p> <span style={{ fontSize: "12px" }}>%</span></p>
          </div>
        </div>
      ) : (
        <img src={loadingGif} width="80" alt="loading..." />
      )}
    </div>
  );
};

export default EngajamentPesquisaGeral;
