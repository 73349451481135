import React from 'react';

const Separator = ({ horizontal }) => {
  const separatorStyle = {
    backgroundColor: '#ccc',
    width: horizontal ? '100%' : '1px',
    height: horizontal ? '1px' : '100%',
    margin: horizontal ? '10px 0' : '0 10px'
  };

  return <div style={separatorStyle}></div>;
};

export default Separator;
