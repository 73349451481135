import React from 'react'

const Notification = ({notification_parameters, setParameter}) => {
  
    document.addEventListener('DOMContentLoaded', () => {
      (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
        const $notification = $delete.parentNode;
    
        $delete.addEventListener('click', () => {
          setParameter({display: false});
          $notification.parentNode.removeChild($notification);
          window.scrollTo(0, 0);
        });
      });
    });

    const handleClose = () => {
      setParameter({display: false});
    }
    
return(
    <div>
    <div className={notification_parameters.color} style={{display: notification_parameters.display ? 'block' : 'none'}}>
        <button className="delete" onClick={handleClose}></button>
        {notification_parameters.text}
      </div>
    </div>
)
} 
export default Notification