import React from 'react';

export const InfoComplementares = ({colaborador={}}) => {

    return (
        <div className="field">
            <label className="label text-darkblue">Informações Complementares</label>
            <div className="control is-flex is-flex-direction-column is-justify-content-flex-start">
                <textarea className="textarea is-static is-size-7" rows="7
                " disabled>{`Email: ${colaborador.corporate_email ?? colaborador.personal_email}`}</textarea>
            </div>
        </div>
    );
}
