import React, { useState, useContext, useEffect } from "react";
import Times from "./comp_teamguide/Times";
import RequestMember from "./comp_teamguide/RequestMember";
import MemberImage from "./comp_teamguide/MemberImage";
import Card from "./subcomponents/Card";
import DataDrivenModal from "./comp_forms/DataDrivenModal";
import { UserContext } from "../context/UserContext";
import PieGraphPesquisa from "./subcomponents/PieGraphPesquisa";
import RequestEngagementMember from "./comp_teamguide/RequestEngagementMember";
import loadingGif from "../icons/loading.gif";
import ToolTip from "./subcomponents/ToolTip";

let options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
};

let options_2 = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const DataDriven = ({ me, faker }) => {
  const [selectedTeam, setSeletedTeam] = useState("");

  const [selectedMember, setSelectedMember] = useState("");
  const [selectedMemberName, setSelectedMemberName] = useState(null);
  const [selectedMemberFullName, setSelectedMemberFullName] = useState(null);

  const [position, setPosition] = useState(null);
  const [salary, setSalary] = useState(null);
  const [promotion, setPromotion] = useState(null);
  const [admissao, setAdmissao] = useState(new Date());
  const [numeroPromo, setNumeroPromo] = useState(null);

  const [membersTeam, setMembersTeam] = useState(null);
  const [empresa, setEmpresa] = useState(null);

  const [dataDrivenData, setDataDrivenData] = useState(null);
  const [pesquisa, setPesquisa] = useState(null);

  const [edit, setEdit] = useState(false);

  const [members, setMembers] = useState(null);
  const [membersList, setMembersList] = useState(null);
  const [token] = useContext(UserContext);

  const [NPS, setNPS] = useState("");

  const [loading, setLoading] = useState(false);

  const [dataUltimaPromo, SetDataUltimaPromo] = useState(null);

  function monthDiff(d1) {
    const d2 = new Date();
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  const decodeTime = (months) => {
    if (months < 12) {
      return `${months} ${months <= 1 ? "mês" : "meses"}`;
    } else if (months >= 12 && months % 12 === 0) {
      return `${months / 12} ${months == 12 ? "ano" : "anos"}`;
    } else {
      const years = Math.floor(months / 12);
      const restMonths = months - years * 12;
      return `${years} ${years == 1 ? "ano" : "anos"} e ${restMonths} ${
        restMonths <= 1 ? "mês" : "meses"
      }`;
    }
  };
  const totalTime = (d1) => {
    const months = monthDiff(d1);
    return decodeTime(months);
  };

  const getMembers = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    setMembers(null);
    // get id from token
    const id = await requestOptions.headers.Authorization.split(" ")[1];
    const response = await fetch(
      "api/get_collaborator/" + id,
      requestOptions
    );
    const data = await response.json();
    setMembers(data);
  };

  const handleModal = () => {
    setEdit(true);
  };

  const getMembersTeam = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      `/api/ms/time/collaborators/${selectedTeam}`,
      requestOptions
    );
    const data = await response.json();
    setMembersTeam(data);
  };
  const getDataDrivenData = async () => {
    setPosition(null);
    SetDataUltimaPromo(null);
    setSalary(null);
    setPromotion(null);
    setNumeroPromo(null);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    setDataDrivenData(null);
    setLoading(true);
    const response = await fetch(
      `/api/datadrivens/team/${selectedMember}`,
      requestOptions
    );
    const data = await response.json();

    setDataDrivenData(data);
  };

  const findAverage = (arr, key) => {
    const { length } = arr;
    return arr.reduce((acc, val) => {
      return acc + val[key] / length;
    }, 0);
  };

  const getPesquisas = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`/api/pesquisas`, requestOptions);
    const data = await response.json();

    setPesquisa({
      dinheiro: findAverage(data, "dinheiro"),
      desenvolvimento: findAverage(data, "desenvolvimento"),
      clima: findAverage(data, "clima"),
      id_collaborator: null,
    });
  };

  const getPesquisa = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      `/api/pesquisas/team/${selectedMember}`,
      requestOptions
    );
    const data = await response.json();

    if (data.id !== undefined) {
      setPesquisa(data);
    } else {
      getPesquisas();
    }
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    setSelectedMember(null);
    if (selectedTeam) {
      getMembersTeam();
    }
  }, [selectedTeam]);

  useEffect(() => {
    dataDrivenData && setLoading(() => false);
  }, [dataDrivenData]);

  useEffect(() => {
    if (members && selectedMember && !edit) {
      getPesquisa();
      get_nps();
      if (
        membersTeam &&
        membersTeam.filter((item) => item.id === parseInt(selectedMember))
          .length === 1
      ) {
        let teams = membersTeam.filter(
          (item) => item.id === parseInt(selectedMember)
        )[0].teams;
        if (teams.length <= 1) {
          setEmpresa("Sem subtime associado!");
        } else {
          setEmpresa(teams[1]);
        }
      }
      getDataDrivenData();
      if (members.filter((item) => item.id == selectedMember).length == 1) {
        let nome = members.filter((item) => item.id == selectedMember)[0].name;
        setSelectedMemberFullName(nome);
        nome = nome.split(" ");
        if (nome[1].length > 3) {
          nome = nome[0] + " " + nome[1];
        } else {
          nome = nome[0] + " " + nome[1] + " " + nome[2];
        }
        setSelectedMemberName(nome);

        setPosition(
          members.filter((item) => item.id == selectedMember)[0].position.name
        );

        setNumeroPromo(
          members.filter((item) => item.id == selectedMember)[0].salaryHistory
            .length - 1
        );
        setAdmissao(
          new Date(
            members.filter((item) => item.id == selectedMember)[0]
              .admissionDate + "T00:00:00"
          )
        );
        if (
          members.filter((item) => item.id == selectedMember)[0].salaryHistory
            .length > 0
        ) {
          setSalary(
            `R$ ${
              members.filter((item) => item.id == selectedMember)[0]
                .salaryHistory[
                members.filter((item) => item.id == selectedMember)[0]
                  .salaryHistory.length - 1
              ].value / 100
            }`
          );
          if (
            members.filter((item) => item.id == selectedMember)[0].salaryHistory
              .length > 1
          ) {
            setPromotion(
              (members.filter((item) => item.id == selectedMember)[0]
                .salaryHistory[
                members.filter((item) => item.id == selectedMember)[0]
                  .salaryHistory.length - 1
              ].value -
                members.filter((item) => item.id == selectedMember)[0]
                  .salaryHistory[
                  members.filter((item) => item.id == selectedMember)[0]
                    .salaryHistory.length - 2
                ].value) /
                members.filter((item) => item.id == selectedMember)[0]
                  .salaryHistory[
                  members.filter((item) => item.id == selectedMember)[0]
                    .salaryHistory.length - 2
                ].value
            );
            SetDataUltimaPromo(
              members.filter((item) => item.id == selectedMember)[0]
                .salaryHistory[
                members.filter((item) => item.id == selectedMember)[0]
                  .salaryHistory.length - 1
              ].initDate
            );
          } else {
            setPromotion(0);
          }
        }
      }
    }
  }, [members, selectedMember, membersTeam, edit]);
  const get_nps = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/ms/NPS/teams/mean/" + selectedTeam, requestOptions);
    const data = await response.json();
    setNPS(data);
  };

  return (
    <div>
      <DataDrivenModal
        show={edit}
        setShow={setEdit}
        id={selectedMember}
        name={selectedMemberName}
        dataDrivenData={dataDrivenData}
        setDataDrivenData={setDataDrivenData}
        selectedMember={selectedMember}
      />
      <h1 className="subtitle">Info Colaborador</h1>
      <div className="columns">
        <div className="column">
          <Times
            selectedTeam={selectedTeam}
            setSeletedTeam={setSeletedTeam}
            faker={faker}
          />
        </div>
        <div className="column">
          <div className={`select is-fullwidth ${!members && "is-loading"}`}>
            <select
              value={selectedMember}
              disabled={!members}
              onChange={(e) => setSelectedMember(e.target.value)}
            >
              <option key={-1} value="">
                {members ? "Membros" : "Buscando membros ..."}
              </option>
              <RequestMember
                selectedTeam={selectedTeam}
                members={membersList}
                setMembers={setMembersList}
              />
            </select>
          </div>
        </div>
      </div>
      {selectedMember &&
        dataDrivenData &&
        pesquisa &&
        (!loading ? (
          <>
            {dataDrivenData ? (
              dataDrivenData.updated_at !== undefined ? (
                `Última atualização em ${new Date(
                  dataDrivenData.updated_at
                ).toLocaleDateString("pt-BR", options)}`
              ) : (
                <span style={{ color: "red" }}>Sem registros salvos!</span>
              )
            ) : (
              <img src={loadingGif} alt="loading..." />
            )}
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">Detalhamento</p>
                {me && me.type != "cliente" && (
                  <button
                    className="card-header-icon"
                    aria-label="more options"
                    onClick={handleModal}
                  >
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fillRule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                    </span>
                  </button>
                )}
              </header>
              <div className="card-content">
                <div className="columns">
                  <div className="column" style={{ position: "relative" }}>
                    {selectedMemberName && (
                      <>
                        {dataDrivenData.linkedin_link ? (
                          <a
                            href={dataDrivenData.linkedin_link}
                            target="_blank"
                          >
                            <span
                              className="tag is-primary is-medium is-fullwidth"
                              style={{
                                position: "absolute",
                                top: "80%",
                                left: "0%",
                                backgroundColor: "#0e76a8",
                              }}
                            >
                              {selectedMemberName}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                                style={{ marginLeft: "10px" }}
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </span>
                          </a>
                        ) : (
                          <span
                            className="tag is-primary is-medium is-fullwidth"
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "0%",
                            }}
                          >
                            {selectedMemberName}
                          </span>
                        )}
                      </>
                    )}
                    <MemberImage memberId={selectedMember}/>
                  </div>
                  <div className="column">
                    <Card title={"Empresa de atuação"} content={empresa} useToolTipo={false}/>
                  </div>
                  <div className="column">
                    <Card title={"Cargo"} content={position} useToolTipo={false}/>
                  </div>
                  <div className="column">
                    <Card
                      title={"Senioridade"}
                      content={
                        dataDrivenData.senioridade !== undefined ? (
                          dataDrivenData.senioridade
                        ) : (
                          <span style={{ color: "red" }}>Sem registro!</span>
                        )
                      }
                      useToolTipo={false}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <Card
                      title={"Tempo de Profectum"}
                      content={totalTime(admissao)}
                      text={
                        "Tempo de trabalho na Profectum desde o seu primeiro dia."
                      }
                    />
                  </div>
                  <div className="column">
                    <Card
                      title={"Tempo de empresa"}
                      content={
                        dataDrivenData.data_empresa !== undefined ? (
                          totalTime(new Date(dataDrivenData.data_empresa))
                        ) : (
                          <span style={{ color: "red" }}>Sem registro!</span>
                        )
                      }
                      text={
                        "Tempo de trabalho na empresa cliente, o qual pode ser maior ou igual ao tempo de Profectum. É maior apenas em situações de transferência."
                      }
                    />
                  </div>
                  <div className="column">
                    <Card
                      title={"Tempo médio em outras empresas"}
                      content={
                        dataDrivenData.tempo_medio_empresas_meses !==
                        undefined ? (
                          decodeTime(dataDrivenData.tempo_medio_empresas_meses)
                        ) : (
                          <span style={{ color: "red" }}>Sem registro!</span>
                        )
                      }
                      text={
                        "Tempo médio de permanência nas empresas passadas a partir de dados do LinkedIn."
                      }
                    />
                  </div>
                </div>
                {me && me.type != "cliente" && (
                  <div className="columns">
                    <div className="column">
                      <Card title={"Salário atual"} content={salary} />
                    </div>
                    <div className="column">
                      <Card
                        title={"Salário médio do mercado"}
                        content={
                          dataDrivenData.salario_medio_cargo_senioridade !==
                          undefined ? (
                            `R$ ${dataDrivenData.salario_medio_cargo_senioridade}`
                          ) : (
                            <span style={{ color: "red" }}>Sem registro!</span>
                          )
                        }
                        text={
                          "Média salarial do mercado para o mesmo cargo, senioridade e estado."
                        }
                      />
                    </div>
                    <div className="column">
                      <Card
                        title={"Salário médio do time"}
                        content={
                          dataDrivenData.salario_medio_cargo_time !==
                          undefined ? (
                            `R$ ${dataDrivenData.salario_medio_cargo_time}`
                          ) : (
                            <span style={{ color: "red" }}>Sem registro!</span>
                          )
                        }
                        text={
                          "Média salarial do time Profectum no mesmo cliente para o mesmo cargo e senioridade."
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="columns">
                  <div className="column">
                    <Card
                      title={"Nº de promoções"}
                      content={numeroPromo}
                      text={
                        "Número de promoções que o colaborador teve desde o seu primeiro dia de Profectum."
                      }
                    />
                  </div>
                  <div className="column">
                    <Card
                      title={"% de aumento na promoção"}
                      content={`${(promotion * 100).toFixed(2)} %`}
                      text={"O valor percentual de aumento na última promoção."}
                    />
                  </div>
                  <div className="column">
                    <Card
                      title={"Data Ultima Promoção"}
                      content={
                        dataUltimaPromo !== null ? (
                          new Date(dataUltimaPromo).toLocaleDateString(
                            "pt-BR",
                            options_2
                          )
                        ) : (
                          <span style={{ color: "red" }}>Sem registro!</span>
                        )
                      }
                      useToolTipo={false}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <Card
                      title={"Faturamento"}
                      content={
                        dataDrivenData.faturamento !== undefined ? (
                          `R$ ${dataDrivenData.faturamento}`
                        ) : (
                          <span style={{ color: "red" }}>Sem registro!</span>
                        )
                      }
                      text={
                        "Valor negociado e contratado entre Profectum e cliente."
                      }
                    />
                  </div>
                  <div className="column">
                    <Card
                      title={"Modalidade de trabalho"}
                      content={
                        dataDrivenData.modalidade_trabalho !== undefined ? (
                          dataDrivenData.modalidade_trabalho
                        ) : (
                          <span style={{ color: "red" }}>Sem registro!</span>
                        )
                      }
                      useToolTipo={false}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <div className="card">
                      <header className="card-header">
                        <p className="card-header-title">
                          {`Pesquisa ${
                            pesquisa.id_collaborator ? "(Real)" : "(Média)"
                          }`}
                        </p>
                        <ToolTip
                          text={
                            "Pesquisa feita com o colaborador a cada 6 meses para avaliar o peso que ele dá para desenvolvimento, dinheiro e clima em um total de 100%. Quando ele responde a pesquisa, o resultado é (Real) e quando não, o resultado é a (Média) do seu time."
                          }
                          id={"Pesquisa"}
                        />
                      </header>
                      <div className="card-content">
                        <div className="content" style={{ height: "230px" }}>
                          <PieGraphPesquisa pesquisa={pesquisa} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <RequestEngagementMember
                      selectedMember={selectedMember}
                      selectedMemberName={selectedMemberName}
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <Card
                      title={"Informações complementares"}
                      content={
                        dataDrivenData.engenharia_social !== undefined ? (
                          <textarea
                            className="textarea"
                            disabled={true}
                            value={dataDrivenData.engenharia_social}
                          ></textarea>
                        ) : (
                          <span style={{ color: "red" }}>Sem registro!</span>
                        )
                      }
                      text={"Dados e informações relevantes sobre o colaborador que possa ajudar a entender o seu contexto de vida."}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <img src={loadingGif} alt="loading..." />
        ))}
    </div>
  );
};

export default DataDriven;
