import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const GraficoGAP = ({ data, nameTeam }) => {
  const [labels, setLabels] = useState(null);
  const [values, setValues] = useState(null);
  const [totalCollaborators, setTotalCollaborators] = useState(0);
  useEffect(() => {
    if (data) {
      setLabels(data.map((item) => `${item.gap} (${Math.round(item.percentual)}%)`));
      setValues(data.map((item) => item.percentual));
      setTotalCollaborators (data.reduce((acc, item) => acc + item.num_collaborators, 0));
    }
  }, [data]);

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
        borderWidth: 0.5,
        labels: {
          boxWidth: 10,
          color: "blue",
          font: {
            weight: "bold",
            size: 10
          },
        },
      },
      datalabels: {
        color: "gray",
        font: {
          size: 10,
        },
        anchor: "right",
        align: "inner",
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          const percentage = ((value / dataset._meta[0].total) * 100).toFixed(
            2
          );
          return `${data.labels[tooltipItem.index]}: ${value} (${percentage}%)`;
        },
      },
    },
  };

  return (
    <>
      <ReactTooltip id="info" effect="solid">
        <span>Proporção</span>
      </ReactTooltip>
      <div className="column">
        <div className="button is-info is-light has-text-centered">{nameTeam}</div>
        <br />
        <div
          style={{
            position: "relative",
            marginTop: "20px",
          }}
        >
          {data && (
            <Doughnut
              width={300}
              height={300}
              data={{
                labels: labels,
                datasets: [
                  {
                    label: "# of Votes",
                    data: values,
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.5)",
                      "rgba(54, 162, 235, 0.5)",
                      "rgba(255, 206, 86, 0.5)",
                      "rgba(75, 192, 192, 0.5)",
                      "rgba(153, 102, 255, 0.5)",
                      "rgba(255, 159, 64, 0.5)",
                    ],
                    borderColor: [
                      "rgba(255, 99, 132, 1)",
                      "rgba(54, 162, 235, 1)",
                      "rgba(255, 206, 86, 1)",
                      "rgba(75, 192, 192, 1)",
                      "rgba(153, 102, 255, 1)",
                      "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={options}
            />
          )}
          {values && (
            <div
              style={{
                position: "absolute",
                top: "53%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <span style={{ fontSize: "40px", fontWeight: "bold" }}>
              {totalCollaborators}
              </span>
              <p>
                {" "}
                <span style={{ fontSize: "12px" }}>Membros</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GraficoGAP;
