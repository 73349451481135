import React, {useState, useEffect, useContext} from 'react'
import ModalNewAction from "../acoes/ModalNewAction";
import {Link} from "react-router-dom";
import ModalEditActions from "./../acoes/ModalEditActions";
import ModalTimeline from "./modals/ModalTimeline";
import ModalAddTimeline from "./modals/ModalAddTimeline";


const TabsTableForm = ({
                           token,
                           colaborador = false,
                           selectedTeam,
                           selectedMember,
                           setIdEditAction,
                           reload,
                           me,
                           idEditAction,
                           setParameter,
                           buttonActon = true
                       }) => {
    const [dadosTable, setDadosTable] = useState([])
    const [auxData, setAuxData] = useState({})
    const collumns = [
        'Data 1:1',
        'GAP',
        'Peso %',
        'Situação',
        'Edit',
    ];

    const getAcoesTable = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const response = await fetch("/api/individual_actions_collaborator/" + selectedMember, requestOptions);
        const data = await response.json();
        setDadosTable(data);
    };

    useEffect(() => {
        if (selectedTeam && selectedMember) {
            getAcoesTable()
        }
    }, [selectedTeam, selectedMember, reload])

    useEffect(() => {
        if (dadosTable) {
            const formatDate = {}
            let options = {year: 'numeric', month: '2-digit', day: '2-digit'};
            dadosTable.forEach(function (item) {
                let data = item.data_one;
                data = new Date(data);
                data = data.toLocaleDateString("pt-BR", options);
                formatDate[item.id] = data;
            })
            setAuxData(formatDate)
        }
    }, [dadosTable])

    const RequestActionEdit = (id) => {
        setIdEditAction(id)
    }

    const SITUACAO = {
        'NAO_INICIADO': 'Não iniciado',
        'ANDAMENTO': 'Em andamento',
        'CONCLUIDO': 'Concluído'
    };

    const [showModalAcao, setModalhowAcao] = useState(false);
    const RequestAddAcao = () => {
        setModalhowAcao(true);
    };

    const [idRequest, setIdRequest] = useState(null);
    const RequestTimeLine = (id) => {
        setIdRequest(id);
    };


    const [idAddTimeline, setAddTimeline] = useState(null);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const RequestAddTimeLine = (id) => {
        setShowModalAdd(true);
        setAddTimeline(id);
    };

    const [flag, setFlag] = useState(true);


    const handleDeleteAndSetId = (id) => {
        setFlag(false);
        handleDeleteAction(id);
    };

    const handleDeleteAction = async (idAction) => {
        const confirmDelete = window.confirm("Deseja realmente remover essa ação?");
        if (confirmDelete) {
            const requestOptions = {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
            };
            const r = await fetch('/api/individual_actions/' + idAction, requestOptions);
            window.location.reload();
        }
    };


    return (
        <>
            <ModalNewAction
                me={me}
                id_collaborator={selectedMember}
                token={token}
                colaborador={colaborador}
                setModalhowAcao={setModalhowAcao}
                showModalAcao={showModalAcao}
                selectedTeams={[selectedTeam]}
                disabled={true}
            />
            <ModalTimeline
                setIdRequest={setIdRequest}
                idRequest={idRequest}
                token={token}
                setParameter={setParameter}
            />
            <ModalEditActions
                setIdEditAction={setIdEditAction}
                idEditAction={idEditAction}
                token={token}
                setParameter={setParameter}
                handleDeleteAction={handleDeleteAction}
            />

            <ModalAddTimeline
                me={me}
                setAddTimeline={setIdRequest}
                idAddTimeline={idAddTimeline}
                token={token}
                setParameter={setParameter}
                showModalAdd={showModalAdd}
                setShowModalAdd={setShowModalAdd}
            />

            <div className="card">
                <div className="card-header">
                    <div className="columns w-100">
                        <div className="column is-10">
                            <p className="card-header-title">
                                Historico Ações
                            </p>
                        </div>
                        {buttonActon &&
                            <div className="column" style={{ textAlign: "right" }}>
                                <button
                                    onClick={() => {
                                        RequestAddAcao()
                                    }}
                                    className="button is-info mt-1">
                                    + Nova Ação
                                </button>
                            </div>
                        }
                    </div>
                </div>
                <div className="card-content">
                    <table className="table is-fullwidth">
                        <thead>
                        <tr>
                            {
                                collumns.map(value => (
                                    <th>{value}</th>
                                ))
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            dadosTable &&
                            dadosTable.map(value => (
                                <tr>
                                    <td>{auxData[value.id]}</td>
                                    <td>{value.gap.name}</td>
                                    <td>{value.burden}</td>
                                    <td>{SITUACAO[value.situation]}</td>
                                    <td>
                                        <div style={{display: "flex", gap: "10px"}}>
                                        {buttonActon &&
                                            <Link to="#" onClick={() => setIdEditAction(value.id)}>
                                                <i
                                                    className={"fa-regular fa-pen-to-square"}
                                                    style={{fontSize: "1.3em", color: "blue"}}
                                                ></i>
                                            </Link>
                                        }
                                            <Link to="#" onClick={() => RequestTimeLine(value.id)}>
                                                <i
                                                    className={"fa-regular fa-comment-dots"}
                                                    style={{fontSize: "1.3em", color: "blue"}}
                                                ></i>
                                            </Link>
                                            <Link to="#" onClick={() => RequestAddTimeLine(value.id)}>
                                                <i
                                                    className={"fa-solid fa-comment-medical"}
                                                    style={{fontSize: "1.3em", color: "blue"}}
                                                ></i>
                                            </Link>
                                            <Link to="#" onClick={() => handleDeleteAction(value.id)}>
                                                <i
                                                    className={"fa-solid fa-trash-can"}
                                                    style={{fontSize: "1.3em", color: "blue"}}
                                                ></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )
}

export default TabsTableForm
