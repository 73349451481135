import React, {useState, useEffect,  useContext} from 'react'
import { UserContext } from "../../context/UserContext";
import GraficoEngajamento  from "./GraficoEngajamento";


const RequestEngajamento = ({selectedTeams, nameTeam}) => {
    const [token] = useContext(UserContext);
    const [values, setValues] = useState('');

    const getEngagementTeam = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response =
        await fetch(
            `/api/ms/team/engagement/${selectedTeams.join(",")}`,
            requestOptions
        );

        const data = await response.json();

        if (data) {
            const tempArr = [];
            for (let i = 0; i < selectedTeams.length; i++) {
            tempArr.push(data[i]);
            }
            setValues(tempArr);
        }
    };

    useEffect(() => {
        setValues(null)
        if (selectedTeams.length){
            getEngagementTeam();
        }
    }, [selectedTeams])


    return (
        <GraficoEngajamento data={values} nameTeam={nameTeam}/>
    )
}

export default RequestEngajamento