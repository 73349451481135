import React, {useEffect} from "react";
import LineGraphNotas from "../../subcomponents/LineGraphNotas";
import loadingGif from "../../../icons/loading.gif";

const ModalNPS = ({
    showNPS,
    setShowNPS,
    collaborator,
    nameCollaborator,
    setCollaborator,
    NPS,
    setNPS,
}) => {
    const handleClose = () => {
        setShowNPS(false);
        setCollaborator(null);
        setNPS('')
        
    };
    useEffect(() => {
      console.log(collaborator);
    }, [collaborator]);
  return (
    <div className={`modal ${showNPS && "is-active"}`}>
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">NPS - Time x Colaborador</p>
          <button
            className="delete"
            aria-label="close"
            onClick={handleClose}
          ></button>
        </header>
        <section className="modal-card-body">
            {!NPS ? (
                <img src={loadingGif} alt="loading..." />
            ):(
              <LineGraphNotas
                NPS={NPS}
                selectedMemberName={nameCollaborator}
                collaborator={collaborator}
              />
            )}
        </section>
        <footer className="modal-card-foot">
          <button className="button" onClick={handleClose}>
            Fechar
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ModalNPS;
