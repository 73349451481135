import React, {useState, useEffect,  useContext} from 'react'
import { UserContext } from "../../context/UserContext";
import GraficoTurnover  from "./GraficoTurnover";


const RequestEngajamento = ({selectedMember, selectedMemberName=null}) => {
    const [token] = useContext(UserContext);
    const [values, setValues] = useState('');

    const getEngagementTeam = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch("/api/ms/collaborator/engagement/"+ selectedMember.pipefy_id, requestOptions);
        const data = await response.json();
        setValues(data);
    };

    useEffect(() => {
        if (selectedMember){
            getEngagementTeam();
        }

    }, [selectedMember])

    return (
        <GraficoTurnover
            data={values}
            tela='planoAcao'
            selectedMemberName={selectedMemberName}
        />
    )
}

export default RequestEngajamento