import React, {useState, useEffect} from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ModalNotification from './modals/ModalNotification'

const FormTagSkills = ({token, tags, getTags, setReload, reload}) => {
    const [selectedValue, setSelectedValue] = useState('');

    const [tagName, setTagName] = useState(null);
    
    const [hardSkillName, setHardSkillName] = useState(null);
    const [tagHardSkill, setTagHardSkill] = useState(null);

    const [softSkillName, setSoftSkillName] = useState(null);

    const [allHardskill, setAllHardskill] = useState([]);
    const [selectedhardSkill, setSelectedHardSkill] = useState([]);
    const animatedComponents = makeAnimated();
    
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);

    const handleCreate = async () => {
        let body = {};
        let rota = 'api';
        if(selectedValue ==='tag'){
            body = {name: tagName, status: true};
            rota = "api/tag_subteam";
        }
        else if(selectedValue ==='hs'){
            body = {
                "name": hardSkillName,
                "status": true,
                "id_tag_subteam": tagHardSkill
              };
            rota = "api/hardskill";
        }
        else if(selectedValue ==='ss'){
            body = {
                "name": softSkillName,
                "status": true,
              };
            rota = "api/softskill";
        }
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(body),
        };
        await fetch(rota, requestOptions);
        setHardSkillName('')
        setTagName('')
        setTagHardSkill('')
        setSoftSkillName('')
        setReload(!reload)
        getTags()
    };

    const handleGetSelected = async () => {
        if (tagHardSkill){
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
            };
            const response = await fetch(`api/association_hardskill_tag/${tagHardSkill}`, requestOptions);
            const data = await response.json();
            setSelectedHardSkill(data.map(option => ({ label: option.name, value: option.id })));
        }
    }
    const handleGetAllHardSkill = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const response = await fetch(`api/all_hardskill`, requestOptions);
        const data = await response.json();
        setAllHardskill(data.map(option => ({ label: option.name, value: option.id })));
    }

    useEffect(() => {
        if (tagHardSkill){
            handleGetSelected();
            handleGetAllHardSkill();
        }else{
            setSelectedHardSkill([])
            setAllHardskill([])
        }
    }, [tagHardSkill, reload])

    const handleConnect = async () => {

        const dados = selectedhardSkill.map(option => ({'id': option.value }))

        const body = {
                "status": true,
                "id_tag_subteam": tagHardSkill,
                "id_hard_skill": dados
            }
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(body),
        };
        await fetch('/api/association_hardskill_tag', requestOptions);
        setShowModal(true);
        setModalMessage(`HardSkill(s) associado(s) com sucesso.`);
    }

    return(
        <>
            <ModalNotification
                message={modalMessage}
                showModal={showModal}
                setShowModal={setShowModal}
                confirm = {false}
            />
            <div className="card is-fullwidth">
                <header className="card-header">
                    <p className="card-header-title">{selectedValue === 'hs' ? 'Criar/Associar Skills' : 'Criar Skills'}</p>
                </header>
                <div className={`card-content`}>
                    <div className="content">
                        <div className="columns">
                            <div className="column is-one-quarter">
                                <div className="control">
                                    <div className="select">
                                        <select
                                            value={selectedValue}
                                            onChange={(e) => setSelectedValue(e.target.value)}
                                        >
                                            <option selectedValue value={false}>Selecione</option>
                                            <option value='tag'>Tag</option>
                                            <option value='hs'>HardSkill</option>
                                            <option value='ss'>Softkill</option>
                                        </select>
                                    </div>
                                </div>            
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                    { selectedValue ==='tag' ?(
                                            <div className="columns">
                                                <div className="column is-one-quarter">
                                                    <label className="label">Nome Tag</label>
                                                    <input 
                                                        className="input" 
                                                        type="text" 
                                                        placeholder="Nome"
                                                        value={tagName}
                                                        onChange={(e) => setTagName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="column">
                                                    <label className="label">&nbsp;</label>
                                                    <button className="button is-half is-link is-one-fifth"
                                                    onClick={() => handleCreate()}
                                                    >
                                                    Salvar
                                                    </button>
                                                </div>
                                            </div>
                                    ):('')
                                    }
                                    { selectedValue ==='hs' ?(
                                        <div className="box">
                                                <div className="columns">
                                                    <div className="column is-one-quarter">
                                                        <label className="label">Nome HardSkill</label>
                                                        <input 
                                                            className="input" 
                                                            type="text" 
                                                            placeholder="Nome"
                                                            value={hardSkillName}
                                                            onChange={(e) => setHardSkillName(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="column ">
                                                        <label className="label">&nbsp;</label>
                                                        <button className="button is-link"
                                                        onClick={() => handleCreate()}>
                                                        Salvar
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="columns">
                                                    <div className="column is-one-quarter">
                                                        <h5 className="title is-5">Tag para associação</h5>
                                                        <div className="select is-fullwidth">
                                                            <select
                                                                value={tagHardSkill}
                                                                onChange={(e) => {
                                                                    setTagHardSkill(e.target.value);
                                                                }}
                                                            >
                                                                <option value=''>Selecione</option>
                                                                {
                                                                    tags && 
                                                                    tags.map(tag => (
                                                                        <option key={tag.id} value={tag.id}>{tag.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <h5 className="title is-5">HardSkills</h5>
                                                        <Select 
                                                            defaultValue={selectedhardSkill}
                                                            components={animatedComponents}
                                                            isMulti
                                                            classNamePrefix="select"
                                                            options={allHardskill}
                                                            onChange={(selected) => setSelectedHardSkill(selected)}
                                                            value={selectedhardSkill}
                                                            isClearable={true}
                                                            placeholder="Selecione as HardSkills"
                                                        />
                                                    </div>
                                                    <div className="column is-one-fifth">
                                                        <h5 className="title is-5">&nbsp;</h5>
                                                        <button className="button is-link"
                                                            onClick={() => handleConnect()}>
                                                            Associar
                                                        </button>
                                                    </div>
                                                </div>
                                        </div>
                                    ):('')
                                    }
                                    { selectedValue ==='ss' ?(
                                            <div className="columns">
                                                <div className="column column is-one-quarter">
                                                    <label className="label">Nome SoftSkill</label>
                                                    <input 
                                                        className="input" 
                                                        type="text" 
                                                        placeholder="Nome"
                                                        value={softSkillName}
                                                        onChange={(e) => setSoftSkillName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="column">
                                                    <label className="label">&nbsp;</label>
                                                    <button className="button is-half is-link is-one-fifth"
                                                    onClick={() => handleCreate()}>
                                                    Salvar
                                                    </button>
                                                </div>
                                            </div>
                                    ):('')
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </>
    )
}
export default FormTagSkills