import React, { useState, useEffect, useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import loadingGif from "../../icons/loading.gif";
import { UserContext } from '../../context/UserContext';

const Adesao = () => {
  const [token] = useContext(UserContext);
  const [adesao, setAdesao] = useState(null);
  const [selectedData, setSeletedData] = useState('839726');

  const getStatistic = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    const response = await fetch(
      `api/ms/tatistic/${selectedData}`,
      requestOptions
    );

    const data = await response.json();
    setAdesao(data);
  };


  const data = {
    labels: ["Completado", "Incompleto"],
    datasets: [
      {
        data: [adesao?.token_completed || 0,adesao?.token_count - adesao?.token_completed  || 0],
        backgroundColor: [
          "rgba(92,203,139,255)",
          "rgba(239,105,134,255)",
        ],
        borderColor: [
          "rgba(76,196,140, 0.6)",
          "rgba(226,71,95, 2.2)",
        ],
        borderWidth: 1,
        weight: 80,
        borderAlign: "inner",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        borderWidth: 0.5,
        labels: {
          boxWidth: 10,
          color: "blue",
          font: {
            weight: "bold",
          },
        },
        padding: {
          top: 10, // Espaço superior
          bottom: 10, // Espaço inferior
        },
      },
      datalabels: {
        color: "white",
        font: {
          size: 12,
        },
        anchor: "bottom",
        align: "inner",
      },
    },
  };



  useEffect(() => {
    if (selectedData) {
      getStatistic()
    }
  }, [selectedData]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <p className="card-header-title">
            Taxa de adesão
            <div className="ml-auto">
              <div className="select is-small">
                <select
                  value={selectedData}
                  onChange={(e) => setSeletedData(e.target.value)}
                >
                  <option value="839726">Pesquisa Pulse Outsourcing</option>
                  <option value="971886">Pesquisa Pulse Backoffice</option>
                </select>
              </div>
            </div>
          </p>
        </div>
        <div
      className="card-content"
      style={{
        height: "300px",
        width: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {adesao ? (
          <div style={{ position: "relative", marginLeft: "25em" }}>
          <Doughnut
            width={350} // Aumente o valor da largura
            height={350} // Aumente o valor da altura
            data={data}
            options={options}
            plugins={[ChartDataLabels]}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "30%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
         <span style={{ fontSize: "36px", fontWeight: "bold" }}>
            {adesao && adesao.token_completed
              ? `${((adesao.token_completed / (adesao.token_count || 1)) * 100).toFixed(1)}%`
              : 'N/A'}
          </span>
            <p>
              <span style={{ fontSize: "11px" }}>Respostas</span>
            </p>
          </div>
        </div>
      ) : (
        <img src={loadingGif} width="80" alt="loading..." />
      )}
    </div>
      </div>
    </>
  );
};

export default Adesao;
