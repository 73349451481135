import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
let options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
};
function PesquisaFatores({ token }) {
  const [pesquisa, setPesquisa] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [editForm, setEditForm] = useState(false);
  const [editSheet, setEditSheet] = useState(false);
  const [url, setUrl] = useState(null);
  const [sheetTitle, setSheetTitle] = useState(null);
  const [sending, setSending] = useState(false);

  const CreateNewPesquisa = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        url_google: "",
        google_sheet_title: "",
      }),
    };
    const response = await fetch("/api/pesquisas_fatores", requestOptions);
    const data = await response.json();
    setPesquisa(data);
    setUrl(data.url_google);
    setSheetTitle(data.google_sheet_title);
  };

  const getPesquisaFatores = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/pesquisas_fatores", requestOptions);

    const data = await response.json();
    if (data.length > 0) {
      setPesquisa(data[0]);
      setUrl(data[0].url_google);
      setSheetTitle(data[0].google_sheet_title);
    } else {
      CreateNewPesquisa();
    }
  };

  const getPesquisaFatoresRegistros = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/google/pesquisa_fatores", requestOptions);

    const data = await response.json();
    setApiData(data);
  };

  const handleUpdatePesquisaFatores = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        url_google: url,
        google_sheet_title: sheetTitle,
      }),
    };
    const response = await fetch(
      `/api/pesquisas_fatores/${pesquisa.id}`,
      requestOptions
    );
    if (response.ok) {
      setSending(false);
    }
    getPesquisaFatores();
  };

  const handleUpdatePesquisaFatoresSend = async (e) => {
    e.preventDefault();
    const requestOptions1 = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const requestOptions2 = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    setSending(true);
    await fetch("/api/send/pesquisa_fatores", requestOptions1);
    const response = await fetch(
      `/api/pesquisas_fatores_send/${pesquisa.id}`,
      requestOptions2
    );
    if (response) {
      setSending(false);
    }
    getPesquisaFatores();
  };

  const handleEdit = () => {
    setEditForm(true);
  };

  const handleSave = (e) => {
    handleUpdatePesquisaFatores(e);
    setEditForm(false);
  };

  const handleCancel = () => {
    setEditForm(false);
  };

  const handleEditSheet = () => {
    setEditSheet(true);
  };

  const handleSaveSheet = (e) => {
    handleUpdatePesquisaFatores(e);
    setEditSheet(false);
  };

  const handleCancelSheet = () => {
    setEditSheet(false);
  };

  const handleSend = (e) => {
    handleUpdatePesquisaFatoresSend(e);
    setEditForm(false);
    getPesquisaFatores();
  };

  useEffect(() => {
    getPesquisaFatores();
    getPesquisaFatoresRegistros();
  }, []);

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">Pesquisa de Fatores</p>
      </header>
      {pesquisa && (
        <div className="card-content">
          <div className="columns">
            <div className="column is-2">
              <strong>Google forms: </strong>
            </div>
            <div className="column is-8">
              {editForm ? (
                <>
                  <input
                    className="input"
                    type="text"
                    placeholder="Url do google forms"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </>
              ) : (
                <a
                  href={pesquisa.url_google}
                  target="_blank"
                  style={{ color: "blue" }}
                >
                  {pesquisa.url_google}
                </a>
              )}
            </div>
            <div className="column is-2">
              {editForm ? (
                <>
                  <Link
                    onClick={handleSave}
                    style={{ paddingLeft: "10px", color: "green" }}
                  >
                    <i className="fa-solid fa-circle-check fa-lg"></i>
                  </Link>
                  <Link
                    onClick={handleCancel}
                    style={{ paddingLeft: "10px", color: "red" }}
                  >
                    <i className="fa-solid fa-ban fa-lg"></i>
                  </Link>
                </>
              ) : (
                <Link onClick={handleEdit} style={{ paddingLeft: "10px" }}>
                  <i className="fa-regular fa-pen-to-square fa-lg"></i>
                </Link>
              )}
            </div>
          </div>
          <div className="columns">
            <div className="column is-2">
              <strong>Nome da planilha google: </strong>
            </div>
            <div className="column is-8">
              {editSheet ? (
                <>
                  <input
                    className="input"
                    type="text"
                    placeholder="Nome da planilha do google sheet"
                    value={sheetTitle}
                    onChange={(e) => setSheetTitle(e.target.value)}
                  />
                </>
              ) : (
                <span>{pesquisa.google_sheet_title}</span>
              )}
            </div>
            <div className="column is-2">
              {editSheet ? (
                <>
                  <Link
                    onClick={handleSaveSheet}
                    style={{ paddingLeft: "10px", color: "green" }}
                  >
                    <i className="fa-solid fa-circle-check fa-lg"></i>
                  </Link>
                  <Link
                    onClick={handleCancelSheet}
                    style={{ paddingLeft: "10px", color: "red" }}
                  >
                    <i className="fa-solid fa-ban fa-lg"></i>
                  </Link>
                </>
              ) : (
                <Link onClick={handleEditSheet} style={{ paddingLeft: "10px" }}>
                  <i className="fa-regular fa-pen-to-square fa-lg"></i>
                </Link>
              )}
            </div>
          </div>
          <p>
            <strong>
              Status:{" "}
              {pesquisa.sent ? (
                <span style={{ color: "green" }}>Já enviado!</span>
              ) : (
                <span style={{ color: "red" }}>Não enviado</span>
              )}
            </strong>
          </p>
          <p>
            <strong>Data do envio: </strong>
            {pesquisa.sent && (
              <span>
                {new Date(pesquisa.last_sent).toLocaleDateString(
                  "pt-BR",
                  options
                )}
              </span>
            )}
          </p>
          <p>
            <strong>Total de respostas: </strong>
            {apiData && <span>{apiData.length}</span>}
          </p>
        </div>
      )}
      <footer className="card-footer">
        <button
          className={`button card-footer-item ${sending && "is-loading"}`}
          onClick={(e) => {
            if (window.confirm("Confirmar?") == true) {
              handleSend(e);
            }
          }}
        >
          <span style={{ paddingRight: "10px" }}>Enviar pesquisa</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-rocket-takeoff"
            viewBox="0 0 16 16"
          >
            <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z" />
            <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z" />
            <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z" />
          </svg>
        </button>
      </footer>
    </div>
  );
}

export default PesquisaFatores;
