import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import Times from "./comp_teamguide/Times";
import ReportTable from "./comp_forms/ReportTable";

const Relatorio = ({faker}) => {
    const [token] = useContext(UserContext);
    const [selectedTeam, setSeletedTeam] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [typeReport, setTypeReport] = useState("operational");

    const [period, setPeriod] = useState("");
    const [actions, setActions] = useState([]);

    const [actionsSelected, setActionsSelected] = useState([]);
    const [msg, setMsg] = useState("Selecione o periodo");

    const [textButton, setTextButton] = useState("Gerar");
    const [isLoading, setIsLoading] = useState(false);
    const [nameTeam, setNameTeam] = useState([]);

    const handleReport = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(
                {
                    team: selectedTeam,
                    id_actions: actionsSelected
                }),
        };
        const response = await fetch(`/api/createreport/${typeReport}`, requestOptions);
        if (response.ok) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'relatorio_operacional.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            setIsLoading(false);
        } else {
            //console.log('Erro ao gerar relatório');
        }
    }

    const get_report_actions = async () => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
        const response = await fetch(`/api/report_actions/${selectedTeam}/${period}`,requestOptions);
        const data = await response.json();
        if (data.length){
            setActions(data);
        }else{
            setActions([]);
            setMsg("Nenhuma ação encontrada.")
        }
    };

    useEffect(() => {
        if ((period && selectedTeam) && typeReport === "operational"){
            get_report_actions();
        }else if(!period || period ===""){
            setActions([]);
            setMsg("Selecione o periodo.")
        }
        console.log("*****NomeTime:",selectedTeam);
      }, [period, selectedTeam]);
      

    return(
        <>
        <h1 className="subtitle">Relatórios</h1>
        <div className="column is-one-quarter">
                <label className="label">Time</label><br/>
                <Times
                    selectedTeams={selectedTeams}
                    setSelectedTeams={setSelectedTeams}
                    setNameTeam={setNameTeam}
                    faker={faker}
                />
            </div><br/>
        <div className="columns">
            
            <div className="column is-one-quarter">
                <label className="label">Tipo de relatório</label>
                <div className="select is-fullwidth" >
                    <select
                        id="tipo"
                        value={typeReport}
                        onChange={(e) => setTypeReport(e.target.value)}
                    >
                        <option disabled value=''>Tipo</option>
                        <option value='operational'>Operações</option>
                    </select>
                </div>
            </div>
            <div className="column is-one-quarter">
                <label className="label">&ensp;</label>
                <button 
                    className={`button is-info ${isLoading ? "is-loading" : ""}`}
                    onClick={() => {
                        setIsLoading(true);
                        handleReport().finally(() => setIsLoading(false));
                    }}
                >
                    {textButton}
                </button>
            </div>
        </div>
        
        {typeReport === "operational" ? (
        <div className="columns">
            <div className="column is-one-fifth">
                <label className="label">Periodo das Ações</label>
                <input
                    className="input"
                    type="month"
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                />
            </div>
        </div>
        ) : (null)}
        {typeReport === "operational" && actions ? 
        <ReportTable 
            actions={actions} 
            setActionsSelected={setActionsSelected}
            msg={msg}
        /> : null}
        </>
    )
    }
export default Relatorio