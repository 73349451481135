import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import ReactTooltip from "react-tooltip";
import loadingGif from "../../icons/loading.gif";
import { Bar } from "react-chartjs-2";

const NPS = ({ selectedTeams, nameTeam }) => {
  const [token] = useContext(UserContext);
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const color = ["rgba(106,159,197, 0.5)", "rgba(247,186,68, 0.5)", "rgba(239,105,134, 0.5)"];
  const bcolor = ["rgba(106,159,197)", "rgba(247,186,68)", "rgba(239,105,134)"];

  useEffect(() => {
    const fetchData = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const response = await fetch(
        `/api/nps/media/${selectedTeams.join(",")}`,
        requestOptions
      );
      const data = await response.json();
      setData(data);
    };

    if (selectedTeams.length) {
      fetchData();
    } else {
      setData(null);
    }
  }, [selectedTeams, token]);

  // ...

useEffect(() => {
  if (data && data.length) {
    // Agrupa os dados por equipe
    const dataByTeam = data.reduce((acc, item) => {
      if (!acc[item.team]) {
        acc[item.team] = [];
      }
      acc[item.team].push(item);
      return acc;
    }, {});

    // Para cada equipe, pega os 4 últimos itens
    const lastFourItemsByTeam = Object.entries(dataByTeam).reduce((acc, [team, teamData]) => {
      acc[team] = teamData.slice(-4);
      return acc;
    }, {});

    const trimestres = [];
    const teamValues = {};

    // Para cada equipe, preenche os arrays trimestres e teamValues
    Object.entries(lastFourItemsByTeam).forEach(([team, lastFourItems], index) => {
      lastFourItems.forEach(item => {
        const trimestreLabel = `${item.Ano_tri.split('-')[0]}-${item.Ano_tri.split('-')[1]}°`; // Adiciona '° Tri' ao formato da data
        if (!trimestres.includes(trimestreLabel)) {
          trimestres.push(trimestreLabel);
        }

        if (!teamValues[team]) {
          teamValues[team] = [];
        }
        teamValues[team].push(item.Nota);
      });
    });

    const datasets = Object.entries(teamValues).map(([team, teamValues], index) => ({
      label: `${nameTeam.find(item => item.id === team)?.name || team}`,
      data: teamValues,
      backgroundColor: color[index],
      borderColor: bcolor[index],
      borderWidth: 2,
      borderSkipped: false,
    }));

    setChartData({
      labels: trimestres,
      datasets,
    });
  } else {
    setChartData(null);
  }
}, [data, nameTeam]);

// ...


  return (
    <>
      <ReactTooltip id="nps1" effect="solid">
        
        <span>Média das notas dos colaboradores do time</span>
      </ReactTooltip>

      <div className="card">
        <div className="card-header">
          <p className="card-header-title">NPS - Nota média</p>
          <a
            className="card-header-icon"
            data-tip
            data-for="nps1"
            data-event="click focus"
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org//2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-question-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
              </svg>
            </span>
          </a>
        </div>
        <div className="card-content">
          {chartData ? (
            <Bar
              data={chartData}
              options={{
                scales: {
                  y: {
                    min: 0,
                    max: 10,
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
                plugins: {
                  datalabels: {
                    display: true,
                    color: "black",
                    anchor: "end",
                    offset: -2,
                    align: "start",
                  },
                },
              }}
            />
          ) : (
            <img src={loadingGif} alt="loading..." />
          )}
        </div>
      </div>
    </>
  );
};

export default NPS;
