import React, {useState, useEffect} from 'react'
import SubtimesSelect from '../comp_teamguide/SubtimesSelect'
import LiderSelect from '../comp_teamguide/LiderSelect'

const EditSubtimeModal = ({token, show, setShow, selectedSubTeam, setSelectedSubTeam, getSubTeams, tags}) => {
    const [selectedSubTeamId, setSelectedSubTeamId] = useState(null);
    const [selectedSubteamName, setSelectedSubteamName] = useState(null);
    const [selectedLider, setSelectedLider] = useState(null);
    const [description, setDescription] = useState(null);
    const [SelectedTagSubteam, setSelectedTagSubTeamId] = useState('');

    const handleCloseModal = () => {
        setSelectedSubTeam(null);
        setShow(false);
        getSubTeams();
    }

    const handleUpdateSubTeam = async (e) => {
        e.preventDefault();
        const body = JSON.stringify(
            {
                id_user: selectedLider,
                id_sub_team: selectedSubTeamId,
                name_sub_team: selectedSubteamName,
                description: description,
                id_tag: SelectedTagSubteam || null,
                status: true
            }
        )
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: body,
        };
        await fetch(`/api/subteams/${selectedSubTeam.id}`, requestOptions);
        handleCloseModal()
    };

    const handleDeleteSubTeam = async (e) => {
        e.preventDefault();

        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        await fetch(`/api/subteams/${selectedSubTeam.id}`, requestOptions);
        handleCloseModal()
    };

    useEffect(() => {
        if (selectedSubTeam) {
            setSelectedSubTeamId(Number(selectedSubTeam.id_sub_team));
            setSelectedSubteamName(selectedSubTeam.name_sub_team);
            setSelectedLider(selectedSubTeam.user.id);
            setDescription(selectedSubTeam.description);
            //var r = selectedSubTeam.tag === null || selectedSubTeam.tag === undefined ? '' : selectedSubTeam.tag.id;
            setSelectedTagSubTeamId(selectedSubTeam.tag === null || selectedSubTeam.tag === undefined ? '' : selectedSubTeam.tag.id);
        }

    }, [selectedSubTeam])
  return (
    <div className={`modal ${show && "is-active"}`}>
  <div className="modal-background" onClick={handleCloseModal}></div>
  <div className="modal-card">
    <header className="modal-card-head">
      <p className="modal-card-title">Edit Sub-time</p>
      <button className="delete" aria-label="close" onClick={handleCloseModal}></button>
    </header>
    <section className="modal-card-body">

        <SubtimesSelect
            selectedSubTeamGuideId={selectedSubTeamId}
            setSelectedSubTeamGuideId={setSelectedSubTeamId}
            setSelectedSubteamName={setSelectedSubteamName}
            setSelectedTagSubTeamId={setSelectedTagSubTeamId}
        />
        <div className="field">
            <div className="control">
            <div className="select is-fullwidth">
                <select
                    value={SelectedTagSubteam}
                    onChange={(e) => {
                        setSelectedTagSubTeamId(e.target.value)
                }}
                >
                    <option value=''>TAG</option>
                    {
                        tags &&
                        tags.map(tag => (
                            <option key={tag.id} value={tag.id}>{tag.name}</option>
                        ))
                    }
                    </select>
                </div>
            </div>
        </div>
        <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="textarea"
            placeholder="Descrição">
        </textarea>
        <br />

        <LiderSelect
            selectedLider={selectedLider}
            setSelectedLider={setSelectedLider}
        />

    </section>
    <footer className="modal-card-foot">

      <button className="button" onClick={handleCloseModal}>Cancelar</button>
      <button className="button is-success" onClick={handleUpdateSubTeam}>Salvar</button>
      <button className="button is-danger" onClick={handleDeleteSubTeam}>Apagar</button>
    </footer>
  </div>
</div>
  )
}

export default EditSubtimeModal