import React, {useState, useEffect,  useContext} from 'react'
import { UserContext } from "../../context/UserContext";

const SubtimesSelect = ({selectedSubTeamGuideId, setSelectedSubTeamGuideId, setSelectedSubteamName}) => {

    const [subTeams, setSubTeams] = useState(null);
    const [token] = useContext(UserContext);

    const getSubTeams = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch("/api/ms/get_subteams", requestOptions);
        const data = await response.json();
        setSubTeams(data);
    };

    useEffect(() => {
        getSubTeams();
    }, [])

    return (
    <>
    <div className="field">
        <div className="control">
            <div className="select is-fullwidth">
                <select
                value={selectedSubTeamGuideId}
                onChange={(e) => {
                    setSelectedSubTeamGuideId(e.target.value)
                    setSelectedSubteamName(subTeams.filter(s => s.id == e.target.value)[0].name)
                }}
                >
                    <option>Subtime</option>
                    {
                        subTeams &&
                        subTeams.map(subTeam => (
                            <option key={subTeam.id} value={subTeam.id}>{subTeam.name}</option>
                        ))
                    }
                </select>
            </div>
        </div>
    </div>
    </>
  )
}

export default SubtimesSelect