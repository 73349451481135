import React, { useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import Times from "./comp_teamguide/Times";
import RequestEngajamento from "./comp_teamguide/RequestEngajamento";
import RequestTurnover from "./comp_teamguide/RequestTurnover";
import NPS from "./comp_teamguide/NPS";
import NPSMedia from "./comp_teamguide/NPSMedia";
import GraficoCriticidade from "./comp_forms/GraficoCriticidade";
import GraficoPerformance from "./comp_forms/GraficoPerformance";

const Home = ({me, faker}) => {
  const [token] = useContext(UserContext);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [nameTeam, setNameTeam] = useState([]);
  const dt = localStorage.getItem("chartCriticality");

  return (
    me && (
      <div>
        <main>
          <h1 className="subtitle">Olá, {me.full_name}, Bem vindo(a) ao Actio!</h1>
        </main>
        <h1 className="title has-text-link">Analytics</h1>
        <div className="columns">
          <div style={{ marginLeft: '12px' }}>
          Equipes
          </div>
        </div>
          <Times
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            setNameTeam={setNameTeam}
            faker={faker}
          />
           <br/> 
        <div className="columns">
            <div className="column" >
            <GraficoCriticidade token={token} selectedTeams={selectedTeams} />
            </div>
        </div>
        <div className="columns">
          <div className="column">
            <GraficoPerformance token={token} selectedTeams={selectedTeams} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <RequestEngajamento
              selectedTeams={selectedTeams}
              nameTeam={nameTeam}
            />
          </div>
          <div className="column">
            <NPS 
              selectedTeams={selectedTeams}
              nameTeam={nameTeam}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <RequestTurnover
              selectedTeams={selectedTeams}
              nameTeam={nameTeam}
            />
          </div>
          
          <div className="column">
            <NPSMedia 
              selectedTeams={selectedTeams} 
              nameTeam={nameTeam}
            />
          </div>
        </div>
      </div>
    )
  );
};
export default Home;
