import React, {useState, useEffect} from 'react';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';

const ModalTimeline = ({setIdRequest, idRequest, token, setParameter} )=>{
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);
    const [dataAux, setAuxData] = useState(['']);
    const [comentario, setComentario] = useState('Não existem comentarios');
    
    
    const getTimeLine = async (idRequest) => {

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch("/api/get_timeline/"+idRequest, requestOptions);
        const data = await response.json();
        //console.log(data);
        setShowModal(true);
        setData(data);

    };

    useEffect(() =>{
        if(!showModal){
            setIdRequest(null);
        }
    }, [showModal])

    useEffect(() => {
        if (idRequest){
            getTimeLine(idRequest)
        }
    }, [idRequest])

    useEffect(() => {
        if (data.length){
            setComentario('')
            let formatDate = {}
            let options = {year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric'};
            data.forEach(function(item){
                let data = item.lock_updated;
                data  = new Date(data);
                data = data.toLocaleDateString("pt-BR", options);
                formatDate[item.id] = data;
            })
            setAuxData(formatDate)
        }else{
            setComentario('Não existem comentarios')
        }
    }, [data])

    return(
        <div>
        <div className={`modal ${showModal && "is-active"}`}>
            <div className="modal-background" onClick={() => {setShowModal(false)}}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Comentarios</p>
                    <button onClick={() => {setShowModal(false)}} className="delete" aria-label="close"></button>
                </header>
                <section className="modal-card-body">
                <h4 className="subtitle is-4">{comentario}</h4>
                <Timeline lineColor={'#ddd'}>
                    {
                    data && (
                        data.map(value => (
                        <TimelineItem
                            key={value.id}
                            dateText={dataAux[value.id]}
                            dateInnerStyle={{ background: '#4bcefa'}}
                            style={{ color: '#4bcefa' }}
                        >
                            <h3><b>{value.title}</b></h3>
                            <p>{value.sub_title}</p>
                        </TimelineItem>
                        ))
                    )
                    }
                    </Timeline>
                </section>
                <footer className="modal-card-foot">
                    <button onClick={() => {setShowModal(false)}} class="button">Fechar</button>
                </footer>
            </div>
        </div>
        </div>
    )
}
export default ModalTimeline
