import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import Times from "./comp_teamguide/Times";
import TableCriticidadeFull from "./comp_forms/TableCriticidadeFull";
import TimesNew from "./comp_teamguide/TimesNew";

const Indicadores = ({ faker = false }) => {
  const [token] = useContext(UserContext);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [indicadores, setIndicadores] = useState(null);
  const [size] = useState(6);
  const [lastPage1, setLastPage1] = useState(null);
  const [page1, setPage1] = useState(1);

  const updateIndicadores = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    setIsLoading(true);
    await fetch(`/api/update_indicators?faker=${faker}`, requestOptions);

    setIsLoading(false);
    getIndicadores();
  };

  const getIndicadores = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      `/api/full/indicators/${selectedTeams[0]}?page=${page1}&size=${size}`,
      requestOptions
    );
    const data = await response.json();
    let data_2 = data.items;
    data_2 &&
      data_2.forEach((item) => {
        let nome = item.name_collaborator.split(" ");
        if (nome.length === 1) {
          item["nome_2"] = nome[0];
        } else {
          if (nome[1].length > 3) {
            nome = nome[0] + " " + nome[1];
          } else {
            nome = nome[0] + " " + nome[1] + " " + nome[2];
          }
          item["nome_2"] = nome;
        }

      });
      // data_2 = data_2.filter(i => i.id_collaborator !== 3485)
      // data_2 = data_2.filter(i => i.id_collaborator !== 2697)
      // data_2 = data_2.filter(i => i.id_collaborator !== 4297)
    setIndicadores(data_2);
    setLastPage1(Math.floor(data.total / data.size));
  };

  useEffect(() => {
    selectedTeams && getIndicadores();
  }, [selectedTeams]);

  return (
    <div>
      <h1 className="subtitle">Risco Demissão</h1>
      <div className="columns">
        <div className="column">
          <TimesNew selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} faker={faker}/>
        </div>
        <div className="column">
          <button
            className={`button is-link ${isLoading && "is-loading"}`}
            style={{marginTop: "12px", float:"right", backgroundColor: "#4885f7"}}
            onClick={(e) => {
              if (
                window.confirm(
                  "Você realmente quer atualizar as previsões da IA?"
                )
              ) {
                updateIndicadores(e);
              }
            }}
          >
            Buscar Indicadores
          </button>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TableCriticidadeFull
            token={token}
            selectedTeams={selectedTeams}
            indicadores={indicadores}
            lastPage={lastPage1}
            page={page1}
            setPage={setPage1}
            getIndicadores={getIndicadores}
          />
        </div>
      </div>
    </div>
  );
};

export default Indicadores;
