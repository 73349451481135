import React, {useState} from 'react'

const CreateUser = ({token, getUsers}) => {

    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [type, setType] = useState("adm");
    const [password, setPassword] = useState("somosprofectum");
    const [idTeamGuide, setIdTeamGuide] = useState(0);
    const [showAddForm, setShowAddForm] = useState(false);

    const handleCreateUser = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(
                {
                    email: email,
                    full_name: fullName,
                    type: type,
                    hashed_password: password,
                    has_temporary_password: true,
                    id_teamguide: idTeamGuide
                }
                ),
        };
        await fetch("/api/users", requestOptions);
        setEmail("");
        setFullName("")
        getUsers();
    };
    
    return (
       

        <div className="card">
            <a href="#" onClick={() => setShowAddForm(!showAddForm)}>
                <header className="card-header" style={{backgroundColor: "#eae8eb"}}>
                    <p className="card-header-title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        <span style={{paddingLeft: "10px"}}>Novo Usuário</span> 
                    </p>
                    <button className="card-header-icon" aria-label="more options">
                        <span className="icon">
                            {
                            showAddForm
                            ?(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up" viewBox="0 0 16 16">
                                <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"/>
                                </svg>
                            )
                            :(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                </svg>
                            )
                            }
                            
                        </span>
                    </button>
                </header>
            </a>
            {
                showAddForm && (
                    <>
                    <div className="card-content">
                <div className="content">
                    <div className="columns">
                <div className="column">
                    <label className="label">E-mail</label>
                    <input 
                    className="input" 
                    type="text" 
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="column">
                <label className="label">Nome completo</label>
                    <input 
                        className="input" 
                        type="text" 
                        placeholder="Nome completo"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        />
                </div>
                <div className="column">
                <label className="label">Senha inicial</label>
                    <input 
                        className="input" 
                        type="text" 
                        placeholder="Senha inicial"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        />
                </div>
                <div className="column">
                    <label className="label">Tipo</label>
                    <div className="select is-fullwidth">
                        <select value={type} onChange={(e) => setType(e.target.value)}>
                            <option value="adm">Administrador</option>
                            <option value="lider">Lider</option>
                            <option value="cliente">Cliente</option>
                        </select>
                    </div>
                </div>
                <div className="column">
                <label className="label">ID Teamguide</label>
                    <input 
                        className="input" 
                        type="number" 
                        placeholder="ID Teamguide"
                        value={idTeamGuide}
                        onChange={(e) => setIdTeamGuide(e.target.value)}
                        readOnly={type === 'adm'}
                        />
                </div>
            </div>
            </div>
            </div>
            <footer className="card-footer">
                <a href="#" className="card-footer-item" onClick={handleCreateUser}>
                    Salvar
                </a>
            </footer>
                </>
                )
            }
            
        </div>
    

    
    )
}

export default CreateUser