import React, { useState, useEffect } from 'react'
import CardIcon from './CardIcon';

const DataDriven = ({dadosTableFinaly}) => {
    const [naoCritico, setNaoCritico] = useState([0,null]);
    const [critico, setCritico] = useState([0, null]);
    const [intermediario, setNaoIntermediario] = useState([0,null]);

    const [naoIniciado, setNaoIniciado] = useState([0, null]);
    const [EmAndamento, setEmAndamento] = useState([0, null]);
    const [concluido, setConcluido] = useState([0, null]);

    function percentage(partialValue, totalValue) {
        let p = ((100 * partialValue) / totalValue).toFixed(2)
        return parseInt(p);
    } 

    useEffect(() => {
        if(dadosTableFinaly.length){
            //console.log(dadosTableFinaly)
            const criticidade = [0,0,0]
            const status = [0,0,0]
            const totalValue = dadosTableFinaly.length
            dadosTableFinaly.forEach(function (item, i) {
                if(item.criticality == "Crítico") criticidade[0]++
                if(item.criticality == "Intermediário") criticidade[1]++
                if(item.criticality == "Não crítico") criticidade[2]++

                if(item.situation == "NAO_INICIADO") status[0]++
                if(item.situation == "ANDAMENTO") status[1]++
                if(item.situation == "CONCLUIDO") status[2]++
            
            })
            const fCritico = []
            const fIntermediario = []
            const fNaoCritico = []

            const fNaoIniciado = []
            const fEmANdamento = []
            const fConcluido = []

            fCritico[0] = criticidade[0]
            fCritico[1] = percentage(criticidade[0], totalValue)
            setCritico(fCritico)
            fIntermediario[0] = criticidade[1]
            fIntermediario[1] = percentage(criticidade[1], totalValue)
            setNaoIntermediario(fIntermediario)
            fNaoCritico[0] = criticidade[2]
            fNaoCritico[1] = percentage(criticidade[2], totalValue)
            setNaoCritico(fNaoCritico)            

            fNaoIniciado[0] = status[0]
            fNaoIniciado[1] = percentage(status[0], totalValue)
            setNaoIniciado(fNaoIniciado)
            fEmANdamento[0] = status[1]
            fEmANdamento[1] = percentage(status[1], totalValue)
            setEmAndamento(fEmANdamento)
            fConcluido[0] = status[2]
            fConcluido[1] = percentage(status[2], totalValue)
            setConcluido(fConcluido) 
        }else{
            setNaoCritico([0,null])
            setCritico([0,null])
            setNaoIntermediario([0,null])
            setNaoIniciado([0,null])
            setEmAndamento([0,null])
            setConcluido([0,null])
        }

    }, [dadosTableFinaly]);

    return (
        <div>
            <div className="columns">
            <div className="column">
            <div className="card">
            <header className="card-header">
                <p className={"card-header-title"}>
                    Quantidade de ações
                </p>       
            </header>
                <div className="card-content">
                <div className="columns">
                    
                    <div className="column">
                    <CardIcon
                        title={"Crítico"} 
                        content={critico[0]} 
                        icon={'fa-regular fa-face-frown fa-xl'}
                        color={'has-text-danger'}
                        percent={critico[1]}
                    />
                    </div>
                    <div className="column">
                        <CardIcon 
                            title={"Intermediário"} 
                            content={intermediario[0]} 
                            icon={'fa-regular fa-face-meh fa-xl'}
                            color={'has-text-warning-dark'}
                            percent={intermediario[1]}
                        />
                    </div>
                    <div className="column">
                        <CardIcon 
                            title={"Não Crítico"} 
                            content={naoCritico[0]} 
                            icon={'fa-regular fa-face-grin fa-xl'}
                            color={'has-text-info'}
                            percent={naoCritico[1]}
                        />
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="column">
            <div className="card">
                <header className="card-header">
                <p className={"card-header-title"}>
                    Status das Ações
                </p>       
                </header>
                <div className="card-content">
                    <div className="columns"> 
                        <div className="column">
                            <CardIcon 
                                title={"Não Iniciado"} 
                                content={naoIniciado[0]} 
                                icon={'fa-solid fa-hourglass-start fa-xl'}
                                color={'has-text-danger'}
                                percent={naoIniciado[1]}
                            />
                        </div>
                        <div className="column">
                            <CardIcon 
                                title={"Em Andamento"} 
                                content={EmAndamento[0]} 
                                icon={'fa-regular fa-hourglass-half fa-xl'}
                                color={'has-text-warning-dark'}
                                percent={EmAndamento[1]}
                            />
                        </div>
                        <div className="column">
                            <CardIcon 
                                title={"Concluído"} 
                                content={concluido[0]}
                                icon={'fa-solid fa-hourglass-end fa-xl'}
                                color={'has-text-info'}
                                percent={concluido[1]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>

    )

}
export default DataDriven

