import React, { useState, useEffect } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import loadingGif from "../../icons/loading.gif";
import StackedBarChart from "./StackedBarChart"

const ChartAcoes = ({ dados }) => {
  const [labels, setLabels] = useState();
  const [values, setValues] = useState(null);
  const [percentages, setPercentages] = useState([]);
  const [dataBar, serDataBar] = useState([]);

  useEffect(() => {
    if (dados.length) {
      const ANDAMENTO = dados.filter((item) => item.situation === 'ANDAMENTO');
      const CONCLUIDO = dados.filter((item) => item.situation === 'CONCLUIDO');
      const NAO_INICIADO = dados.filter((item) => item.situation === 'NAO_INICIADO');
      
      setValues([NAO_INICIADO.length, ANDAMENTO.length, CONCLUIDO.length]);
      
      const totalCount = dados.length;
      const calculatedPercentages = [
        (NAO_INICIADO.length / totalCount) * 100,
        (ANDAMENTO.length / totalCount) * 100,
        (CONCLUIDO.length / totalCount) * 100
      ];
      
      const datas = [
        { color: "rgba(226,71,95, 1.5)", value: calculatedPercentages[0], legend: 'Não Iniciado' },
        { color: "rgba(250,226,138, 1.5)", value: calculatedPercentages[1], legend: 'Em Andamento' },
        { color: "rgba(76,196,140, 1.5)", value: calculatedPercentages[2], legend: 'Concluido' },
      ];
      serDataBar(datas)

      setPercentages(calculatedPercentages);
    }else{
      setPercentages([])
      setValues([0]);

      const datas = [
        { color: "rgba(120,120,120, 1.5)", value: 100, legend: 'Não Iniciado' },
        { color: "rgba(120,120,120, 1.5)", value: 0, legend: 'Em Andamento' },
        { color: "rgba(120,120,120, 0.5)", value: 0, legend: 'Concluido' },
      ];
      serDataBar(datas)
    }
  }, [dados]);



  useEffect(() => {
    if (percentages.length > 0) {
      const label = ['Não Iniciado', 'Em Andamento', 'Concluido'];
      const formattedValues = label.map((label, index) => `${label} (${percentages[index].toFixed(2)}%)`);

      setLabels(formattedValues);
    }else{
      const label = ['Não Iniciado', 'Em Andamento', 'Concluido'];
      setLabels(label)
    }
  }, [percentages]);

  useEffect(() => {
    if (dados.length > 0) {
      
    }
  
  }, [dados]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: values,
        backgroundColor: [
          "rgba(226,71,95, 1.5)",
          "rgba(250,226,138, 1.5)",
          "rgba(76,196,140, 0.5)",
        ],
        borderColor: [
          "rgba(226,71,95, 2.2)",
          "rgba(250,226,138, 2.2)",
          "rgba(76,196,140, 0.6)",
        ],
        borderWidth: 1,
        weight: 2,
        borderAlign: "inner",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        borderWidth: 0.5,
        labels: {
          boxWidth: 10,
          color: "blue",
          font: {
            weight: "bold",
          },
        },
        padding: {
          top: 10,
          bottom: 10,
        },
      },
    },
    datalabels: {
      color: "gray",
      font: {
        size: 10,
      },
      anchor: "right",
      align: "inner",
    },
  };


  return (
    <>
      <div className="column is-two-fifths">
        <br />
        <h1 className="title is-5 has-text-link">Quantidade de Ações</h1>
        {values ? (
          <div
            style={{
              position: "relative",
            }}
          >
            <Doughnut
              width={300}
              height={300}
              data={data}
              options={options}
              plugins={[ChartDataLabels]}
            />
            <div
              style={{
                position: "absolute",
                top: "58%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <span style={{ fontSize: "36px", fontWeight: "bold" }}>
                {values.reduce((acc, value) => acc + value, 0)}
              </span>
              <p>
                <span style={{ fontSize: "13px" }}>Ações</span>
              </p>
            </div>
          </div>
        ) : (
          <img src={loadingGif} width="80" alt="loading..." />
        )}
      </div>
      <div className="column">
        <br/>
        <h1 className="title is-5 has-text-link">Status das Ações</h1>
        <br/><br/><br/>
        <StackedBarChart data={dataBar} />
      </div>
    </>
  );
};

export default ChartAcoes;
