import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import TableLowPerformance from "./TableLowPerformance";
import ReactTooltip from "react-tooltip";
import ChartPerfomance from "./ChartPerfomance"

ChartJS.register(ArcElement, Tooltip, Legend);

const GraficoPerformance = ({ token, selectedTeams }) => {

  return (
    <>
    <ReactTooltip id="perf" effect="solid">
        <span>
        Visão geral do time em relação à ultima pesquisa NPS
        </span>
      </ReactTooltip>
      <div className="card">
      <div className="card-header">
        <p className="card-header-title">Análise de Performance</p>
        <a
            className="card-header-icon"
            data-tip
            data-for="perf"
            data-event="click focus"
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-question-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
              </svg>
            </span>
          </a>
      </div>
      <div className="card-content">
        <div className="columns">
          <ChartPerfomance token={token} selectedTeams={selectedTeams}/>
          <TableLowPerformance selectedTeams={selectedTeams} />
        </div>
      </div>
    </div>
    </>
    
  );
};

export default GraficoPerformance;
