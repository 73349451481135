import React, { useState, useEffect, useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import loadingGif from "../../icons/loading.gif";
import { UserContext } from '../../context/UserContext';

const AbaladoEmocional = ({selectedTeams,  period}) => {
  const [token] = useContext(UserContext);
  const [enps, setEnps] = useState(null);
  const [year, month] = period.split('-').map(Number);

  const getEnps = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    const response = await fetch(
        `api/ms/time/enps/${selectedTeams}/${month}/${year}`,
      requestOptions
    );
    const data = await response.json();
    //console.log("🚀 ~ file: eNPS.jsx:25 ~ getEnps ~ data:", data)
    setEnps(data);
  };

  useEffect(() => {
    if (selectedTeams) {
        getEnps();
    }
  }, [selectedTeams, period]);

  const data = {
    labels: ['Sim','Não'],
    datasets: [
      {
        label: "# of Votes",
        data: [  enps?.sim || 0, enps?.nao || 0,],
        backgroundColor: [
          "rgba(246,76,25,100)",
          "rgba(55,219,208,100)",
          "rgba(92,203,139,255)",
          "rgba(206,206,206, 0.5)",
        ],
        borderColor: [
          "rgba(225,68,20, 2.2)",
          "rgba(38,206,195, 2.2)",
          "rgba(76,196,140, 0.6)",
          "rgba(206,206,206, 0.6)",
        ],
        borderWidth: 1,
        weight: 2,
        borderAlign: "inner",
      },
    ],
  };


  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        borderWidth: 0.5,
        labels: {
          boxWidth: 10,
          color: "blue",
          font: {
            weight: "bold",
          },
        },
        padding: {
          top: 10, // Espaço superior
          bottom: 10, // Espaço inferior
        },
      },
      datalabels: {
        color: "white",
        font: {
          size: 12,
        },
        anchor: "right",
        align: "inner",
      },
    },
  };



  return (
    <>
  <div className="card">
    <div className="card-header">
      <p className="card-header-title">Emocionalmente Abalado</p>
    </div>
    <div
      className="card-content"
      style={{
        height: "410px",
        width: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <br/><br/>
      <p
        style={{
          textAlign: "justify",
          paddingRight: "15", // Removendo a margem direita
          fontSize: "14px",
          margin: -15, // Removendo a margem
        }}
      >

        Nos últimos meses, em algum momento, você já deixou de produzir ou se
        manter engajado por estar emocionalmente abalado?
      </p>
      <div style={{ position: "relative", marginLeft: "13em", }}>
      <Doughnut
        width={350}
        height={350}
        data={data}
        options={options}
        plugins={[ChartDataLabels]}
      />
      </div>
    </div>
  </div>
</>



  );
};

export default AbaladoEmocional;
