import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


export const InfoEngajamento = ({colaborador, token}) => {

    const getCriticidade = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const response = await fetch(`/api/ms/collaborator/engagement/${colaborador.pipefy_id}`, requestOptions);
        const data = await response.json();
        setLabels(data.map(x => x.date));
        setValues(data.map(x => x.engagement));
    };

    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        getCriticidade()
    }, []);

    return (
        <div className="field">
          <label className="label text-darkblue">Engajamento</label>
          <div className="control is-flex is-flex-direction-column is-justify-content-flex-start">
            <Line
              data={{
                labels: labels,
                datasets: [
                  {
                    label: '',
                    data: values,
                    borderColor: "rgba(75,192,192,1)",
                    backgroundColor: "rgba(75,192,192,0.2)",
                    borderWidth: 2,
                    fill: true,
                    display: false,
                  }
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  }
                },
                scales: {
                  y: {
                    min: 0,
                    max: 100,
                  }
                },
                height: 200,
              }}
            />
          </div>
        </div>
      );
}
