import React, { useState } from 'react';

const ModalNotification = ({ message, onResponse, showModal, setShowModal, confirm = true }) => {
    const [response, setResponse] = useState(null);
  
    return(
        <div>
        <div className={`modal ${showModal && "is-active"}`}>
            <div className="modal-background" onClick={() => {setShowModal(false)}}></div>
            <div className="modal-card">
                <section className="modal-card-body">
                <p>{message}</p>
                
                </section>
                {confirm ?(
                    <footer className="modal-card-foot">
                        <button className="button" onClick={() => {onResponse()}}>Confirmar</button>
                        <button onClick={() => {setShowModal(false)}} className="button">Cancelar</button>
                    </footer>)
                    :  (<footer className="modal-card-foot">
                    <button className="button" onClick={() => {setShowModal(false)}}>Ok</button>
                    </footer>)
                }

            </div>
        </div>
        </div>
    )
  };
  
  export default ModalNotification;
  
