import React, {useState, useEffect,  useContext} from 'react'
import { UserContext } from "../../context/UserContext";


const RequestMember = ({selectedTeam, members, setMembers}) => {

    const [token] = useContext(UserContext);


    const getMembers = async (team) => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch(`/api/ms/time/collaborators/${team}`, requestOptions);
        const data = await response.json();
        ////console.log("🚀 ~ file: RequestMember.jsx:21 ~ getMembers ~ data:", data)
         // Adicionar a propriedade 'team' a cada objeto do array
        const dataWithTeam = data.map((member) => {
            return {
            ...member,
            team: team,
            };
        });
        ////console.log("🚀 ~ file: RequestMember.jsx:21 ~ getMembers ~ dataWithTeam:", dataWithTeam);

        return dataWithTeam;
    };

    useEffect(() => {
        const fetchData = async () => {
            setMembers(() => null)
            if (selectedTeam.length > 0){
                const fetchedData = [];
                for (const item of selectedTeam) {
                    const response = await getMembers(item);
                    fetchedData.push(...response);
                }
                setMembers(fetchedData)
            }
        };
        fetchData();
    }, [selectedTeam])

    return (
        members &&<React.Fragment>
            {
            members.map((member) => (

                <option key={`member${member.id}`} value={member.id}>{member.name}</option>
            ))
            }
        </React.Fragment>
    )
}

export default RequestMember