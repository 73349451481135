import React, {useState, useEffect,  useContext} from 'react'
import { UserContext } from "../context/UserContext";
import FormSubtimes from './comp_forms/FormSubtimes';
import SubTeamTable from './comp_forms/SubTeamTable';
import EditSubtimeModal from './comp_forms/EditSubtimeModal';
import FormTagSkills from './comp_forms/FormTagSkills'
import TableTagSkills from './comp_forms/TableTagSkills'
import Message from './Message'

const Subtimes = () => {
  const [token] = useContext(UserContext);
  const [subTeams, setSubTeams] = useState(null);
  const [show, setShow] = useState(false);
  const [selectedSubTeam, setSelectedSubTeam] = useState(null);
  const [tags, setTags] = useState(null);
  const [reload, setReload] = useState(false);

  const getSubTeams = async () => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        },
    };
    const response = await fetch("/api/subteams", requestOptions);
    const data = await response.json();
    setSubTeams(data);
  };

  const getTags = async () => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        },
    };
    const response = await fetch("/api/all_tag_subteam", requestOptions);
    const data = await response.json();

    setTags(data);
};

  useEffect(() => {
      getSubTeams();
      getTags();
  }, [])

  return (
    <div>
      <EditSubtimeModal
        token={token}
        show={show}
        setShow={setShow}
        selectedSubTeam={selectedSubTeam}
        setSelectedSubTeam={setSelectedSubTeam}
        getSubTeams={getSubTeams}
        tags={tags}
      />
      <h1 className="subtitle">Subtimes</h1>
      <div className="columns">
        <div className="column is-4">
          <FormSubtimes token={token} getSubTeams={getSubTeams} tags={tags}/>
        </div>
        <div className="column">
          <SubTeamTable
            token={token}
            subTeams={subTeams}
            setSelectedSubTeam={setSelectedSubTeam}
            setShow={setShow}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <FormTagSkills
          token={token}
          tags={tags}
          getTags={getTags}
          setReload={setReload}
          reload={reload}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TableTagSkills
          token={token}
          tags={tags}
          getTags={getTags}
          setReload={setReload}
          reload={reload}
          />
        </div>
      </div>
    </div>
  )
}

export default Subtimes