import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import loadingGif from "../../icons/loading.gif";
import { Bar } from "react-chartjs-2";

const GraficoEngajamento = ({ data, nameTeam }) => {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const color = ["rgba(106,159,197, 0.5)", "rgba(247,186,68, 0.5)", "rgba(239,105,134, 0.5)"];
  const bcolor = ["rgba(106,159,197)", "rgba(247,186,68)", "rgba(239,105,134)"];
  const options_date = {
    year: "numeric",
    month: "2-digit",
  };

  useEffect(() => {
    if (data && nameTeam) {
      const id_teams = [];
      for (let i = 0; i < nameTeam.length; i++) {
        id_teams.push(nameTeam[i].id);
      }

      const validData = data.map((item) => ({
        date: item.date,
        engajamento_individual: item.engajamento_individual,
        team_or_subteam_id: item.team_or_subteam_id,
      }));

      const tempLabelsSet = new Set(validData.map((entry) => entry.date));
      const tempLabels = Array.from(tempLabelsSet);

      const tempDatasets = id_teams.map((team) => {
        return tempLabels.map((date) => {
          const teamDataForDate = validData.filter((entry) => entry.date === date && entry.team_or_subteam_id === team);
          const valuesForTeamAndDate = teamDataForDate.map((entry) =>
            parseFloat(entry.engajamento_individual)
          );


          return valuesForTeamAndDate;
        });
      });

      console.log(tempLabels, tempDatasets);
      setLabels(tempLabels);
      setDatasets(tempDatasets);
    }
  }, [data, nameTeam]);

  function getBackgroundColor(index) {
    return color[index % color.length];
  }

  return (
    <>
      <ReactTooltip id="engajamento" effect="solid">
        <span>
          Engajamento dos colaboradores é medido a cada 45 dias e considera
          questões como liderança, metas, motivação e propósito
        </span>
      </ReactTooltip>
    <div className="card">
            <div className="card-header">
              <p className="card-header-title">Engajamento</p>
              <a
                className="card-header-icon"
                data-tip
                data-for="engajamento"
                data-event="click focus"
              >
                <span className="icon">
                </span>
              </a>
            </div>
            <div className="card-content">
              {labels.length > 0 && datasets.length > 0 && nameTeam && nameTeam.length > 0 ? (
                <Bar
                  data={{
                    labels: labels
                      .filter((label) => label !== null) // Filter out null labels
                      .map((label) => new Date(label).toLocaleDateString(undefined, options_date)),
                    datasets: datasets.map((data, index) => ({
                      label: nameTeam[index] && nameTeam[index].name ? nameTeam[index].name : `Team ${index + 1}`,
                      data: data.map((value) => (!isNaN(value) ? Number(value).toFixed(2) : null)),
                      backgroundColor: getBackgroundColor(index),
                      borderColor: bcolor[index % bcolor.length],
                      borderWidth: 2,
                      borderSkipped: false,
                    })),
                  }}
                  options={{
                    scales: {
                      y: {
                        min: 0,
                        max: 100,
                        ticks: {
                          stepSize: 1,
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        display: true,
                        color: "black",
                        anchor: "end",
                        offset: -2,
                        align: "start",
                      },
                    },
                  }}
                />
              ) : (
                <img src={loadingGif} alt="loading..." />
              )}
            </div>
          </div>
        </>
      );
    };

export default GraficoEngajamento;