import React, {useState, useEffect, useContext} from 'react'
import {UserContext} from "../../context/UserContext";

const TimesNew = ({selectedTeams, setSelectedTeams, setNameTeam}) => {
    const [teams, setTeams] = useState(null);
    const [token] = useContext(UserContext);
    const color = ['is-info','is-warning','is-danger'];

    const getTeams = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const response = await fetch(`/api/ms/get_teams`, requestOptions);
        if (response.ok) {
            const data = await response.json();
            ////console.log("times", data);
            setTeams(data);
            const firstTeamId = data[0].id.toString();
            const firstTeamName = data[0].name.toString();
            //setNameTeam([firstTeamName]);
            setSelectedTeams([firstTeamId]);
        }
    };

    const onChangeBox = async (e) => {
        const teamId = e.target.value;
        const teamName = e.target.dataset.name;
        if (e.target.checked) {
            setSelectedTeams((prevSelectedTeams) => [...prevSelectedTeams, teamId]);
            //setNameTeam((prevNameTeam) => [...prevNameTeam, teamName]);
        } else {
            setSelectedTeams((prevSelectedTeams) =>
                prevSelectedTeams.filter((id) => id !== teamId)
            );
            // setNameTeam((prevNameTeam) =>
            //     prevNameTeam.filter((name) => name !== teamName)
            // );
        }
    };

    useEffect(() => {
        getTeams();
    }, []);

    return (
        <div>
            <div className="columns ml-0">
                <label >Equipes:</label>
            </div>
            <div className="columns">
                {teams ? (
                    teams.map((team, index) => (
                        <div
                            className={`button ${color[index]} is-light`}
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                marginLeft: '12px'
                            }}
                            key={team.id}
                        >
                            <input
                                type="checkbox"
                                id={`team_${team.id}`}
                                value={team.id}
                                checked={selectedTeams && selectedTeams.includes(team.id.toString())}
                                onChange={onChangeBox}
                                disabled={!teams}
                                data-name={team.name}
                                style={{marginRight: "5px"}}
                            />
                            <label htmlFor={`team_${team.id}`}>{team.name}</label>
                        </div>
                    ))
                ) : (
                    <p>Buscando times...</p>
                )}
            </div>
        </div>
    );
}

export default TimesNew;
