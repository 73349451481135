import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../../context/UserContext';

const MotivoDemissao = ({ selectedTeams, period }) => {
  const [token] = useContext(UserContext);
  const [enps, setEnps] = useState(null);
  const [year, month] = period.split('-').map(Number);
  const [maxQuantidade, setMaxQuantidade] = useState(100);

  const getEnps = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    const response = await fetch(
        `api/ms/time/enps/${selectedTeams}/${month}/${year}`,
      requestOptions
    );

    const data = await response.json();
    //console.log("🚀 ~ file: MotivoDemissao.jsx:23 ~ getEnps ~ data:", data)
    setEnps(data);
    // Calcula a soma total dos valores desses motivos específicos
    
      setMaxQuantidade(data.total);
  };

  useEffect(() => {
    if (selectedTeams) {
      getEnps();
    }
  }, [selectedTeams, period]);

  const renderProgressBars = (quantidade) => {
    const percentage = (quantidade / maxQuantidade) * 100;
  
    return (
      <div style={{ position: 'relative', marginBottom: '0.5rem' }}>
        <progress
          className="progress is-primary"
          value={quantidade}
          max={maxQuantidade}
          style={{ height: '1.5rem' }}
        >
          {percentage}%
        </progress>
        <span
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'gray', // Cor do texto
            fontSize: '0.9rem', // Tamanho da fonte
          }}
        >
          {quantidade}
        </span>
      </div>
    );
  };
  
  

  // Armazena as linhas da tabela
  const rows = [
    { motivo: "Ambiente de trabalho", quantidade: enps ? enps.Ambiente_de_trabalho : 0 },
    { motivo: "Falta de reconhecimento", quantidade: enps ? enps.Falta_de_reconhecimento : 0 },
    { motivo: "Imposição de trabalho 100% presencial", quantidade: enps ? enps.Imposicao_trabalho_presencial : 0 },
    { motivo: "Falta de plano de carreira", quantidade: enps ? enps.Falta_plano_de_carreira : 0 },
    { motivo: "Falta de protagonismo", quantidade: enps ? enps.Falta_de_protagonismo : 0 }
  ];

  return (
    <div className="card">
      <div className="card-header">
        <p className="card-header-title">Emocionalmente Abalado</p>
      </div>
      <div className="card-content">
      <table className="table">
          <thead>
            <tr>
              <th>Motivo de Demissão</th>
              <th>Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td style={{ fontSize: "14px",  lineHeight: "1" }} >{row.motivo}</td>
                <td>{renderProgressBars(row.quantidade)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MotivoDemissao;
