import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import GraficoTurnover from './GraficoTurnover';

const RequestTurnover = ({ selectedTeams, nameTeam }) => {
  const [token] = useContext(UserContext);
  const [values, setValues] = useState();
  const [selectedTurnover, setSelectedTurnover] = useState('TURNOVER');
  const [selectedData, setSeletedData] = useState(3);

  const getTurnoverForTeam = async (team) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    const response = await fetch(
      `api/ms/get_turnover/${selectedData}?team=${team}`,
      requestOptions
    );

    const data = await response.json();
    return data;
  };

  const getTurnover = async () => {
    setValues(null);

    if (selectedTeams.length) {
      const promises = selectedTeams.map(async (team) => {
        const teamData = await getTurnoverForTeam(team);
        return { team, data: teamData };
      });

      const results = await Promise.all(promises);

      const combinedData = results.reduce((acc, { team, data }) => {
        acc[team] = data;
        return acc;
      }, {});

      setValues(combinedData);
    }
  };

  useEffect(() => {
    if (selectedTeams.length) {
      getTurnover();
    }
  }, [selectedTeams, selectedTurnover, selectedData]);

  return (
    <>
      <GraficoTurnover
        data={values}
        customTitle={selectedTurnover}
        setSelectedTurnover={setSelectedTurnover}
        selectedTurnover={selectedTurnover}
        setSeletedData={setSeletedData}
        selectedData={selectedData}
        nameTeam={nameTeam}
        selectedTeams={selectedTeams}
      />
    </>
  );
};

export default RequestTurnover;