import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import Message from "./Message";
import { UserContext } from "../context/UserContext";
import {getIndicadores,getEngagement} from "../actions/HomeAct";

import './Login.css';

import image1 from "../images/IMG_1518.jpg"
import image2 from "../images/IMG_1808.jpg"
import image3 from "../images/IMG_1832.jpg"
import image4 from "../images/IMG_1959.jpg"
import image5 from "../images/IMG_2334.jpg"
import ImageLogo from "../images/logo2.png";
import ImageLogoProfectum from "../images/logoProfectum.png";

import {ReactComponent as BlueIcon} from "../icons/profectum.svg";

const images = [image1, image2, image3, image4, image5];


const Login = ({getMe,faker = false}) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useContext(UserContext);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [nameTeam, setNameTeam] = useState([]);

  const navigate = useNavigate();

  const submitLogin = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };



    const response = await fetch("/api/token", requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setErrorMessage(data.detail);
    } else {
      setToken(data.access_token);
      const requestOptions2 = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + data.access_token,
        },
      };
      const response2 = await fetch(`/api/ms/get_teams`, requestOptions2);
        if (response2.ok) {
        const data2 = await response2.json();
        setSelectedTeams(data2[0].id.toString());
        const newArr = [];
        for (let index = 0; index < data2.length; index++) {
            newArr.push(data2[index].id.toString());

        }
        //console.log(newArr.join(","));
        getIndicadores(data.access_token,newArr.join(","));
        getEngagement(data.access_token,newArr.join(","));
        }
        token && navigate('/');
      //getMe();
    }



  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitLogin();

  };

  useEffect(() => {
    token && navigate('/')
  }, [token])


    const [currentImageSrc, setCurrentImageSrc] = useState('');

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * images.length);
        setCurrentImageSrc(images[randomIndex]);
    }, []);

    return (
        <>
            {
                !token ?
                    (
                        <div className="columns columns-login">
                            <div className="column column-login image-column">
                                <img src={currentImageSrc} className="full-height" alt="Random Image"/>
                            </div>
                            <div className="column column-login form-column">
                                <form className={'form-width'} onSubmit={handleSubmit}>
                                    <div className="line-container is-vcentered">
                                        <div className="column">

                                            <img height={"60px"} width={"180px"} src={ImageLogoProfectum} style={{marginLeft:"-20px"}}/>
                                        </div>
                                        <div className="column">

                                            <img height={"80px"} width={"180px"} src={ImageLogo}/>
                                        </div>
                                    </div>

                                    <div className="field mt-5">
                                        <div className="control">
                                            <label className="label-login">Digite seu login</label>
                                            <input
                                                type="email"
                                                placeholder="E-mail"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="input is-info"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="field">
                                        <div className="control">
                                            <label className="label-login">Digite sua senha</label>
                                            <input
                                                type="password"
                                                placeholder="Senha"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="input is-info"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <Message message={errorMessage} setMessage={setErrorMessage} type={'is-danger'}/>

                                    <button className="button is-link w-100" type="submit">
                                        Login
                                    </button>

                                    <Link to='/send_email'>
                                        <div className="has-text-centered has-text-link mt-3">
                                            Esqueceu sua senha?
                                        </div>
                                    </Link>
                                </form>
                            </div>
                        </div>
                    )
                    : (
                        <div className="columns columns-login">
                            <div className="column column-login"></div>
                            <div className="column column-login">
                                <div className="notification is-primary">
                                    <h1 className="title"> Login efetuado com sucesso!</h1>

                                </div>
                            </div>
                            <div className="column column-login"></div>
                        </div>
                    )
            }
        </>
    );
};

export default Login;
