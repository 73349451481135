import React, { useState, useEffect, useContext } from 'react'

import TableColab from "./TableColab"
import GraficoGAP from "./../comp_teamguide/GraficoGAP"
import { UserContext } from "./../../context/UserContext";
import Times from "./../comp_teamguide/Times"

const VisaoConsolidada = ({faker}) => {
  const [token] = useContext(UserContext);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [nameTeam, setNameTeam] = useState([]);
  const today = new Date();
  const [period, setPeriod] = useState(today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2));
  const [dataArray, setDataArray] = useState(null);
  //const [gapArray, setGapArray] = useState(null);
  const [unique, setUnique] = useState(null);
  const [gapDataArray, setGapDataArray] = useState([]);
  const [searchTable, setSearchTable] = useState("");

  const getTimeConsolidado = async () => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        },
    };

    const response = await fetch(`/api/time_consolidado/${selectedTeams.join(",")}/${Number(period.split("-")[1])}/${Number(period.split("-")[0])}`, requestOptions);
    const data = await response.json();
    const uniqueIds = new Set();
    setUnique(
      data
        .filter((element) => {
          const isDuplicate = uniqueIds.has(element.id_collaborator);
          uniqueIds.add(element.id_collaborator);
          return !isDuplicate;
        })
        .map((value) => {
          const teamObject = nameTeam.find((team) => team.id === value.team.toString());
          const teamName = teamObject ? teamObject.name : '';
          return { ...value, name_team: teamName };
        })
    );
    
    setDataArray(data.map(value => {
      const teamObject = nameTeam.find(team => team.id === value.team.toString());
      const teamName = teamObject ? teamObject.name : '';
      return {
          id_collaborator: value.id_collaborator,
          name_collaborator: value.name_collaborator,
          burden: value.burden,
          gap: value.gap.name,
          name_team: teamName
      }
  }));
};

const getTimeConsolidado2 = async (team) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        },
    };

    const response = await fetch(`/api/time_consolidado/agrupado/${team}/${Number(period.split("-")[1])}/${Number(period.split("-")[0])}`, requestOptions);
    const data = await response.json();
    //setGapArray(data);
    return data;
};

useEffect(() => {
  const fetchData = async () => {
    if (selectedTeams.length && period) {
      const gapData = [];

      for (const item of selectedTeams) {
        
        const g = await getTimeConsolidado2(item);

        if (g && g.length > 0) {
          gapData.push({ team: item, data: g }); // Save the team and its specific gap data
        }
      }
      getTimeConsolidado()
      setGapDataArray(gapData); // Update the state with separate gap arrays for each team
    }
  };
  fetchData();
}, [selectedTeams, period]);



  return(
    <>
    <h1 className="title has-text-link">GAP Consolidado</h1>
    <div className="columns">
      <div style={{ marginLeft: '12px' }}>
        Equipes
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <Times
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
          setNameTeam={setNameTeam}
          nameTeam={nameTeam}
        />
      </div>
    </div>
    <div className="card">
      <div className="card-content">
        <div className="columns">
          <div className="column is-one-fifth">
            <input
                className="input"
                type="month"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
            />
          </div>
          <div className="column">
            <div className="field is-grouped is-pulled-right">
              <input
                className="input is-small"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchTable(e.target.value)}
                value={searchTable}
              />
            </div>
          </div>
        </div>
         {/* Map over the gapDataArray and create separate charts for each team if the gapArray exists */}
         <div className="columns">
          <div className="column is-one-third">
          {gapDataArray.map((item, index) => (
              item.data && item.data.length > 0 ? (
                <div key={index}>
                  {/* Display the corresponding team name above the chart */}
                  <div className="columns">
                    <GraficoGAP 
                      data={item.data}
                      nameTeam={selectedTeams.indexOf(item.team) !== -1 ? nameTeam[selectedTeams.indexOf(item.team)].name : ''}
                    />
                  </div>
                </div>
              ) : null
            ))}
            </div>
            <div className="column">
              <TableColab 
                data={dataArray} 
                unique={unique} 
                setSearchTable={setSearchTable}
                searchTable={searchTable}
              />
            </div>
          </div>
      </div>

    </div>
    </>
  )

};
export default VisaoConsolidada