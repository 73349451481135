import React, { useEffect, useState } from "react";
import MemberImage from "../comp_teamguide/MemberImage";
import { Link } from "react-router-dom";
import Pagination from "../subcomponents/Pagination";
import ReactTooltip from "react-tooltip";
import loadingGif from "../../icons/loading.gif";

const TablePerformance = ({
  performances,
  token,
  getPerformances,
  setCollaborator,
  setShow,
  setShowNPS,
  get_nps,
  setNameCollaborator,
  setChosenPerformance,
  setChosenHard,
  setChosenSoft,
  lastPage,
  page,
  setPage,
  setChosenClassNPS,
  setChosenSubTime,
  searchValue,
  handleSearchChange
}) => {
  const [senioridades, setSenioridades] = useState({})
  const perf = {
    1: "Baixa",
    2: "Média",
    3: "Alta",
    0: "Sem informação",
  };
  const tooltip = []
  const cor = {
    1: "is-danger",
    2: "is-warning",
    3: "is-success",
    0: "",
  };

  const cor2 = {
    Detrator: "is-danger",
    Neutro: "is-warning",
    Promotor: "is-success",
  };

  const handleSelect = (id_collaborator, performance, hard, soft, nps_class, id_sub_team) => {
    setCollaborator(id_collaborator);
    setChosenPerformance(performance);
    setChosenHard(hard);
    setChosenSoft(soft);
    setChosenClassNPS(nps_class);
    setChosenSubTime(id_sub_team);
    setShow(true);
  };

  const getNPS = (id_collaborator, name_collaborator) => {
    setCollaborator(id_collaborator);
    setNameCollaborator(name_collaborator)
    get_nps();
    setShowNPS(true);
  }

  const updatePerformanceStrategic = async (id_collaborator) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    await fetch(
      `/api/performances_strategic/${id_collaborator}`,
      requestOptions
    );
    getPerformances();
  };

  const getSenioridades = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    setSenioridades({})
    performances.map(async performance => {
      const response = await fetch(
        `/api/datadrivens/team/${performance.id_collaborator}`,
        requestOptions
      );
      const data = await response.json();
      if (data.status !== 404) {
        var newSenioridades = senioridades
        newSenioridades[performance.id_collaborator] = data.senioridade
        //console.log(performance.id_collaborator)
        setSenioridades(newSenioridades)
      }
    })

  };
  //console.log(performances)

  useEffect(() => {
    getPerformances();
    if (performances) {
      getSenioridades()
    }
  }, [page]);

  useEffect(() => {
    if (performances) {
      getSenioridades()
    }
  }, [performances]);

  return (
    <div>
      <ReactTooltip id="categoria_nps" effect="solid">
        <span>Categoria resultante da última pesquisa trimestral de NPS</span>
      </ReactTooltip>
      <ReactTooltip id="estrategico" effect="solid">
        <span>
          Indique se o colaborador é ou não estratégico para os seus projetos. A
          sua permanência é vital para o sucesso do projeto
        </span>
      </ReactTooltip>
      <ReactTooltip id="categoria_performance" effect="solid">
        <span>
          Selecione a categoria de performance atual do colaborador (Alta, média
          ou baixa) e recomende melhorias em soft e hard skills
        </span>
      </ReactTooltip>
      {performances &&
        performances.map((performance, i) => (
          <ReactTooltip key={`nps_${performance.id_collaborator}`} effect="solid">
            <span>Ver nota NPS</span>
          </ReactTooltip>
        ))
      }

      <div className="card">
        <div className="card-header">
          <p className="card-header-title"/>
          <div className="control m-3">
            <input
                className="input"
                type="text"
                placeholder="Pesquisa..."
                value={searchValue}
                onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="card-content">
          <table className="table is-fullwidth is-hoverable has-text-centered">
            <thead>
              <tr>
                <th className="has-text-centered">Colaborador</th>
                <th className="has-text-centered">Senioridade</th>
                <th className="has-text-centered">
                  <span style={{ paddingRight: "5px" }}>Histórico NPS</span>
                  <a data-tip data-for="categoria_nps" data-event="click focus">
                    <i className="fa fa-circle-info"></i>
                  </a>
                </th>
                <th className="has-text-centered">
                  <span style={{ paddingRight: "5px" }}>Estratégico</span>
                  <a data-tip data-for="estrategico" data-event="click focus">
                    <i className="fa fa-circle-info"></i>
                  </a>
                </th>
                <th className="has-text-centered">
                  <span style={{ paddingRight: "5px" }}>
                  Avaliação
                  </span>
                  <a
                    data-tip
                    data-for="categoria_performance"
                    data-event="click focus"
                  >
                    <i className="fa fa-circle-info"></i>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              {performances ?
                performances.map((performance, i) => (
                  <tr key={performance.id_collaborator}>
                    <td>
                      <div className="columns">
                        <div className="column is-3">
                          <MemberImage
                            memberId={performance.id_collaborator}
                            colaboradorName={performance.name}
                            width={40}
                            height={40}
                          />
                        </div>
                        <div className="column" style={{ textAlign: "left" }}>
                          {performance.name_collaborator}
                        </div>
                      </div>
                    </td>
                    <td>{senioridades[performance.id_collaborator]}</td>
                    <td>
                      <Link
                       to="#"
                       onClick={() =>
                         getNPS(
                           performance.id_collaborator,
                           performance.name_collaborator
                         )
                       }
                      >
                        <a data-tip data-for={`nps_${performance.id_collaborator}`}>
                          <span className={`tag ${cor2[performance.nps_class]}`}>
                            {performance.nps_class}
                          </span>
                        </a>
                        </Link>
                    </td>
                    <td>
                      {!performance.strategic ? (
                        <Link
                          to="#"
                          onClick={() =>
                            updatePerformanceStrategic(
                              performance.id_collaborator,
                              performance.performance
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-star"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                          </svg>
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          onClick={() =>
                            updatePerformanceStrategic(
                              performance.id_collaborator
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-star-fill"
                            viewBox="0 0 16 16"
                            style={{ color: "#e3ce2d" }}
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </Link>
                      )}
                    </td>
                    <td>
                      <span className={`tag ${cor[performance.performance]}`}>
                        {perf[performance.performance]}
                      </span>
                      <Link
                        to="#"
                        onClick={() =>
                          handleSelect(
                            performance.id_collaborator,
                            performance.performance,
                            performance.hard,
                            performance.soft,
                            performance.nps_class,
                            performance.id_sub_team
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                      </Link>
                    </td>
                  </tr>
                ) ): <img src={loadingGif} width="80" alt="loading..." />}
            </tbody>
          </table>
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </div>
      </div>
    </div>
  );
};

export default TablePerformance;
