import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';

const Times = ({ selectedTeams, setSelectedTeams, setNameTeam}) => {
  const [teams, setTeams] = useState(null);
  const [token] = useContext(UserContext);
  const [isAtLeastOneChecked, setIsAtLeastOneChecked] = useState(false);
  const color = ['is-info','is-warning','is-danger'];

  const getTeams = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    const response = await fetch(`/api/ms/get_teams`, requestOptions);
    if (response.ok) {
      const data = await response.json();
      const color = ['is-info','is-warning','is-danger','is-success'];
      //setTeams([color,...data]);
      setTeams(data);
      const firstTeamId = data[0].id.toString();
      const firstTeamName = data[0].name.toString();
      setNameTeam([{ id: firstTeamId, name: firstTeamName }]);
      setSelectedTeams([firstTeamId]);
    }
  };

  const onChangeBox = async (e) => {
    const teamId = e.target.value;
    const teamName = e.target.dataset.name;
    const isChecked = e.target.checked;

    if (isChecked || selectedTeams.length > 1) {
      setSelectedTeams((prevSelectedTeams) =>
        isChecked ? [...prevSelectedTeams, teamId] : prevSelectedTeams.filter((id) => id !== teamId)
      );

      setNameTeam((prevNameTeam) =>
        isChecked
          ? [...prevNameTeam, { id: teamId, name: teamName }]
          : prevNameTeam.filter((team) => team.id !== teamId)
      );

      setIsAtLeastOneChecked(isChecked || selectedTeams.length > 1);
    }
  };

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    setIsAtLeastOneChecked(selectedTeams.length > 0);
  }, [selectedTeams]);

  return (
    <div>
      <div className="columns">
        {teams ? (
          teams.map((team,index) => (
            <div
              className={`button ${color[index]} is-light`}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginLeft: '12px'
              }}
              key={team.id}
            >
              <input
                type="checkbox"
                id={`team_${team.id}`}
                value={team.id}
                checked={selectedTeams.includes(team.id.toString())}
                onChange={onChangeBox}
                data-name={team.name}
                style={{ marginRight: '5px' }}
                className={`${color[index]} is-light`}
                disabled={!teams && (!isAtLeastOneChecked && !selectedTeams.includes(team.id.toString()))}
              />
              <label htmlFor={`team_${team.id}`}>{team.name} - {index}</label>
            </div>
          ))
        ) : (
          <p>Buscando times...</p>
        )}
      </div>
    </div>
  );
};

export default Times;
