import React, { useState, useContext } from "react";

import Message from "./Message";
import { UserContext } from "../context/UserContext";

const SendEmail = () => {
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState(null);
    const [email, setEmail] = useState("");
    const [token] = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const handleSendEmail = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(
                {
                    email: email,
                }
                ),
        };
        setLoading(true)
        const response = await fetch("/api/users/forgot_password", requestOptions);
        if (response.ok){
            setMessage("Um e-mail foi enviado com a instruções de redefinição de senha")
            setMessageType('is-success')
        } else {
            setMessage("E-mail não foi encontrado")
            setMessageType('is-danger')
        }
        setLoading(false)
    };

  return (
      <div className="columns">
          <div className="column"></div>
          <div className="column">
    <div className="box">

        <div className="field has-addons">
            <div className="control">
                <input 
                className="input" 
                type="text" 
                placeholder="E-mail de cadastro"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="control">
                <a href="#" className={'button is-info ${loading && "is-loading"}'} onClick={handleSendEmail}>
                Enviar
                </a>
            </div>
            </div>
            <Message message={message} setMessage={setMessage} type={messageType}/>
    </div>
    </div>
    <div className="column"></div>
    </div>
  )
}

export default SendEmail