import React, {useState, useEffect,  useContext} from 'react'
import { UserContext } from "../../context/UserContext";
import GraficoEngajamento  from "./GraficoEngagPesquisa";


const RequestEngajamento = ({selectedTeams,period}) => {
    const [token] = useContext(UserContext);
    const [values, setValues] = useState('');
    const [year, month] = period.split('-').map(Number);

    const getEngagementTeam = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        /*const response = await fetch(
            `/teamguide/get_engagement_team/${selectedTeams.join(",")}`,
            requestOptions
        );*/
        const date = new Date();
        const response = await fetch(
            `api/ms/time/engagement/${selectedTeams}/${month}/${year}`,
            requestOptions
        );
        console.log(response);
        const data = await response.json();


        const tempArr = [];

        //console.log(data.engajamento_pilares);
        setValues(data.engajamento_pilares);
    };

    useEffect(() => {
        setValues(null)
        if (selectedTeams.length){
            getEngagementTeam();
        }
    }, [selectedTeams])


    return (
        <GraficoEngajamento data={values}/>
    )
}

export default RequestEngajamento