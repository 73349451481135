import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import {InfoEngajamento} from "./colaborador-infos/InfoEngajamento";
import {InfoCriticidade} from "./colaborador-infos/info-geral/InfoCriticidade";
import {InfoComplementares} from "./colaborador-infos/info-geral/InfoComplementares";
import {InfoHeaderNumbers} from "./colaborador-infos/info-geral/InfoHeaderNumbers";
import {InfoHeader} from "./colaborador-infos/info-geral/InfoHeader";
import {InfoAcoes} from "./colaborador-infos/InfoAcoes";

import './ColaboradorInfo.css'
import {UserContext} from "../../context/UserContext";
import loadingGif from "../../icons/loading.gif";

export const ColaboradorInfo = ({me}) => {
    const {id} = useParams();
    const [token] = useContext(UserContext);
    const [criticidade, setCriticidade] = useState([]);
    const [newCriticidade, setNewCriticidade] = useState(true);
    const [selectedOption, setSelectedOption] = useState('1');
    const [reason, setReason] = useState("");
    const [colaborador, setColaborador] = useState(null);
    const [npsClass, setNpsClass] = useState("Não Informado");
    const [avaliacao, setAvaliacao] = useState("Não Informado");
    const [dataDriven, setDataDriven] = useState({});
    const [salario, setSalario] = useState({});

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        },
    };

    const getColaborador = async () => {
        const response = await fetch(`/api/ms/collabrator/salary/${id}`, requestOptions);
        const array = await response.json();
        setSalario(array);
    }

    const getColaboradorSalario = async () => {
        const response = await fetch(`/api/ms/collabrator/${id}`, requestOptions);
        const array = await response.json();
        setColaborador(array);
    }

    const performance_strategic = async()=>{
        const response = await fetch("/api/performances_strategic/" + id, requestOptions);
        const {nps_class, performance} = await response.json();
        setNpsClass(nps_class);
        const avaliacoes = {
            1: "Baixa",
            2: "Média",
            3: "Alta",
        };
        setAvaliacao(avaliacoes[performance] || "Sem Informação");
    }

    const getCriticidade = async () => {
        const response = await fetch("/api/get_criticalityleaders/" + id, requestOptions);
        const data = await response.json();
        setNewCriticidade(data.length === 0)
        setCriticidade(data.length > 0 ? data[0].criticality.toString() : '1');
        setReason(data.length > 0 ? data[0].reason : "");
        setSelectedOption(data.length > 0 ? data[0].criticality.toString() : '1')
    };

    const getDataDrivens = async () => {
        const response = await fetch("/api/datadrivens/team/" + id, requestOptions);
        const data = await response.json();
        setDataDriven(data);
    }

    useEffect(() => {
        id && performance_strategic();
        id && getColaborador();
        id && getCriticidade();
        id && getDataDrivens();
        id && getColaboradorSalario();
    }, [id]);


    return (
        colaborador ?
            <div>
                <div className={"columns"}>
                    <div className="column is-1 ml-5">
                        <Link to={"/colaboradores"}>
                            <i className="fa fa-solid fa-arrow-left fa-lg" style={{width: "100px"}}></i>
                        </Link>
                    </div>
                    <div className="column">
                        <h1 className="subtitle mb-0">Colaborador</h1>
                        <main className={'mb-4'}>
                            <h1 className="title">{colaborador && colaborador.collaborator_name}</h1>
                        </main>
                    </div>
                </div>

                <div className="columns">
                    <div className="column">
                        <div className="card" style={{position: "relative"}}>
                            <header className="card-header">
                                <InfoHeader
                                    colaborador={colaborador}
                                    criticidade={criticidade}
                                    token={token}
                                    dataDriven={dataDriven}
                                    npsClass={npsClass}
                                    avaliacao={avaliacao}
                                />
                            </header>
                            <div className="card-content">
                                <div className="columns top-border p-3">
                                    <InfoHeaderNumbers
                                        colaborador={colaborador}
                                        dataDriven={dataDriven}
                                        me={me}
                                        salario={salario}
                                    />
                                </div>
                                <div className="columns top-border">
                                    <div className="column is-half">
                                        {<InfoComplementares colaborador={colaborador}/>}
                                    </div>
                                    <div className="column is-half">
                                        <InfoCriticidade
                                            me={me}
                                            colaborador={colaborador}
                                            token={token}
                                            newCriticidade={newCriticidade}
                                            reason={reason}
                                            selectedOption={selectedOption}
                                            dadosCriticidade={criticidade}
                                            setReason={setReason}
                                            setSelectedOption={setSelectedOption}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="card">
                            <InfoAcoes
                                colaborador={colaborador}
                                token={token}
                                me={me}
                            />
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-7">
                    <div className="card">
                            <div className="card-content">
                                <InfoEngajamento colaborador={colaborador} token={token}/>
                            </div>
                        </div>
                    </div>
                    <div className="column is-5">
                        <div className="card">

                        </div>
                    </div>
                </div>
            </div> :
            <img src={loadingGif} width="80" alt="loading..."/>
    );
}
