import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import loadingGif from "../../icons/loading.gif";
import { Bar } from "react-chartjs-2";

const GraficoEngajamento = ({ data }) => {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [label, setLabel] = useState([]);
  const color = ["rgba(106,159,197, 0.5)", "rgba(247,186,68, 0.5)", "rgba(239,105,134, 0.5)"];
  const bcolor = ["rgba(106,159,197)", "rgba(247,186,68)", "rgba(239,105,134)"];
  const options_date = {
    year: "numeric",
    month: "2-digit",
  };
  

  useEffect(() => {
    if (data) {
        
        
      //const tempLabels = validData.map((item) => item.map((entry) => entry.month));
      //const tempDatasets = validData.map((item) => item.map((entry) => entry.score));
      //console.log(Object.keys(data).map((item,index) => item.replace('_engajamento', ''))); 
      //console.log(Object.values(data).map((item,index) => parseFloat(item.replace('%', ''))));   
      setLabels(Object.keys(data).map((item,index) => item.replace('_engajamento', '')));
      setDatasets(Object.values(data).map((item,index) => item.replace('%', '')));
      
    }
  }, [data]);

  function getNamesForIds(idsArray, data) {
    return idsArray.map((id) => {
      const filteredTeams = data.filter((team) => team.id === id);
      return filteredTeams.length > 0 ? filteredTeams[0].name : null;
    });
  }

  return (
    <>
      <ReactTooltip id="engajamento" effect="solid">
        <span>
          Engajamento dos colaboradores é medido a cada 45 dias e considera
          questões como liderança, metas, motivação e propósito
        </span>
      </ReactTooltip>
      <div className="card" style={{
            height:"50vh",
            width: "83vh",
          }}>
        <div className="card-header">
          <p className="card-header-title">Engajamento</p>
          <a
            className="card-header-icon"
            data-tip
            data-for="engajamento"
            data-event="click focus"
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-question-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
              </svg>
            </span>
          </a>
        </div>
        <div className="card-content" style={{
            height:"40vh",
            width:"40vw",
          }}> 
          {labels.length > 0 && datasets.length > 0 ? ( 
            <Bar
              data={{
                labels: labels.map((data, index) => [data]),
                datasets: [{//datasets.map((data, index) => ({
                  //label: labels.map((data, index) => [data]),
                  data: datasets,
                  backgroundColor: color[1],
                  borderColor: bcolor[1],
                  borderWidth: 2,
                  borderSkipped: false,
                //}))
                }]
              }}
              options={{
                scales: {
                  y: {
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
                indexAxis: 'y',
                plugins: {
                    legend: {
                        display: false,
                      },
                  datalabels: {
                    display: true,
                    color: "black",
                    //formatter: Math.round,
                    anchor: "end",
                    offset: -2,
                    align: "start"
                  }
                },

                
              }}
            />
          ) : (
            <img src={loadingGif} alt="loading..." />
          )}
        </div>
      </div>
    </>
  );
};


export default GraficoEngajamento;
