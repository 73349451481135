import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ModalTimeline from "../comp_forms/modals/ModalTimeline";
import ModalAddTimeline from "../comp_forms/modals/ModalAddTimeline";
import ModalEditActions from "./ModalEditActions";
import {ReactComponent as IconMenu} from "../../icons/chevron-down.svg"

const TableAcoes = ({ dados, selectedTeams, token, me }) => {
  const [notificationParameters, setParameter] = useState({
    display: false,
    text: "",
    color: "notification is-success",
  });
  const [criticality, setCriticality] = useState([]);
  const [finalyTable, setFinalyTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState("ASC");
  const [totalPages, setTotalPages] = useState(1);
  const [idRequest, setIdRequest] = useState(null);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [idAddTimeline, setAddTimeline] = useState(null);

  const [reload, setReload] = useState(0);
  const [idEditAction, setIdEditAction] = useState(null);

  const SITUACAO = {
    NAO_INICIADO: "Não iniciado",
    ANDAMENTO: "Em andamento",
    CONCLUIDO: "Concluído",
  };

  const cor_indicador = {
    Crítico: "is-danger",
    Intermediário: "is-warning",
    "Não crítico": "is-success",
    "Não informado": "",
  };

  const sorting = (col) => {
    setOrder((prevOrder) => (prevOrder === "ASC" ? "DSC" : "ASC"));
    const sorted = [...finalyTable].sort((a, b) =>
      order === "ASC"
        ? a[col].toLowerCase().localeCompare(b[col].toLowerCase())
        : b[col].toLowerCase().localeCompare(a[col].toLowerCase())
    );
    setFinalyTable(sorted);
  };

  const getCriticality = async (time) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`/api/full/nopage/indicators/${time}`, requestOptions);
    const data = await response.json();
    console.log("🚀 ~ file: TableAcoes.jsx:29 ~ const getCriticality = ~ data:", data);

    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedTeams.length > 0) {
        const fetchedData = [];
        for (const item of selectedTeams) {
          const response = await getCriticality(item);
          fetchedData.push(...response);
        }
        setCriticality(fetchedData);
      }
    };
    fetchData();
  }, [selectedTeams, token]);

  useEffect(() => {
    const createFinalyTable = () => {
      if (criticality.length > 0) {
        const finalyTableData = dados.map((item) => {
          const foundItem = criticality.find((c) => c.id_collaborator === item.id_collaborator);
          if (foundItem) {
            return { ...item, final: foundItem.final };
          } else {
            return { ...item, final: 'Não Informado' };
          }
        });
        setFinalyTable(finalyTableData);
      }else{
        const finalyTableData = dados.map((item) => {
          return { ...item, final: 'Não Informado' };
        });
        setFinalyTable(finalyTableData);
      }
    };
    createFinalyTable();
  }, [dados, criticality]);

  useEffect(() => {
    setTotalPages(Math.ceil(finalyTable.length / itemsPerPage));
    setCurrentPage(1); // Reset the current page when data changes
  }, [finalyTable, itemsPerPage]);

  const renderCurrentPageData = () => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return finalyTable
      .filter((item) => {
        const searchData = searchTerm.toLowerCase();
        return (
          item.name_collaborator.toLowerCase().includes(searchData) ||
          item.final.toLowerCase().includes(searchData) ||
          item.gap.name.toLowerCase().includes(searchData) ||
          item.action.toLowerCase().includes(searchData) ||
          SITUACAO[item.situation].toLowerCase().includes(searchData)
        );
      })
      .slice(startIdx, endIdx);
  };

  const renderPaginationNumbers = () => {
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + 2, totalPages);

    const paginationNumbers = [];

    if (startPage > 1) {
      paginationNumbers.push(
        <li key={1}>
          <a
            style={{ fontSize: "12px" }}
            className="pagination-link"
            onClick={() => setCurrentPage(1)}
          >
            1
          </a>
        </li>
      );
      if (startPage > 2) {
        paginationNumbers.push(<li key="ellipsis-start">&hellip;</li>);
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      paginationNumbers.push(
        <li key={page}>
          <a
            style={{ fontSize: "12px" }}
            className={`pagination-link ${page === currentPage ? "is-current" : ""}`}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </a>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        paginationNumbers.push(<li key="ellipsis-end">&hellip;</li>);
      }
      paginationNumbers.push(
        <li key={totalPages}>
          <a
            style={{ fontSize: "12px" }}
            className="pagination-link"
            onClick={() => setCurrentPage(totalPages)}
          >
            {totalPages}
          </a>
        </li>
      );
    }

    return paginationNumbers;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset the page to 1 when searching
  };

  const RequestTimeLine = (id) => {
    setIdRequest(id);
  };

  const RequestAddTimeLine = (id) => {
    setShowModalAdd(true);
    setAddTimeline(id);
  };

  const handleDeleteAction = async (idAction) => {
    const confirmDelete = window.confirm("Deseja realmente remover essa ação?");
    if (confirmDelete) {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const r = await fetch('/api/individual_actions/' + idAction, requestOptions);
        window.location.reload();
    }
  };

  return (
    <>
      <ModalEditActions
        setIdEditAction={setIdEditAction}
        idEditAction={idEditAction}
        token={token}
        setParameter={setParameter}
        setReload={setReload}
        reload={reload}
        handleDeleteAction={handleDeleteAction}
      />
      <ModalTimeline
        setIdRequest={setIdRequest}
        idRequest={idRequest}
        token={token}
        setParameter={setParameter}
      />
      <ModalAddTimeline
        me={me}
        setAddTimeline={setIdRequest}
        idAddTimeline={idAddTimeline}
        token={token}
        setParameter={setParameter}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}
      />
      <IconMenu width="3rem"  stroke="black" />
      <div className="column">
        <div className="field is-grouped is-pulled-right">
          <p className="control has-icons-left">
            <input
              className="input is-small"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
            <span className="icon is-small is-left">
              <i className="fa fa-search"></i>
            </span>
          </p>
        </div>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>
                <Link to="#" style={{color: "darkblue"}} onClick={() => sorting("name_collaborator")}>
                  Colaborador&nbsp;
                  {order === "ASC" ? (
                  <i className="fa-solid fa-sort-down" style={{ fontSize: "0.8em", color: "blue" }}></i>
                ) : (
                  <i className="fa-solid fa-sort-up" style={{ fontSize: "0.8em", color: "blue" }}></i>
                )}
                </Link>
              </th>
              <th>
                <Link to="#" style={{color: "darkblue"}} onClick={() => sorting("final")}>
                  Criticidade
                </Link>
              </th>
              <th>
                <Link to="#" style={{color: "darkblue"}} onClick={() => sorting("GAP")}>
                  GAP
                </Link>
              </th>
              <th>
                <Link to="#" style={{color: "darkblue"}} onClick={() => sorting("action")}>
                  Ações
                </Link>
              </th>
              <th>
                <Link to="#" style={{color: "darkblue"}} onClick={() => sorting("situation")}>
                  Status da Ação

                </Link>
              </th>
              <th></th>

            </tr>
          </thead>
          <tbody>
            {renderCurrentPageData().map((value) => (
              <tr key={value.id}>
                <td style={{color: "darkblue"}}>{value.name_collaborator}</td>
                <td style={{color: "darkblue"}}>
                  <span className={"tag " + cor_indicador[value.final]}>{value.final}</span>
                </td>
                <td style={{color: "darkblue"}}>{value.gap.name}</td>
                <td style={{color: "darkblue"}}>{value.action}</td>
                <td style={{color: "darkblue"}}>{SITUACAO[value.situation]}</td>
                <td>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Link to="#"  onClick={() => setIdEditAction(value.id)}>
                    <i
                      className={"fa-regular fa-pen-to-square"}
                      style={{ fontSize: "1.3em" , color: "blue"}}
                      ></i>
                    </Link>
                    <Link to="#" onClick={() => RequestTimeLine(value.id)}>
                      <i
                      className={"fa-regular fa-comment-dots"}
                      style={{ fontSize: "1.3em", color: "blue" }}
                      ></i>
                    </Link>
                    <Link to="#" onClick={() => RequestAddTimeLine(value.id)}>
                      <i
                      className={"fa-solid fa-comment-medical"}
                      style={{ fontSize: "1.3em", color: "blue" }}
                      ></i>
                    </Link>
                    <Link to="#" onClick={() => handleDeleteAction(value.id)}>
                      <i
                      className={"fa-solid fa-trash-can"}
                      style={{ fontSize: "1.3em", color: "blue" }}
                      ></i>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        {totalPages > 1 && (
          <nav className="pagination is-centered is-small is-rounded" role="navigation" aria-label="pagination">
            <a
              className="pagination-previous"
              onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </a>
            <a
              className="pagination-next"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={currentPage === totalPages}
            >
              Próximo
            </a>
            <ul className="pagination-list">{renderPaginationNumbers()}</ul>
          </nav>
        )}
      </div>
    </>
  );
};

export default TableAcoes;
