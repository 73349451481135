import React, {useState, useEffect,  useContext} from 'react'
import SubtimesSelect from '../comp_teamguide/SubtimesSelect'
import LiderSelect from '../comp_teamguide/LiderSelect'

const FormSubtimes = ({token, getSubTeams, tags}) => {
    const [selectedSubteam, setSelectedSubteam] = useState(null);
    const [selectedSubteamName, setSelectedSubteamName] = useState(null);
    const [selectedLider, setSelectedLider] = useState(null);
    const [description, setDescription] = useState("");
    const [selectedTagSubteam, setSelectedTagSubteam] = useState(null);

    const handleCreateSubTeam = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(
                {
                    id_user: selectedLider,
                    id_sub_team: selectedSubteam,
                    name_sub_team: selectedSubteamName,
                    description: description,
                    id_tag: selectedTagSubteam,
                    status: true
                }
                ),
        };
        await fetch("/api/subteams", requestOptions);
        getSubTeams()
        setDescription("")
    };


  return (
    <div className="card">
        <div className="card-header">
            <p className="card-header-title">
            Cadastro Sub-times
            </p>
        </div>
        <div className="card-content" style={{ height: "470px"}}>
            <div className="content">
                <SubtimesSelect
                    selectedSubTeamGuideId={selectedSubteam}
                    setSelectedSubTeamGuideId={setSelectedSubteam}
                    setSelectedSubteamName={setSelectedSubteamName}
                />
                <div className="field">
                    <div className="control">
                    <div className="select is-fullwidth">
                        <select
                            value={selectedTagSubteam}
                            onChange={(e) => {
                                setSelectedTagSubteam(e.target.value)
                        }}
                        >
                            <option value=''>TAG</option>
                            {
                                tags &&
                                tags.map(tag => (
                                    <option key={tag.id} value={tag.id}>{tag.name}</option>
                                ))
                            }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="textarea"
                            placeholder="Descrição">
                        </textarea>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                    <LiderSelect
                        selectedLider={selectedLider}
                        setSelectedLider={setSelectedLider}
                    />
                    </div>
                </div>
                <button className="button is-info" onClick={handleCreateSubTeam}>Inserir</button>
            </div>
        </div>

    </div>
  )
}

export default FormSubtimes