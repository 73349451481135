import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import loadingGif from "../../icons/loading.gif";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartPerfomance = ({ token, selectedTeams }) => {
  const [labels, setLabels] = useState(null);
  const [values, setValues] = useState(null);

  const getGroupedPerformance = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      `/api/grouped/nps/${selectedTeams.join(",")}`,
      requestOptions
    );
    const data = await response.json();
    //console.log(data)
    const total = data.reduce((accumulator, object) => {
      return accumulator + object.number;
    }, 0);
    setLabels(
      data.map(
        (element) =>
          `${element.index} (${Math.round((element.number / total) * 100)}%)`
      )
    );
    setValues(data.map((element) => element.number));
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: values,
        backgroundColor: [
          "rgba(239,105,134,255)",
          "rgba(247,186,68,255)",
          "rgba(92,203,139,255)",
          "rgba(206,206,206, 0.5)",
        ],
        borderColor: [
          "rgba(226,71,95, 2.2)",
          "rgba(250,226,138, 2.2)",
          "rgba(76,196,140, 0.6)",
          "rgba(206,206,206, 0.6)",
        ],
        borderWidth: 1,
        hoverOffset: 8,
        weight: 2,
        borderAlign: "inner",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        borderWidth: 0.5,
        labels: {
          boxWidth: 10,
          color: "blue",
          font: {
            weight: "bold",
          },
        },
      },
      datalabels: {
        color: "white",
        font: {
          size: 12,
        },
        anchor: "right",
        align: "inner",
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          const percentage = ((value / dataset._meta[0].total) * 100).toFixed(
            2
          );
          return `${data.labels[tooltipItem.index]}: ${value} (${percentage}%)`;
        },
      },
    },
  };

  useEffect(() => {
    setValues(null)
    if (selectedTeams.length){
      getGroupedPerformance();
    }
  }, [selectedTeams]);
  return (
    <>
      <div className="column is-two-fifths">
      <br/>
        {values ? (
          <div
          style={{
            position: "relative",

          }}
        >
          <Doughnut
            width={400} // Aumente o valor da largura
            height={300} // Aumente o valor da altura
            data={data}
            options={options}
            plugins={[ChartDataLabels]}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "30%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
          <span style={{ fontSize: "36px", fontWeight: "bold" }}>
            {data.datasets[0].data.reduce(
              (acc, value) => acc + value,
              0
            )}
          </span>
          <p> <span style={{ fontSize: "12px" }}>membros</span></p>
          </div>
        </div>
        ) : (
          <img src={loadingGif} width="80" alt="loading..." />
        )}
      </div>
    </>

  );
};

export default ChartPerfomance;
